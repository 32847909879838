import React, { useEffect } from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { logoutUser } from '../store/actions'

const AuthProtected = props => {
  const dispatch = useDispatch()

  const token = localStorage.getItem('token')

  if (!token) {
    dispatch(logoutUser())
    return <Redirect to={{ pathname: '/login' }} />
  }

  return <>{props.children}</>
}

const AccessRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
        return (
          <>
            {' '}
            <Component {...props} />{' '}
          </>
        )
      }}
    />
  )
}

export { AuthProtected, AccessRoute }
