import React, { useState, useEffect } from "react";
// import {
//   Card,
//   CardBody,
//   CardHeader,
//   Col,
//   Container,
//   Row,
//   Table,
//   UncontrolledDropdown,
//   DropdownToggle,
//   DropdownItem,
//   DropdownMenu
// } from 'reactstrap'

import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  FormFeedback,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import UiContent from "../../../Components/Common/UiContent";
import { Link, useHistory, useParams } from "react-router-dom";
import { pmServices } from "../../../services/pmServices";
import { organisationServices } from "../../../services/organisationServices";
import { toast } from "react-toastify";

import Select from "react-select";
import { useRef } from "react";

const PmOrganisaitonList = () => {
  const [allOrganisations, setAllOrganisations] = useState([]);
  const history = useHistory();
  let { id } = useParams();
  const token = localStorage.getItem("token");
 
  // new satates for adding org
  const [showAddOrganisationModal, setShowOrganisationsModal] = useState(false);
  const [orgText, setOrgText] = useState("");
  const [orgDetails, setOrgDetails] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({ value: "", label: "" });
  const [orgId, setOrgID] = useState("");
  const [pageCount, setPageCount] = useState(1);
  const [itSystem, setItSystem] = useState([]);
  const [selectedItSystem, setSelectedItSystem] = useState("");
  const [organisationError, setOrganisationError] = useState(false);
  
  useEffect(() => {
    fetchOrganisationById();
  }, []);

  useEffect(() => {
    fetchOrganisation("", 1, 10);
    fetchItSystem();
  }, []);

  const fetchOrganisationById = () => {
    pmServices
      .getOrganisationByPMId(id, token)
      .then((resp) => {
        if (resp?.status) {
          setAllOrganisations(resp?.data || []);
        }
      })
      .catch((err) => console.log(err));
  };

  const fetchItSystem = () => {
    organisationServices
      .getItSystems()
      .then((resp) => {
        setItSystem(resp.it_systems);
      })
      .catch((err) => console.log(err));
  };

  const fetchSuspendPMForOrganisation = (pm_id, org_id, suspend, token) => {
    organisationServices
      .postSuspendPMForOrganisation(pm_id, org_id, suspend, token)
      .then((resp) => {
        if (resp.success) {
          toast.success(resp.message);
          fetchOrganisationById();
        }
      })
      .catch((err) => console.log(err));
  };

  // fucntions related org
  const fetchOrganisation = (prefix, page, limit) => {
    organisationServices
      .getOrganizationsList(prefix, page, limit, token, id)
      .then((res) => {
        if (res) {
          console.log("organizations", res.data);
          let set = new Set();
          let copiedArray = [];
          if (res?.data?.length !== 0) {
            res?.data?.map((item) => {
              if (!set.has(item?.uuid)) {
                copiedArray.push({
                  value: item?.uuid,
                  label: `${item?.organisation_name} - ${item?.organisation_code}\n${item?.address_line_1}\n${item?.postcode}`,
                });
                set.add(item?.uuid);
              }
            });

            const filteredData = copiedArray.filter((item) =>
              orgDetails.map((_) => {
                return item.value !== _.value;
              })
            );

            setOrgDetails(filteredData);
          } else {
            setOrgDetails([]);
          }
        } else {
          let copiedArray = [...orgDetails];
          let set = new Set();
          if (res?.data?.length !== 0) {
            res?.data?.map((item) => {
              if (!set.has(item?.uuid)) {
                copiedArray.push({
                  value: item?.uuid,
                  label: `${item?.organisation_name} - ${item?.organisation_code}\n${item?.address_line_1}\n${item?.postcode}`,
                });
                set.add(item?.uuid);
              }
            });

            const filteredData = copiedArray.filter((item) =>
              orgDetails.map((_) => {
                return item.value !== _.value;
              })
            );

            setOrgDetails(filteredData);
          } else {
            setOrgDetails([]);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  const handleScroll = (e) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
      console.log("bottom", bottom);
      console.log("scrollHeight:",e.target.scrollHeight,"  scrollTop:",e.target.scrollTop,"   clientheight:", e.target.clientHeight);
    if (bottom) {
      setPageCount(pageCount + 1);
      console.log("pageCount-->",pageCount);
      fetchOrganisation("", pageCount, 20, token, id);
    }
  };

  const handleOrgChange = (event) => {
    setOrgID(event.value);
    setSelectedOrg({ value: event.value, label: event.label });
  };

  const handleItSystemChange = (event) => {
    setSelectedItSystem(event.target.value);
  };

  useEffect(() => {
    let delayedApiCall;
    delayedApiCall = setTimeout(() => {
      fetchOrganisation(orgText, 1, 10);
    }, 300);

    return () => clearTimeout(delayedApiCall);
  }, [orgText]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      gp_soc_uuid: selectedItSystem,
      org_uuid: selectedOrg.value,
      PMId: id,
    };
    console.log("handling submit", data);
    organisationServices
      .allocatePM(data, token)
      .then((resp) => {
        if (resp.success) {
          toast.success(resp.message);
          setShowOrganisationsModal(false);
          fetchOrganisationById();
          setSelectedOrg({ value: "", label: "" })
          setSelectedItSystem("")
          
        } else {
          toast.error(resp.message);
        }
      })
      .catch((err) => console.log(err));
  };

  document.title = "Organisations List | airGP";

  return (
    <React.Fragment>
      <UiContent />
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader className="card-title text-light mb-0">
                  <span>Managed Organisations</span>
                  <div className="float-end">
                    <button
                      className="btn btn-light "
                      id="btn-new-event"
                      onClick={() => setShowOrganisationsModal(true)}
                    >
                      Add Organisation
                    </button>
                  </div>
                </CardHeader>
                <CardBody>
                  <div className="live-preview">
                    <div className="table-responsive table-card">
                      <Table className="table-nowrap mb-0">
                        <thead className="table-light">
                          <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Name</th>
                            <th scope="col">Organisation Code</th>
                            <th scope="col">Postcode</th>
                            <th scope="col">Phone number</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        {allOrganisations?.length !== 0 ? (
                          allOrganisations?.map((org, idx) => {
                            return (
                              <tbody key={`org-list-${idx}`}>
                                <tr>
                                  <td className="fw-medium">
                                    <Link to="#" className="fw-medium">
                                      {`#${org?.organisations?.uuid?.slice(
                                        0,
                                        6
                                      )}` || "NA"}
                                    </Link>
                                  </td>
                                  <td>{org?.organisations?.name || "NA"}</td>
                                  <td>
                                    {org?.organisations?.organisation_code ||
                                      "NA"}
                                  </td>
                                  <td>
                                    {org?.organisations?.postcode || "NA"}
                                  </td>
                                  <td>
                                    {org?.organisations
                                      ?.contact_telephone_number || "NA"}
                                  </td>
                                  <td>
                                    {" "}
                                    <UncontrolledDropdown className="align-self-start message-box-drop">
                                      <DropdownToggle
                                        className="btn-soft-secondary btn-sm dropdown"
                                        tag="button"
                                      >
                                        <i className="ri-more-2-fill"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        <DropdownItem
                                          onClick={() =>
                                            history.push(
                                              `/pm-sessions/${org?.organisations?.uuid}`
                                            )
                                          }
                                        >
                                          <i className="ri-instance-line me-2 text-muted align-bottom"></i>
                                          Session List
                                        </DropdownItem>
                                        {org?.organisations
                                          ?.organisation_profiles[0]?.active ? (
                                          <DropdownItem
                                            onClick={() =>
                                              fetchSuspendPMForOrganisation(
                                                id,
                                                org.organisations.uuid,
                                                0,
                                                token
                                              )
                                            }
                                          >
                                            <i className="ri-stop-circle-line me-2 text-danger align-bottom"></i>
                                            Suspend
                                          </DropdownItem>
                                        ) : (
                                          <DropdownItem
                                            onClick={() =>
                                              fetchSuspendPMForOrganisation(
                                                id,
                                                org.organisations.uuid,
                                                1,
                                                token
                                              )
                                            }
                                          >
                                            <i className="bx bx-briefcase-alt me-2 text-muted align-bottom"></i>
                                            Un-suspend
                                          </DropdownItem>
                                        )}
                                      </DropdownMenu>
                                    </UncontrolledDropdown>
                                  </td>
                                </tr>
                              </tbody>
                            );
                          })
                        ) : (
                          <tbody>
                            <tr>
                              <td className="fw-medium"></td>
                              <td>No organisation found</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                          </tbody>
                        )}
                      </Table>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>

      <Modal
        isOpen={showAddOrganisationModal}
        toggle={() => setShowOrganisationsModal(!showAddOrganisationModal)}
        scrollable={true}
      >
        <ModalHeader
          className="modal-title"
          toggle={() => setShowOrganisationsModal(!showAddOrganisationModal)}
        >
          Add Organisation
        </ModalHeader>
        <ModalBody>
          <form className="needs-validation" action="#" onSubmit={handleSubmit}>
            <div className="row g-3">
              <div className="mb-3">
                <Label htmlFor="organisations-field" className="form-label">
                  Select Organisation <span className="text-danger">*</span>
                </Label>
                <Select
                  inputId="organisations-field"
                  isClearable
                  isMulti={false}
                  name="organisation"
                  onInputChange={(e) => setOrgText(e)}
                  options={orgDetails || []}
                  maxMenuHeight={210}
                  value={selectedOrg}
                  closeMenuOnSelect={true}
                  onMenuScrollToBottom={handleScroll}
                  onChange={handleOrgChange}
                />
                {organisationError && (
                  <FormFeedback type="invalid">
                    <div>Please select your organisation</div>
                  </FormFeedback>
                )}
              </div>
              <div className="mb-3">
                <Label htmlFor="it-system-field" className="form-label">
                  IT System <span className="text-danger">*</span>
                </Label>
                <Input
                  name="itSystem"
                  type="select"
                  id="it-system-field"
                  placeholder="Please select your primary IT system"
                  onChange={handleItSystemChange}
                >
                  <option>Select your IT system </option>
                  {itSystem.map((_) => (
                    <option key={_?.uuid} value={_?.uuid}>
                      {_?.name || "NA"}
                    </option>
                  ))}
                </Input>
              </div>
            </div>

            <Col xxl={12}>
              <div className="modal-footer">
                <Button
                  color="light"
                  onClick={() => setShowOrganisationsModal(false)}
                >
                  Close
                </Button>
                <Button color="primary" type="submit">
                  Allocate
                </Button>
              </div>
            </Col>
          </form>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default PmOrganisaitonList;
