import moment from "moment";
import React from "react";
import { useState } from "react";

import { Button, Form, Modal, ModalBody, ModalHeader, Table } from "reactstrap";

const UserDetailsModal = ({ open, handleClose, toggle, selectedClient }) => {
    const [col1, setcol1] = useState(false);
    const [col2, setcol2] = useState(false);
    const t_col1 = () => {
        setcol1(!Boolean(true));
    };
    const t_col2 = () => {
        setcol2(!col2);
    };

    return (
        <>
            <Modal isOpen={open} id="event-modal" centered>
                <ModalHeader toggle={toggle} className="p-3 bg-airgp modal-title">
                    <div className="modal-title text-white h5">Client Information</div>
                </ModalHeader>
                <ModalBody>
                    <Form
                        className={"needs-validation view-event"}
                        name="event-form"
                        id="form-event"
                        onSubmit={(e) => e.preventDefault()}
                    >
                        <div className="event-details">
                            <div className="table-responsive">
                                <Table className="table-borderless mb-">
                                    <tbody>
                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-home-4-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            Name :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {selectedClient?.title} {selectedClient?.firstName || "NA"}{" "}
                                                        {selectedClient?.lastName || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-money-pound-box-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            Role :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {selectedClient?.role || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-centos-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            Email :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {selectedClient?.email || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-pushpin-2-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            Contact :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {selectedClient?.User_profile?.contact_number || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-space-ship-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            Date of birth :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {moment(selectedClient?.User_profile?.dob).format("ll") || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-route-fill text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            Employment Type :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6
                                                        className="d-block fw-semibold text-capitalize"
                                                        id="event-start-date-tag"
                                                    >
                                                        {selectedClient?.User_profile?.employment_type || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-money-pound-box-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            Regulator Type :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {selectedClient?.User_profile?.regulator_type || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-calendar-event-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            regulator number :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {selectedClient?.User_profile?.regulator_number || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-money-pound-box-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            smartcard number :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {selectedClient?.User_profile?.smartcard_number || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-money-pound-box-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            Postcode :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {selectedClient?.User_profile?.postcode || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-home-4-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            Address :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {selectedClient?.User_profile?.address_line_1 ? (
                                                            selectedClient?.User_profile?.address_line_1
                                                        ) : (
                                                            <p></p>
                                                        )}
                                                        {selectedClient?.User_profile?.address_line_2 ? (
                                                            selectedClient?.User_profile?.address_line_2
                                                        ) : (
                                                            <p></p>
                                                        )}
                                                        {selectedClient?.User_profile?.address_line_3 ? (
                                                            selectedClient?.User_profile?.address_line_3
                                                        ) : (
                                                            <p></p>
                                                        )}
                                                        {selectedClient?.User_profile?.address_line_4 ? (
                                                            selectedClient?.User_profile?.address_line_4
                                                        ) : (
                                                            <p></p>
                                                        )}
                                                        {selectedClient?.User_profile?.address_line_5 ? (
                                                            selectedClient?.User_profile?.address_line_5
                                                        ) : (
                                                            <p></p>
                                                        )}
                                                        {selectedClient?.User_profile?.postcode ? (
                                                            selectedClient?.User_profile?.postcode
                                                        ) : (
                                                            <p></p>
                                                        )}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th className="ps-0 fw-semibold mb-0 p-1" scope="row">
                                                <div className="flex-grow-1 d-flex align-items-baseline">
                                                    <div className="flex-shrink-0 me-3">
                                                        <i className="ri-space-ship-line text-muted fs-16"></i>{" "}
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <h6
                                                            className="d-block fw-semibold  text-uppercase"
                                                            id="event-start-date-tag"
                                                        >
                                                            last Logged :
                                                        </h6>
                                                    </div>
                                                </div>
                                            </th>
                                            <td className="p-1">
                                                <div className="flex-grow-1">
                                                    <h6 className="d-block fw-semibold " id="event-start-date-tag">
                                                        {moment(selectedClient?.lastLoggedIn).format("lll") || "NA"}
                                                    </h6>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <Button color="light" onClick={handleClose} className="margin-top">
                                Close
                            </Button>
                        </div>
                    </Form>
                </ModalBody>
            </Modal>
        </>
    );
};

export default UserDetailsModal;
