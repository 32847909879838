import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table
} from 'reactstrap'
import { clientServices } from '../../services/clientServices'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const INVITATION_DATA = {
  email: '',
  invitation_type: 'first',
  invitation_template: '1'
}

const InvitaionModal = ({ open, toggle, handleClose, selectedPerformer, handleSuccess }) => {
  const [template, setTemplate] = useState([])
  const [invitation, setInvitation] = useState(INVITATION_DATA)

  const token = localStorage.getItem('token')
  const history = useHistory()

  useEffect(() => {
    fetchTemplates(token)
  }, [])

  const fetchTemplates = token => {
    clientServices
      .getTemplatesData(token)
      .then(resp => {
        if (resp.success) {
          setTemplate(resp.data)
        }else{
          if(resp?.message === 'Unauthorised'){
            localStorage.clear();
            history.push('/login');
          }
        }
      })
      .catch(err => console.log(err))
  }

  const handleInvitaionChange = ({ target: { name, value } }) => {
    const copiedInvitaionData = { ...invitation }
    copiedInvitaionData[name] = value
    setInvitation(copiedInvitaionData)
  }

  const handleSendInvitationClick = () => {
    if (!invitation.email) {
      toast.error('Please enter email.')
      return
    }

    const data = {
      performer_uuid: selectedPerformer.uuid,
      invitation_email: invitation.email,
      invitation_type: invitation.invitation_type,
      invitation_template: invitation.invitation_template
    }

    clientServices
      .postSendInvitation(data, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          handleClose()
          handleSuccess()
        } else {
          toast.error(resp.error[0].message)
        }
        console.log(resp)
      })
      .catch(err => console.log(err))
  }
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader className='p-3 bg-soft-info modal-title'>
        Send Invitation
      </ModalHeader>
      <ModalBody className=''>
        <div className='mt-2'>
          <Form
            className={'needs-validation view-event'}
            name='event-form'
            id='form-event'
            onSubmit={e => e.preventDefault()}
          >
            <div className='event-details'>
              <div className='table-responsive'>
                <Table className='table-borderless mb-'>
                  <tbody>
                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-center'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-home-4-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Name :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedPerformer
                              ? selectedPerformer['ForeName(s)']
                              : ''}{' '}
                            {selectedPerformer?.Surname || 'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-center'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-home-4-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Performer Role :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedPerformer
                              ? selectedPerformer['Performer Role']
                              : '' || 'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-center'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-centos-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              STATUS :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedPerformer?.Status || 'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-center'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-centos-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Probationary Period :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedPerformer
                              ? selectedPerformer[
                                  'Currently in Probationary Period'
                                ]
                              : '' || 'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Form>
          <div>
            <Label htmlFor='formtextInput' className='form-label'>
              Enter Email
            </Label>
            <Input
              type='email'
              name='email'
              className='form-control'
              id='formtextInput'
              placeholder='Enter email to send invitation...'
              value={invitation.email || ''}
              onChange={handleInvitaionChange}
            />
            <div id='passwordHelpBlock' className='form-text'>
              Must be 8-20 characters long.
            </div>
          </div>
          <Row className='g-3 mt-1'>
            <Col lg={6}>
              <Label htmlFor='formtextInput' className='form-label'>
                Select Template
              </Label>
              <div className='input-group'>
                <select
                  className='form-select'
                  id='inputGroupSelect01'
                  name='invitation_template'
                  onChange={handleInvitaionChange}
                  placeholder='Please select invitaion template'
                >
                  {template.map(item => (
                    <option defaultValue={item} key={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
            </Col>
            <Col lg={6}>
              <Label htmlFor='formtextInput' className='form-label'>
                Select Invitaion Type
              </Label>
              <div className='input-group'>
                <select
                  className='form-select'
                  id='inputGroupSelect02'
                  onChange={handleInvitaionChange}
                  name='invitation_type'
                >
                  <option defaultValue='first'>First</option>
                  <option defaultValue='second'>Second</option>
                  <option defaultValue='third'>Third</option>
                </select>
              </div>
            </Col>
          </Row>
          <div className='hstack gap-4 mt-3 justify-content-end'>
            <Button color='light' onClick={handleClose}>
              Close
            </Button>
            <Link
              to='#'
              className='btn btn-danger'
              onClick={handleSendInvitationClick}
            >
              Send
            </Link>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default InvitaionModal
