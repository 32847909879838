import React, { useState, useEffect, useCallback } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import UiContent from '../../../Components/Common/UiContent'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { pmServices } from '../../../services/pmServices'
import PaginationV2 from '../../../Components/Common/PaginationV2'
import SpinnerComponent from '../../../Components/Common/Spinner'

let LIMIT = 20
const PMListTable = () => {
  document.title = 'PM List | AirGP'
  const [page, setPage] = useState(1)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [pmUserList, setPMUserList] = useState([])
  const [isLoading , setIsLoading] = useState(false)
  const history = useHistory()
  const token = localStorage.getItem('token')

  useEffect(() => {
    fetchPMList()
  }, [page])

  const fetchPMList = () => {
    setIsLoading(true)
    pmServices
      .getPMListSuperAdmin('', page, LIMIT, token)
      .then(resp => {
        console.log(resp);
        if (resp.status) {
          setPMUserList(resp?.data || [])
          setTotalPageCount(resp.totalPages || 1)
        }else{
          if(resp?.message === "Unauthorised"){
            localStorage.clear();
            history.push('/login');
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchSearchPmList = e => {
    const { value } = e.target
    pmServices
      .getPMListSuperAdmin(value, '', '', token)
      .then(resp => {
        if (resp.status) {
          setPMUserList(resp?.data || [])
        }
      })
      .catch(err => console.log(err))
  }

  const debouncingSearchFunction = func => {
    let timer
    return function (...args) {
      const context = this
      console.log(context)
      clearTimeout(timer)
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 300)
    }
  }

  const handleOnKeyUpSearchBox = useCallback(
    debouncingSearchFunction(fetchSearchPmList),
    []
  )
  document.title = 'PM List | airGP'

  return (
    <div>
      <React.Fragment>
        <UiContent />
        <div className='page-content'>
          {isLoading && <SpinnerComponent />}
          <Container fluid>
            <Row>
              <Col>
                  <Card>
                    <CardHeader className='card-title text-light mb-0'>
                    
                    <div className={'search-box float-end '}>
                      <input
                        onKeyUp={handleOnKeyUpSearchBox}
                        id='search-bar-0'
                        type='text'
                        className='form-control search '
                        placeholder={`Search...`}
                      />
                      <i className='ri-search-line search-icon'></i>
                    </div>
                    <p className='pt-2'>Practice managers list</p>
                    </CardHeader>
                    <CardBody>
                          <div className='live-preview'>
                            <div className='table-responsive table-card'>
                              <Table className='align-middle table-nowrap table-sm mb-0'>
                                <thead className='table-light'>
                                  <tr>
                                    <th
                                      scope='col'
                                      style={{ width: '46px' }}
                                    ></th>
                                    <th scope='col'>ID</th>
                                    <th scope='col'>Name</th>
                                    <th scope='col'>Email</th>
                                    <th scope='col'>Role</th>
                                    <th scope='col'>Phone No</th>
                                    <th scope='col'>Action</th>
                                  </tr>
                                </thead>
                                {pmUserList.length !== 0 ? (
                                  pmUserList.map((_, index) => {
                                    return (
                                      <tbody key={`admin-${index}`}>
                                        <tr>
                                          <td>
                                            <div className='form-check'>
                                              <Input
                                                className='form-check-input'
                                                type='checkbox'
                                                defaultValue=''
                                                id='cardtableCheck01'
                                              />
                                              <Label
                                                className='form-check-label'
                                                htmlFor='cardtableCheck01'
                                              ></Label>
                                            </div>
                                          </td>
                                          <td>
                                            <Link to='#' className='fw-medium'>
                                              {`#${_?.uuid.slice(0, 5)}` ||
                                                'NA'}
                                            </Link>
                                          </td>
                                          <td>
                                            {_?.firstName} {_?.lastName}
                                          </td>
                                          <td>{_?.email || 'NA'}</td>
                                          <td>
                                            <span className='badge bg-success'>
                                              {_?.role || 'NA'}
                                            </span>
                                          </td>
                                          <td>{_?.phone_number || 'NA'}</td>
                                          <td>
                                            <UncontrolledDropdown className='align-self-start message-box-drop'>
                                              <DropdownToggle
                                                className='btn-soft-secondary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-2-fill'></i>
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem
                                                  onClick={() =>
                                                    history.push(
                                                      `pm-organisation/${_?.uuid}/organisations`
                                                    )
                                                  }
                                                >
                                                  <i className='ri-home-4-line me-2 text-muted align-bottom'></i>
                                                  Organisation
                                                </DropdownItem>
                                                {/* <DropdownItem>
                                                  <i className='ri-file-list-3-fill me-2 text-muted align-bottom'></i>
                                                  Add User
                                                </DropdownItem> */}
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )
                                  })
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td className='fw-medium'></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No User found</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </Table>
                            </div>
                          </div>
                        </CardBody>

                  <PaginationV2
                    totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                    previousDisabled={page === 1}
                    nextDisabled={totalPageCount === page ? true : false}
                    previousPageCall={() => setPage(page - 1)}
                    currentPage={page}
                    nextPageCalls={() => setPage(page + 1)}
                    onPageInputChange={({ target: { value } }) => {
                      var regex = new RegExp(`^[1-${totalPageCount}]$`)
                      if (value <= totalPageCount && value !== 0) {
                        setPage(value)
                      }
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default PMListTable
