import React from 'react'
import { Redirect } from 'react-router-dom'

//Invoices
import InvoiceList from '../pages/Invoices/InvoiceList'
import InvoiceCreate from '../pages/Invoices/InvoiceCreate'
import InvoiceDetails from '../pages/Invoices/InvoiceDetails'

import BasicTwosVerify from '../pages/Authentication/TwoStepVerification/BasicTwosVerify'
import CoverTwosVerify from '../pages/Authentication/TwoStepVerification/CoverTwosVerify'
import Basic404 from '../pages/Authentication/Errors/Basic404'
import Cover404 from '../pages/Authentication/Errors/Cover404'
import Alt404 from '../pages/Authentication/Errors/Alt404'
import Error500 from '../pages/Authentication/Errors/Error500'

// Table
import UserListTable from '../pages/BasicTables/ClientListTable/UserListTable'
import SessionListTable from '../pages/BasicTables/ListTable'
//login
import Login from '../pages/Authentication/Login'
import ForgetPasswordPage from '../pages/Authentication/ForgetPassword'
import Logout from '../pages/Authentication/Logout'
import Register from '../pages/Authentication/Register'

// Landing Page
// import OnePage from '../pages/Landing'

// User Profile
import UserProfile from '../pages/Authentication/user-profile'
import BasicPasswReset from '../pages/Authentication/PasswordCreate/BasicPasswCreate'
import BasicSuccessMsg from '../pages/Authentication/SuccessMessage/BasicSuccessMsg'
import PasswordSuccess from '../pages/Authentication/SuccessMessage/PasswordSuccess'
import PMListTable from '../pages/BasicTables/PMListTables/PMListTable'
import PmOrganisaitonList from '../pages/BasicTables/PMListTables/PmOrganisaitonList'
import PMSessionList from '../pages/BasicTables/PMListTables/PMSessionList'
import DocumentListTable from '../pages/BasicTables/DocumentListTable'
import OrganisationListTable from '../pages/BasicTables/OrganisationListTable/OrganisationListTable'
import PMApplications from '../pages/BasicTables/PMApplications'
import PmInvoices from '../pages/PM-invoices/PmInvoices'
import GPInvitation from '../pages/Invitation/GPInvitation'
import PMInvitation from '../pages/Invitation/PMInvitation'
import StaffList from '../pages/Staff/StaffList'
import OrganisationPmList from '../pages/BasicTables/OrganisationListTable/OrganisationPmList'
import Sessions from '../pages/Sessions/Sessions'

const authProtectedRoutes = [
  //Invoices
  { path: '/apps-invoices-list', component: InvoiceList },
  { path: '/apps-invoices-details', component: InvoiceDetails },
  { path: '/apps-invoices-create', component: InvoiceCreate },
  { path: '/client-list', component: UserListTable },
  { path: '/list/:id/:name', component: SessionListTable },
  //User Profile
  // { path: '/profile', component: UserProfile },
  { path: '/practice-manager', component: PMListTable },
  { path: '/pm-organisation/:id/:name', component: PmOrganisaitonList },
  { path: '/pm-sessions/:id', component: PMSessionList },
  { path: '/documents', component: DocumentListTable },
  { path: '/organisations', component: OrganisationListTable },
  { path: '/pm-applications', component: PMApplications },
  { path: '/pm-invoices', component: PmInvoices },
  { path: '/gp-invitation', component: GPInvitation },
  { path: '/pm-invitation', component: PMInvitation },
  { path: '/staff-list', component: StaffList },
  { path: '/pm-list', component: OrganisationPmList },
  { path: '/sessions', component: Sessions },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to='/documents' />
  }
]

const publicRoutes = [
  // Authentication Page
  { path: '/logout', component: Logout },
  { path: '/login', component: Login },
  { path: '/auth/reset-password', component: BasicPasswReset },
  // { path: '/forgot-password', component: ForgetPasswordPage },
  { path: '/success', component: BasicSuccessMsg },
  { path: '/password-reset-succeed', component: PasswordSuccess },
  // { path: '/register', component: Register },

  //AuthenticationInner pages
  { path: '/auth-twostep-basic', component: BasicTwosVerify },
  { path: '/auth-twostep-cover', component: CoverTwosVerify },
  { path: '/auth-404-basic', component: Basic404 },
  { path: '/auth-404-cover', component: Cover404 },
  { path: '/auth-404-alt', component: Alt404 },
  { path: '/auth-500', component: Error500 },

  // Landing Page
  // { path: '/landing', component: OnePage }
]

export { authProtectedRoutes, publicRoutes }
