export const procedureTypeCodeClass = _type => {
  switch (_type.toLowerCase()) {
    case 'completed':
      return 'success'
    case 'withdrawn':
      return 'danger'
    case 'manual':
      return 'info'
    case 'published':
      return 'secondary'
    case 'market_place':
      return 'info'
    case 'rejected':
      return 'danger'
    case 'awaiting':
      return 'warning'
    case 'accepted':
      return 'success'
    case 'approved':
      return 'success'
    case 'awaiting_approval':
      return 'warning'
    case 'cancelled':
      return 'danger'
    case 'paid':
      return 'success'
    case 'expired':
      return 'danger'
    case 'registered':
      return 'success'
    case 'unregistered':
      return 'danger'
    default:
      return 'primary'
  }
}

export const PM_SESSION_CREATE_VALUES = {
  orgId: '',
  sessionJobRole: '',
  sessionJobType: '',
  startTime: '',
  endTime: '',
  appointmentLength: '',
  about: '',
  hourlyRate: '',
  breakDuration: '',
  sessionFeatures: [],
  defaultJobType: '',
  defaultJobRole: '',
  defaultOrg: '',
  formatedStartTime: '',
  formatedEndTime: ''
}

export const convertTimeToMinute = (sTime, eTime, breakDuration) => {
  const startTime = new Date(sTime).valueOf()
  const endTime = new Date(eTime).valueOf()
  const durationTs = endTime - startTime
  const durationInSecondes = durationTs / 1000
  const durationInMinutes = durationInSecondes / 60
  const totalMinutes = durationInMinutes - breakDuration

  return totalMinutes || '0'
}

export const calculatePensionablePay = (fee, expence) => {
  const total = fee + expence
  const contributionPay = ((total * 90) / 100) * 0.1438
  // let finalValue = total - contributionPay
  return contributionPay.toFixed(2)
}

export const PerformerRole = [
  { type: 'GP Performer', checked: false },
  { type: 'Emergency Registered Practitioner', checked: false },
  { type: 'Armed Services Type 1', checked: false },
  { type: 'Armed Services Type 2', checked: false },
  { type: 'GP Contractor', checked: false }
]

export const PerformerStatus = [
  { type: 'included', checked: false },
  { type: 'Included with Conditions', checked: false }
]

export const badgeColor = type => {
  switch (type.toLowerCase()) {
    case 'booked':
      return 'success'
    case 'completed':
      return 'success'
    case 'paid':
      return 'success'
    case 'worked':
      return 'success'
    case 'applied':
      return 'primary'
    case 'manual':
      return 'info'
    case 'draft':
      return 'info'
    case 'published':
      return 'warning'
    case 'withdrawn':
      return 'danger'
    case 'unfilled':
      return 'info'
    default:
      return 'warning'
  }
}

export const BILLING_TABS = [
  'SESSIONS COMPLETED',
  'SESSIONS INVOICED',
  '-SESSIONS PM INVOICED-',
  'SESSIONS PAID',
  'SESSION PAID BY ADMIN',
  'DIRECT DEBIT'
];

export const SESSIONS_TABS = [
  'ALL',
  'PUBLISHED',
  'BOOKED',
  'WORKED',
  'DRAFT'
];