import moment from 'moment'
import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import classnames from 'classnames'

import {
  Accordion,
  AccordionItem,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Table,
  UncontrolledTooltip
} from 'reactstrap'
import { clientServices } from '../../services/clientServices'
import Select from 'react-select'

const ViewClientInfoModal = ({
  open,
  handleClose,
  toggle,
  selectedClient,
  allRegistrationStatuses,
  handleSuccess
}) => {
  const [bonusPercent, setBonusPercent] = useState(0)
  const [gmcStatus, setGmcStatus] = useState({
    label:selectedClient?.lrmp?.registrationStatus.registration_status_name ? selectedClient?.lrmp?.registrationStatus.registration_status_name : 'Select',
    value:selectedClient?.lrmp?.registrationStatus.registration_status_name ? selectedClient?.lrmp?.registrationStatus.registration_status_name : '',
   })
  const token = localStorage.getItem('token')

  const [col1, setcol1] = useState(false)
  const [col2, setcol2] = useState(false)

  const t_col1 = () => {
    setcol1(!Boolean(true))
  }

  const t_col2 = () => {
    setcol2(!col2)
  }

  useEffect(() => {
    if (selectedClient)
    setBonusPercent((selectedClient?.User_profile?.bonus_fee_percentage ?? 0) * 100);
      setGmcStatus({
        label:selectedClient?.lrmp?.registrationStatus.registration_status_name ? selectedClient?.lrmp?.registrationStatus.registration_status_name : 'Select',
        value:selectedClient?.lrmp?.registrationStatus.registration_status_name ? selectedClient?.lrmp?.registrationStatus.registration_status_name : '',
       })
  }, [selectedClient])

  const fetchUpdateBonusPercentage = (data, id, token) => {
    if (bonusPercent === undefined || bonusPercent === null) {
      toast.error('Please enter bonus percentage!')
      return
    }

    if (!(gmcStatus.value)) {
      toast.error('Please enter Gmc Status!')
      return
    }

    clientServices
      .postUpdateBonusPercentage(data, id, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          handleClose()
          handleSuccess()
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchSuspendClient = (id, suspend, toke) => {
    clientServices
      .postSuspendClient(id, suspend, toke)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          handleClose()
          handleSuccess()
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }
  return (
    <>
      <Modal isOpen={open} id='event-modal' centered>
        <ModalHeader
          toggle={toggle}
          className='p-3 bg-airgp modal-title'
        > 
          <div className="modal-title text-white h5">Client Information</div>          
        </ModalHeader>
        <ModalBody>
          <Form
            className={'needs-validation view-event'}
            name='event-form'
            id='form-event'
            onSubmit={e => e.preventDefault()}
          >
            <div className='event-details'>
              <div className='table-responsive'>
                <Table className='table-borderless mb-'>
                  <tbody>
                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-home-4-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Name :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.title}{' '}
                            {selectedClient?.firstName || 'NA'}{' '}
                            {selectedClient?.lastName || 'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-centos-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Email :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.email || 'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-pushpin-2-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Contact :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.User_profile?.contact_number ||
                              'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-space-ship-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Date of birth :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {moment(selectedClient?.User_profile?.dob).format(
                              'll'
                            ) || 'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-route-fill text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Employment Type :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold text-capitalize'
                            id='event-start-date-tag'
                          >
                            {selectedClient?.User_profile?.employment_type ||
                              'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-money-pound-box-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Regulator Type :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.User_profile?.regulator_type ||
                              'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-money-pound-box-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Intanst Booked :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.activeInstantBookingsCount}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-money-pound-box-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Banned :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.activeBannedUsersCount}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-calendar-event-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              regulator number :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.User_profile?.regulator_number ||
                              'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-money-pound-box-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              smartcard number :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.User_profile?.smartcard_number ||
                              'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-money-pound-box-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Postcode :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.User_profile?.postcode ||
                              'NA'}
                          </h6>
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                        <div className='flex-grow-1 d-flex align-items-baseline'>
                          <div className='flex-shrink-0 me-3'>
                            <i className='ri-home-4-line text-muted fs-16'></i>{' '}
                          </div>
                          <div className='flex-grow-1'>
                            <h6
                              className='d-block fw-semibold  text-uppercase'
                              id='event-start-date-tag'
                            >
                              Address :
                            </h6>
                          </div>
                        </div>
                      </th>
                      <td className='p-1'>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold '
                            id='event-start-date-tag'
                          >
                            {selectedClient?.User_profile?.address_line_1 ? selectedClient?.User_profile?.address_line_1 : <p></p>}
                            {selectedClient?.User_profile?.address_line_2 ? selectedClient?.User_profile?.address_line_2 : <p></p>}
                            {selectedClient?.User_profile?.address_line_3 ? selectedClient?.User_profile?.address_line_3 : <p></p>}
                            {selectedClient?.User_profile?.address_line_4 ? selectedClient?.User_profile?.address_line_4 : <p></p>}
                            {selectedClient?.User_profile?.address_line_5 ? selectedClient?.User_profile?.address_line_5 : <p></p>}
                            {selectedClient?.User_profile?.postcode ? selectedClient?.User_profile?.postcode : <p></p>}
                          </h6>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </div>
            <Col xl={12}>
              <div className='mb-4'>
                <Label htmlFor='bonus-percentage' className='form-label'>
                  Bonus Percentage (0 - 100)
                </Label>
                <Input
                  type='text'
                  name='Percentage'
                  value={bonusPercent}
                  onChange={event => setBonusPercent(event.target.value)}
                  className='form-control'
                  id='bonus-percentage'
                  placeholder='Enter Bonus Percentage'
                />
              </div>
            </Col>
            <Col xl={12}>
              <div className='mb-4'>
                <Label htmlFor='gmc-status' className='form-label'>
                  GMC Status
                </Label>
                  <Select
                    isClearable={true}
                    inputId='gmc-status'
                    isMulti={false}
                    isSearchable={false}
                    options={allRegistrationStatuses || []}
                    onChange={e => {
                      if(e !== null){
                        const copiedPension = { ...gmcStatus }
                        copiedPension.value = e.value
                        copiedPension.label = e.label
  
                        setGmcStatus(copiedPension)
                      }else{
                        setGmcStatus({
                          label:selectedClient?.lrmp?.registrationStatus?.registration_status_name,
                          value:selectedClient?.lrmp?.registrationStatus?.registration_status_name
                        })
                      }
                    }}
                    value={gmcStatus}
                  />
              </div>
            </Col>
            <div className='modal-footer'>
              <div className='margin-top'>
                <Link
                  to='#'
                  className='btn btn-success'
                  id='tooltipDel'
                  onClick={() =>
                    fetchUpdateBonusPercentage(
                      { bonus_percentage: `${bonusPercent / 100}`, gmcStatus: gmcStatus.value },
                      selectedClient.uuid,
                      token
                    )
                  }
                >
                  {/* <i className='ri-delete-bin-6-line align-bottom me-1'></i>{' '} */}
                  Save
                </Link>
                <UncontrolledTooltip placement='bottom' target='tooltipDel'>
                  Save bonus percentage
                </UncontrolledTooltip>
              </div>
              {selectedClient?.active === 1 ? (
                <Button
                  color='danger'
                  onClick={() =>
                    fetchSuspendClient(selectedClient.uuid, 0, token)
                  }
                  className='margin-top'
                >
                  Suspend
                </Button>
              ) : (
                <Button
                  color='danger'
                  onClick={() =>
                    fetchSuspendClient(selectedClient.uuid, 1, token)
                  }
                  className='margin-top'
                >
                  Un-suspend
                </Button>
              )}
              <Button
                color='light'
                onClick={handleClose}
                className='margin-top'
              >
                Close
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default ViewClientInfoModal
