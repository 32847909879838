const METHODS = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  del: 'DELETE',
  patch: 'PATCH'
}

const generateAbsPath = relativePath =>
  `${process.env.REACT_APP_LOCAL_BASE_URL?.replace(
    /\/$/,
    ''
  )}/${relativePath.replace(/^\//, '')}`

const AUTH_SERVICE_BASE_URL = generateAbsPath('/admin-api/v1/auth')
const CLIENT_DATA_BASE_URL = generateAbsPath('/admin-api/v1')

export const POST_LOGIN_SUPER_ADMIN = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/login`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_FORGOT_PASSWORD_SUPER_ADMIN = {
  endpoint: `${AUTH_SERVICE_BASE_URL}/forgot-password`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_RESET_PASSWORD_SUPER_ADMIN = {
  endpoint: (id, token) =>
    `${AUTH_SERVICE_BASE_URL}/reset-password/${id}/${token}`,
  method: METHODS.post,
  successStatusCode: 200
}

// Client UI endpoints

export const GET_USERS_LIST_SUPER_ADMIN = {
  endpoint: (key, page, limit) =>
    `${CLIENT_DATA_BASE_URL}/user?key=${key ? key : ''}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 20}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_USERS_LIST_PROFILE_SUPER_ADMIN = {
  endpoint: (key, page, limit) =>
  `${process.env.REACT_APP_AIRGP_BACKEND_URL}/api/v1/user-profile/admin?key=${key ? key : ''}&page=${ page ? page : 1 }&limit=${limit ? limit : 20}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_SESSION_AS_CLIENT_SUPER_ADMIN = {
  endpoint: (id, page, limit) =>
    `${CLIENT_DATA_BASE_URL}/user/${id}/session?page=${page ? page : 1}&limit=${
      limit ? limit : 20
    }`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_INVOICES_AS_CLIENT_SUPER_ADMIN = {
  endpoint: (id, page, limit) =>
    `${CLIENT_DATA_BASE_URL}/user/${id}/invoices?page=${
      page ? page : 1
    }&limit=${limit ? limit : 20}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_DOCUMETN_AS_CLIENT_SUPER_ADMIN = {
  endpoint: (id, page, limit) =>
    `${CLIENT_DATA_BASE_URL}/user/${id}/documents?page=${
      page ? page : 1
    }&limit=${limit ? limit : 20}`,
  method: METHODS.get,
  successStatusCode: 200
}

////////////////////////////////////////////////// **** PM Endpoints ****/////////////////////////////////////////////////////////////

export const GET_ALL_PM_AS_SUPER_ADMIN = {
  endpoint: (key, page, limit) =>
    `${CLIENT_DATA_BASE_URL}/user/pm?key=${key ? key : ''}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 20}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_ORGANIATIONS_AS_PM_SUPER_ADMIN = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/user/${id}/organisation`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_SESSIONS_AS_PM_SUPER_ADMIN = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/user/${id}/sessions`,
  method: METHODS.get,
  successStatusCode: 200
}

///////////////////////////////////////////////// **** Document endpoints **** ////////////////////////////

export const GET_DOCUMENT_AS_DV_SUPER_ADMIN = {
  endpoint: (limit, page, key) =>
    `${process.env.REACT_APP_AIRGP_BACKEND_URL}/api/v1/user-profile/admin/document?key=${key ? key : ''}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 20}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const PATCH_CHANE_DOCUMENT_STATUS_SUPER_ADMIN = {
  endpoint: `${CLIENT_DATA_BASE_URL}/document/document-status`,
  method: METHODS.patch,
  successStatusCode: 200
}

export const GET_ALL_ORGANISATIONS_SUPER_ADMIN = {
  endpoint: (key, page, limit, registered) =>
    `${CLIENT_DATA_BASE_URL}/organisation?key=${key ? key : ''}&page=${
      page ? page : 1
    }&limit=${limit ? limit : 20}&registered=${registered}`,
  method: METHODS.get,
  successStatusCode: 200
}


export const DOWNLOAD_DOCUMENT = {
  endpoint: (pdf_uuid, status) =>
    `${process.env.REACT_APP_AIRGP_BACKEND_URL}/api/v1/aws/fetch-pdf?pdf_uuid=${pdf_uuid}&status=${status}`,
  method: METHODS.get
}

////////////////////////////////////////**** Applications ****/////////////////////////////////////////////////

export const GET_PM_APPLIED_ORGANISATIONS_SUPER_ADMIN = {
  endpoint: (page, limit) =>
`${CLIENT_DATA_BASE_URL}/organisation/pm-applied?key=&page=${page ? page : 1}&limit=${limit ? limit : 20}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_ORGANISATIONS_STATUS_SUPER_ADMIN = {
  endpoint: `${CLIENT_DATA_BASE_URL}/organisation/statuses`,
  method: METHODS.get,
  successStatusCode: 200
}

export const PATCH_ORGANISATIONS_STATUS_CHANGE_SUPER_ADMIN = {
  endpoint: `${CLIENT_DATA_BASE_URL}/organisation/change-status`,
  method: METHODS.patch,
  successStatusCode: 200
}

export const POST_ADD_NEW_ORGANISATIONS = {
  endpoint: `${CLIENT_DATA_BASE_URL}/organisation`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_UPDATE_DIRECT_DEBIT = {
  endpoint: `${CLIENT_DATA_BASE_URL}/organisation/direct_debit`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_SET_FEE_PERCENTAGE = {
  endpoint: `${CLIENT_DATA_BASE_URL}/organisation/set-fee-percentage`,
  method: METHODS.post,
  successStatusCode: 200
}

///////////////////////////////////////////////***** Session services  *****////////////////////////////////////////////

export const GET_SESSION_FEATURES = {
  endpoint: `${CLIENT_DATA_BASE_URL}/features`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_WORKED_SESSIONS = {
  endpoint: (limit, page, key) =>
    `${CLIENT_DATA_BASE_URL}/admin/session/worked${`?limit=${limit ? limit : 20}&page=${page ? page : 1}&key=${key}`
    }`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_SESSIONS = {
  endpoint: (status, page, limit) =>
      `${CLIENT_DATA_BASE_URL}/admin/session${status ? `?status=${status}` : ""}${page ? `&page=${page}` : 1}${limit ? `&limit=${limit}` : 20}`,
  method: METHODS.get,
  successStatusCode: 200,
};

export const GET_SESSION_CLIENTS_BY_ID = {
  endpoint: (id, clientId) =>
    `${process.env.REACT_APP_AIRGP_BACKEND_URL}/api/v1/sessions/${id}${clientId ? `?clientId=${clientId}` : ''}`,
  method: METHODS.get,
  successStatusCode: 200
};

export const GET_SESSION_JOB_ROLE = {
  endpoint: `${CLIENT_DATA_BASE_URL}/user-types`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_SESSION_JOB_TYPE = {
  endpoint: `${CLIENT_DATA_BASE_URL}/admin/session/types`,
  method: METHODS.get,
  successStatusCode: 200
}

export const PATCH_EDIT_PM_SESSION = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/admin/session/${id}`,
  method: METHODS.patch,
  successStatusCode: 200
}

////////////////////////////////////////////////////////PM-invioces///////////////////////////////////////////////////////////////////////

export const GET_PM_INVOCIES = {
  endpoint:(invoiceStatus, key, limit, page)=> `${CLIENT_DATA_BASE_URL}/invoices?invoiceStatus=${invoiceStatus}&key=${key}&limit=${limit}&page=${page}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_DIRECT_DEBIT = {
  endpoint:(invoiceStatus, key, limit, page)=> `${CLIENT_DATA_BASE_URL}/invoices/direct_debit?invoiceStatus=${invoiceStatus}&key=${key}&limit=${limit}&page=${page}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_INVOICE_WITH_STATUS = {
  endpoint:(invoiceStatus, limit, page, key)=> `${CLIENT_DATA_BASE_URL}/invoices/invoice-with-status?invoiceStatus=${invoiceStatus}&limit=${limit}&page=${page}&key=${key}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_BANK_DETAILS = {
  endpoint: id =>
    `${CLIENT_DATA_BASE_URL}/invoices/bank-details?client_id=${id}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const POST_MARK_AS_PAID = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/invoices/mark-paid/${id}`,
  method: METHODS.post,
  successStatusCode: 200
}

export const SIGN_FORM_A = {
  endpoint: () => `${process.env.REACT_APP_AIRGP_BACKEND_URL}/api/v1/pensions/mark-signed-manually`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_MARK_AS_PM_PAYMENT_RECEIVED = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/invoices/mark-pm-received/${id}`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_MARK_AS_PM_PAYMENT_RECEIVED_PRE_PAID_CLIENT = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/invoices/mark-pm-received-prepaid-client/${id}`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_MAKE_EARLY_PAYMENT_TO_CLIENT = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/invoices/make-early-payment/${id}`,
  method: METHODS.post,
  successStatusCode: 200
}

///////////////////////////////////////////////////////////GP-invitation/////////////////////////////////////////////////////////////////////////////

export const GET_PERFORMER_LIST = {
  endpoint: (
    prefix,
    performer_role,
    status,
    probationary_period,
    page,
    limit
  ) =>
    `${CLIENT_DATA_BASE_URL}/gp-invitation?prefix=${
      prefix ? prefix : ''
    }&performer_role=${performer_role ? performer_role : ''}&status=${
      status ? status : ''
    }&probationary_period=${
      probationary_period ? probationary_period : ''
    }&page=${page ? page : 1}&limit=${limit ? limit : 30}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_TEMPLATES = {
  endpoint: `${CLIENT_DATA_BASE_URL}/gp-invitation/template`,
  method: METHODS.get,
  successStatusCode: 200
}

export const POST_SEND_INVITATION = {
  endpoint: `${CLIENT_DATA_BASE_URL}/gp-invitation`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_SEND_PM_INVITATION = {
  endpoint: `${CLIENT_DATA_BASE_URL}/pm-invitation`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_UPDATE_BONUS_PERCENTAGE = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/user/${id}/client`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_SUSPEND_CLIENT = {
  endpoint: (id, suspend) =>
    `${CLIENT_DATA_BASE_URL}/user/${id}/suspend?suspend=${suspend}`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_SUSPEND_PM_FOR_ORG = {
  endpoint: (pm_id, org_id, suspend) =>
    `${CLIENT_DATA_BASE_URL}/user/${pm_id}/pm-suspend?orgId=${org_id}&suspend=${suspend}`,
  method: METHODS.post,
  successStatusCode: 200
}

export const POST_CLIENT_NOT_APPEAR = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/user/${id}/client-not-appear`,
  method: METHODS.post,
  successStatusCode: 200
}

export const GET_ORGANISATION_STAFF_LIST = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/organisation/staff-list?orgId=${id}`,
  method: METHODS.get,
  successStatusCode: 200
}

export const GET_ORGANISATION_PM_LIST = {
  endpoint: id => `${CLIENT_DATA_BASE_URL}/organisation/pm-list?orgId=${id}`,
  method: METHODS.get,
  successStatusCode: 200
}

// Allocate Organisation endpoints
export const GET_ORGANISATION_LIST = {
  endpoint: (prefix, page, limit, pmId) => `${CLIENT_DATA_BASE_URL}/user/${pmId}/organisationList?page=${page ? page : 1
  }&limit=${limit ? limit : 20}&prefix=${encodeURIComponent(prefix) ? encodeURIComponent(prefix) : ''}`,
  method: METHODS.get,
  successStatusCode: 200
} 

export const GET_IT_SYSTEMS = {
  endpoint: `${CLIENT_DATA_BASE_URL}/user/it-systems`,
  method: METHODS.get,
  successStatusCode: 200
}

export const POST_ALLOCATE_PM = {
  endpoint: `${CLIENT_DATA_BASE_URL}/user/allocatePM`,
  method: METHODS.post,
  successStatusCode: 200
}
