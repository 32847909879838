import React from 'react'
import { Button, Form, Modal, ModalBody, ModalHeader, Table } from 'reactstrap'

const BankDetailsModal = ({ open, toggle, bankDetails }) => {
  return (
    <Modal isOpen={open} id='event-modal' centered>
        <ModalHeader
          toggle={toggle}
          className='p-3 bg-airgp modal-title'
        >
                  <div className="modal-title text-white h5">Bank detail</div>
          
        </ModalHeader>
      <ModalBody>
        <Form
          className={'needs-validation view-event'}
          name='event-form'
          id='form-event'
          onSubmit={e => e.preventDefault()}
        >
          <div className='event-details'>
            <div className='table-responsive'>
              <Table className='table-borderless mb-'>
                <tbody>
                  <tr>
                    <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                      <div className='flex-grow-1 d-flex align-items-center'>
                        <div className='flex-shrink-0 me-3'>
                          <i className='ri-home-4-line text-muted fs-16'></i>{' '}
                        </div>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold  text-uppercase'
                            id='event-start-date-tag'
                          >
                            Bank Name :
                          </h6>
                        </div>
                      </div>
                    </th>
                    <td className='p-1'>
                      <div className='flex-grow-1'>
                        <h6
                          className='d-block fw-semibold '
                          id='event-start-date-tag'
                        >
                          {bankDetails?.bank_name || 'NA'}
                        </h6>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                      <div className='flex-grow-1 d-flex align-items-center'>
                        <div className='flex-shrink-0 me-3'>
                          <i className='ri-centos-line text-muted fs-16'></i>{' '}
                        </div>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold  text-uppercase'
                            id='event-start-date-tag'
                          >
                            Name On Bank :
                          </h6>
                        </div>
                      </div>
                    </th>
                    <td className='p-1'>
                      <div className='flex-grow-1'>
                        <h6
                          className='d-block fw-semibold '
                          id='event-start-date-tag'
                        >
                          {bankDetails?.name_on_bank || 'NA'}
                        </h6>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                      <div className='flex-grow-1 d-flex align-items-center'>
                        <div className='flex-shrink-0 me-3'>
                          <i className='ri-map-pin-4-line text-muted fs-16'></i>{' '}
                        </div>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold  text-uppercase'
                            id='event-start-date-tag'
                          >
                            Account Number :
                          </h6>
                        </div>
                      </div>
                    </th>
                    <td className='p-1'>
                      <div className='flex-grow-1'>
                        <h6
                          className='d-block fw-semibold '
                          id='event-start-date-tag'
                        >
                          {bankDetails?.account_number || 'NA'}
                        </h6>
                      </div>
                    </td>
                  </tr>

                  <tr>
                    <th className='ps-0 fw-semibold mb-0 p-1' scope='row'>
                      <div className='flex-grow-1 d-flex align-items-center'>
                        <div className='flex-shrink-0 me-3'>
                          <i className='ri-pushpin-2-line text-muted fs-16'></i>{' '}
                        </div>
                        <div className='flex-grow-1'>
                          <h6
                            className='d-block fw-semibold  text-uppercase'
                            id='event-start-date-tag'
                          >
                            Sort Code :
                          </h6>
                        </div>
                      </div>
                    </th>
                    <td className='p-1'>
                      <div className='flex-grow-1'>
                        <h6
                          className='d-block fw-semibold '
                          id='event-start-date-tag'
                        >
                          {bankDetails?.sortcode || 'NA'}
                        </h6>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default BankDetailsModal
