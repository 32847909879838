import PropTypes from 'prop-types'
import React from 'react'
import { Modal, ModalBody,ModalHeader,Input } from 'reactstrap'

const SuccessConfirmationModal = ({
  show,
  onDeleteClick,
  onCloseClick,
  deleteMsg,
  enableDatePicker = false,
  dateTitle = false,
  title,
  dateChange,
  dateValue,
  btnName
}) => {
  return (
    <Modal isOpen={show} toggle={onCloseClick} centered={true}>
              <ModalHeader
          toggle={onCloseClick}
          className='p-3 bg-airgp modal-title'
        >
                  <div className="modal-title text-white h5">Confirm</div>
          
        </ModalHeader>
      <ModalBody className='py-3 px-5'>
        <div className='mt-2 text-center'>

          <div className='mt-4 pt-2 fs-15 mx-4 mx-sm-5'>
            {/* <h4>Are you sure ?</h4> */}
            <p className='h6 mx-4 mb-0'>
              {deleteMsg || 'Are you sure you want to remove this record ?'}
            </p>
          </div>
          {enableDatePicker ? 
          <div className='mt-4 pt-2 fs-15 mx-4 mx-sm-5'>
            {
              dateTitle ?
                <div>
                  <p className='text-muted mx-4 mb-0'>{title}</p>
                </div>
              :
              <></>
            }
            <Input
                name='dateOfpick'
                onChange={dateChange}
                value={dateValue}
                type='date'
                className='form-control'
                id='date-of-pick'
              />
          </div>: ''}
        </div>
        <div className='d-flex gap-2 justify-content-center mt-4 mb-2'>

          <button
            type='button'
            className='btn btn-primary'
            onClick={onDeleteClick}
          >
            {btnName || 'Yes, Do it!'}
          </button>
          <button
            type='button'
            className='btn w-sm btn-light'
            data-bs-dismiss='modal'
            onClick={onCloseClick}
          >
            Close
          </button>
        </div>
      </ModalBody>
    </Modal>
  )
}

SuccessConfirmationModal.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  dateChange: PropTypes.func,
  show: PropTypes.any
}

export default SuccessConfirmationModal
