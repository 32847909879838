import { headerContent } from '.'
import {
  POST_FORGOT_PASSWORD_SUPER_ADMIN,
  POST_LOGIN_SUPER_ADMIN,
  POST_RESET_PASSWORD_SUPER_ADMIN
} from './endpoints'
var token = localStorage.getItem('token')

export const headerContentWithAuthorization = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${token}`
}

export const customHeaders = token => {
  let copiedHeaderContentWithAuthorization = {
    ...headerContentWithAuthorization
  }
  copiedHeaderContentWithAuthorization['Authorization'] = `Bearer ${token}`
  return copiedHeaderContentWithAuthorization
}

export const authServices = {
  // Login super admin
  postLoginSuperAdmin: data =>
    fetch(POST_LOGIN_SUPER_ADMIN.endpoint, {
      method: POST_LOGIN_SUPER_ADMIN.method,
      headers: headerContent,
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // forgot password super admin
  postForgotPasswordSuperAdmin: data =>
    fetch(POST_FORGOT_PASSWORD_SUPER_ADMIN.endpoint, {
      method: POST_FORGOT_PASSWORD_SUPER_ADMIN.method,
      headers: headerContent,
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // forgot password super admin
  postResetPasswordSuperAdmin: (data, id, token) =>
    fetch(POST_RESET_PASSWORD_SUPER_ADMIN.endpoint(id, token), {
      method: POST_RESET_PASSWORD_SUPER_ADMIN.method,
      headers: headerContent,
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err))
}
