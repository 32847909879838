import React, { useState, useEffect, useCallback } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import UiContent from '../../../Components/Common/UiContent'
import { Link } from 'react-router-dom'
import { clientServices } from '../../../services/clientServices'
import { useHistory } from 'react-router-dom'
import PaginationV2 from '../../../Components/Common/PaginationV2'
import moment from 'moment/moment'
import ViewClientInfoModal from '../../../Components/Common/ViewClientInfoModal'
import userphoto from '../../../assets/images/user.png'
import SpinnerComponent from '../../../Components/Common/Spinner'

const UserListTable = () => {
  document.title = 'Client List | airGP'
  const [usersList, setUsersList] = useState([])
  const [allRegistrationStatuses, setAllRegistrationStatuses] = useState([])
  const [page, setPage] = useState(1)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [viewClientModal, setViewClientModal] = useState(false)
  const [selectedClient, setSelectedClient] = useState()
  const history = useHistory()
  const token = localStorage.getItem('token')
  const [userProfile, setUserProfile] = useState([]);
  const [ isLoading, setIsLoading] = useState(false)

  let id = new URLSearchParams(document.location.search).get('id')

  useEffect(() => {
    fetchUsersListSuperAdmin()
  }, [page])

  useEffect(() => {
    if(id){
      fetchSearchFromUserList({target: { value : id }})
    }
  },[id])

  const fetchUsersListSuperAdmin = () => {
    setIsLoading(true)
    clientServices
      .getUsersListSuperAdmin('', page, 20, token)
      .then(resp => {
        clientServices.getUsersListProfile('', page, 20, token)
        .then(resp => {
          if (resp.status){
            setUserProfile(resp?.data)
          }
        })
        if (resp.status) {
          setUsersList(resp?.data)
          setAllRegistrationStatuses(resp?.allStatuses?.map((item)=> { return {label:item?.registration_status_name, value: item?.registration_status_name}}))
          setTotalPageCount(resp?.totalPages || 1)
        }else{
          if(resp?.message === "Unauthorised"){
            localStorage.clear();
            history.push('/login');
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchSearchFromUserList = e => {
    const { value } = e.target
    clientServices
      .getUsersListSuperAdmin(value, '', '', token)
      .then(resp => {
        if (resp.status) {
          setUsersList(resp.data)
        }
      })
      .catch(err => console.log(err))
  }

  const debouncingSearchFunction = func => {
    let timer
    return function (...args) {
      const context = this
      console.log(context)
      clearTimeout(timer)
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 300)
    }
  }

  const handleOnKeyUpSearchBox = useCallback(
    debouncingSearchFunction(fetchSearchFromUserList),
    []
  )

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
      {isLoading && <SpinnerComponent />}
        <Container fluid>
          <Row>
            <Col>
                <Card>
                  <CardHeader className='card-title text-light mb-0'>
                   
                  <div className={'search-box float-end'}>
                    <input
                      onKeyUp={handleOnKeyUpSearchBox}
                      id='search-bar-0'
                      type='text'
                      className='form-control search'
                      placeholder={`Search...`}
                    />
                    <i className='ri-search-line search-icon'></i>
                  </div>
                  <p className='pt-2'> Client List</p>
                  </CardHeader>
                  <CardBody>
                    <div className='live-preview'>
                      <div className='table-responsive table-card'>
                        <Table className='align-middle table-hover table-sm  table-nowrap mb-0'>
                          <thead className='table-light'>
                            <tr>
                              <th
                                scope='col'
                                style={{ width: '46px' }}
                              ></th>
                              <th scope='col'>ID</th>
                              <th scope='col'>Name</th>
                              <th scope='col'>Email</th>
                              <th scope='col'>Gender</th>
                              <th scope='col'>Date Of Birth</th>
                              <th scope='col'>Registered</th>
                              <th scope='col'>GMC Number</th>
                              <th scope='col'>Performers List Status</th>
                              <th scope='col'>Regulator Status</th>
                              <th scope='col'>Last Updated</th>
                              <th scope='col'>Last Logged</th>
                              <th scope='col'>Status</th>
                              <th scope='col'>Role</th>
                              <th scope='col'>Bonus</th>
                              <th scope='col' style={{ width: '50px' }}>
                                Action
                              </th>
                            </tr>
                          </thead>
                          {usersList?.length !== 0 ? (
                            usersList?.map((_, index) => {
                              return (
                                <tbody key={`admin-${index}`}>
                                  <tr>
                                    <td>
                                      <div className='form-check'>
                                        <Input
                                          className='form-check-input'
                                          type='checkbox'
                                          defaultValue=''
                                          id='cardtableCheck01'
                                        />
                                        <Label
                                          className='form-check-label'
                                          htmlFor='cardtableCheck01'
                                        ></Label>
                                      </div>
                                    </td>
                                    <td>
                                      <Link to='#' className='fw-medium'>
                                        {`#${_?.uuid?.slice(0, 5)}` || 'NA'}
                                      </Link>
                                    </td>
                                    <td>
                                    {userProfile[index]?.profile ? (
                                      <img
                                        key={index}
                                        src={`data:image/png;base64,${userProfile[index]?.profile}`}
                                        className="me-3 rounded-circle avatar-xs"
                                        alt={`user_profile_${index}`}
                                      />
                                      ) : (
                                      <img
                                        src={userphoto}
                                        className="me-3 rounded-circle avatar-xs"
                                        alt={`user_photo_placeholder_${index}`}
                                      />
                                      )}
                                      {_?.title} {_?.firstName} {_?.lastName}
                                    </td>
                                    <td>
                                      {_?.email || 'NA'}</td>
                                    <td>
                                      {_?.User_profile?.gender || '-'}
                                    </td>
                                    <td>
                                      {_?.User_profile?.dob ? moment(_?.User_profile?.dob).format(
                                        'l'
                                      ) || '-' : ''}
                                      <p className='text-muted small mb-0'>{_?.User_profile?.dob ? moment(_?.User_profile?.dob).fromNow(true) + ' old' : ''} </p>
                                    </td>
                                    <td>
                                      {moment(_?.User_profile?.createdAt).format(
                                        'll'
                                      ) || '-'}

                                      <p className='text-muted small mb-0'>{moment(_?.User_profile?.createdAt).fromNow()}</p>
                                    </td>
                                    <td>
                                      {_?.lrmp?.["GMC Ref No"] || '-'}
                                    </td>
                                    <td>
                                      {_?.performerList?.Status === 'Included' ? (
                                        <span className='badge text-bg-success bg-success'>
                                          {_?.performerList?.Status || '-'}
                                        </span>
                                      ) : (
                                        <span className='badge text-bg-danger bg-danger'>
                                          {_?.performerList?.Status || 'NA'}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      {_?.lrmp?.registration_status_id === 5 ? (
                                        <span className='badge text-bg-success bg-success'>
                                          {_?.lrmp?.registrationStatus?.registration_status_name || '-'}
                                        </span>
                                      ) : (
                                        <span className='badge text-bg-danger bg-danger'>
                                          {_?.lrmp?.registrationStatus?.registration_status_name}
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                        {_?.lrmp?.registration_status_last_updated ? (
                                            <>
                                                {moment(_?.lrmp?.registration_status_last_updated).format("ll")}
                                                <p className="text-muted small mb-0">
                                                    {moment(_?.lrmp?.registration_status_last_updated).fromNow()}
                                                </p>
                                            </>
                                        ) : (
                                            "-"
                                      )}
                                    </td>
                                    <td>
                                        {_?.lastLoggedIn ? (
                                            <>
                                                {moment(_?.lastLoggedIn).format("ll")}
                                                <p className="text-muted small mb-0">
                                                    {moment(_?.lastLoggedIn).fromNow()}
                                                </p>
                                            </>
                                        ) : (
                                            "-"
                                        )}
                                    </td>
                                    <td>
                                      {_?.active === 1 ? (
                                        <span className='badge text-bg-success bg-success'>
                                          Active
                                        </span>
                                      ) : (
                                        <span className='badge text-bg-danger bg-danger'>
                                          Suspended
                                        </span>
                                      )}
                                    </td>
                                    <td>
                                      <span className='badge bg-info'>
                                        {_?.user_type?.name || 'NA'}
                                      </span>
                                    </td>
                                    <td>
                                    {_?.User_profile?.bonus_fee_percentage * 100  || '0'}%

                                    </td>
                                    <td>
                                      <UncontrolledDropdown className='align-self-start message-box-drop'>
                                        <DropdownToggle
                                          className='btn-soft-primary btn-sm dropdown'
                                          tag='button'
                                        >
                                          <i className='ri-more-fill'></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            onClick={() => {
                                              setSelectedClient(_)
                                              setViewClientModal(true)
                                            }}
                                          >
                                            <i className='ri-user-line me-2 text-muted align-bottom'></i>
                                            View Info
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() =>
                                              history.push(
                                                `/list/${_?.uuid}/session`
                                              )
                                            }
                                          >
                                            <i className='ri-instance-line me-2 text-muted align-bottom'></i>
                                            Sessions
                                          </DropdownItem>
                                          {/* <DropdownItem
                                            onClick={() =>
                                              history.push(
                                                `/list/${_?.uuid}/invoice`
                                              )
                                            }
                                          >
                                            <i className='ri-file-list-3-fill me-2 text-muted align-bottom'></i>
                                            Invoices
                                          </DropdownItem> */}
                                          <DropdownItem
                                            onClick={() =>
                                              history.push(
                                                `/list/${_?.uuid}/document`
                                              )
                                            }
                                          >
                                            <i className='ri-file-copy-line me-2 text-muted align-bottom'></i>
                                            Documents
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                          })
                          ) : (
                            <tbody>
                              <tr>
                                <td className='fw-medium'></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>No User found</td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                      </div>
                    </div>
                  </CardBody>


                <PaginationV2
                  totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                  previousDisabled={page === 1}
                  nextDisabled={totalPageCount === page ? true : false}
                  previousPageCall={() => setPage(page - 1)}
                  currentPage={page}
                  nextPageCalls={() => setPage(page + 1)}
                  onPageInputChange={({ target: { value } }) => {
                    var regex = new RegExp(`^[1-${totalPageCount}]$`)
                    if (value <= totalPageCount && value !== 0) {
                      setPage(value)
                    }
                  }}
                />

                <ViewClientInfoModal
                  open={viewClientModal}
                  handleClose={() => setViewClientModal(!viewClientModal)}
                  allRegistrationStatuses={allRegistrationStatuses}
                  toggle={() => setViewClientModal(!viewClientModal)}
                  selectedClient={selectedClient}
                  handleSuccess={() => fetchUsersListSuperAdmin()}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default UserListTable
