import React, { useState } from 'react'
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Label,
  Col,
  Input
} from 'reactstrap'
import Select from 'react-select'
import Flatpickr from 'react-flatpickr'
import { useEffect } from 'react'
// import { scheduleServices } from '../../services/scheduleServices'
import { PM_SESSION_CREATE_VALUES } from '../../utils/constants'
import moment from 'moment'
import { toast } from 'react-toastify'
import { sessionServices } from '../../services/sessionSrvices'

const EditDraftSessionModal = ({
  open,
  handleClose,
  toggle,
  selectedSession
}) => {
  const [pmSessionValues, setPmSessionValues] = useState(
    PM_SESSION_CREATE_VALUES
  )
  const [userRoleType, setUserRoleType] = useState([])
  const [sessionJobType, setSessionJobType] = useState([])
  const [features, setFeatures] = useState([])

  const token = localStorage.getItem('token')

  useEffect(() => {
    fetchUserTypeRole()
    fetchSessionJobType(token)
    fetchFeaturesType(token)
  }, [])

  useEffect(() => {
    if (selectedSession) {
      const copiedPmObj = { ...pmSessionValues }
      copiedPmObj.startTime = moment(selectedSession?.start_time).format(
        'HH:mm'
      )
      copiedPmObj.endTime = moment(selectedSession?.end_time).format('HH:mm')
      copiedPmObj.sessionJobType = selectedSession?.session_type?.uuid
      copiedPmObj.hourlyRate = selectedSession?.hourly_rate
        ? selectedSession.hourly_rate
        : ''
      copiedPmObj.breakDuration = selectedSession?.unpaid_break_duration
      copiedPmObj.appointmentLength = selectedSession?.appointment_length
      copiedPmObj.about = selectedSession?.session_description

      copiedPmObj.defaultJobType = selectedSession?.session_type?.type
      copiedPmObj.defaultJobRole = selectedSession?.user_type?.name
      copiedPmObj.sessionJobRole = selectedSession?.user_type?.uuid

      let filterAUuid = selectedSession?.features?.map(__ => {
        return __.uuid
      })

      copiedPmObj.sessionFeatures = filterAUuid
      setPmSessionValues(copiedPmObj)

      if (features.length !== 0) {
        const copiedFeatures = [...features]
        copiedFeatures.map(_ => {
          _.checked = false
        })
        setFeatures(copiedFeatures)

        selectedSession?.features?.map(_ => {
          let index = copiedFeatures?.findIndex(obj => _.uuid === obj.uuid)
          copiedFeatures[index].checked = true
        })
        setFeatures(copiedFeatures)
      }
    }
  }, [selectedSession])

  const fetchUserTypeRole = () => {
    sessionServices
      .getSessionJobTypes(token)
      .then(resp => {
        if (resp.success) {
          const mutedArray = []
          const filteredUserType = resp?.user_types?.filter(
            item =>
              item.name !== 'Practice Manager' && item.name !== 'Super Admin'
          )
          filteredUserType.map(_ => {
            mutedArray.push({ value: _.uuid, label: _.name })
          })
          setUserRoleType(mutedArray)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchFeaturesType = token => {
    sessionServices
      .getSessionFeaturesTypes(token)
      .then(resp => {
        let featuresT = resp?.features.map(_ => ({
          ..._,
          checked: false
        }))
        setFeatures(featuresT)
      })
      .catch(err => console.log(err))
  }

  const handleFeaturesCheck = (event, fet, index) => {
    let featuresCloneArray = [...features]
    featuresCloneArray[index].checked = event.target.checked
    setFeatures(featuresCloneArray)

    let filterAUuid = features
      .filter(_ => _.checked === true)
      .map(__ => {
        return __.uuid
      })

    let copyiedObj = { ...pmSessionValues }
    copyiedObj.sessionFeatures = filterAUuid
    setPmSessionValues(copyiedObj)
  }

  const fetchSessionJobType = token => {
    sessionServices
      .getSessionJobTypesData(token)
      .then(resp => {
        const mutedArray = []
        resp.data?.map(_ => {
          mutedArray.push({ value: _.uuid, label: _.type })
        })
        setSessionJobType(mutedArray)
      })
      .catch(err => console.log(err))
  }

  const handlePmSessionOnChanges = ({ target: { value, name } }) => {
    const copiedPmSessionData = { ...pmSessionValues }
    copiedPmSessionData[name] = value
    setPmSessionValues(copiedPmSessionData)
  }

  const fetchUpdateSessionAPI = () => {
    const data = {
      org_uuid: selectedSession?.organisation?.uuid,
      start_time: `${moment(selectedSession?.start_time).format(
        `YYYY-MM-DD`
      )} ${pmSessionValues.startTime}:00`,
      end_time: `${moment(selectedSession?.end_time).format(`YYYY-MM-DD`)} ${
        pmSessionValues.endTime
      }:00`,
      break_duration: pmSessionValues.breakDuration,
      hourly_rate: pmSessionValues.hourlyRate,
      fee: pmSessionValues.fee,
      bio: pmSessionValues.about,
      session_type_uuid: pmSessionValues.sessionJobType,
      user_type_uuid: pmSessionValues.sessionJobRole,
      features: pmSessionValues.sessionFeatures,
      appointment_length: pmSessionValues.appointmentLength
    }

    // if (
    //   pmSessionValues?.startTime?.length !== 0 &&
    //   pmSessionValues.endTime.length !== 0 &&
    //   pmSessionValues.breakDuration.length !== 0 &&
    //   pmSessionValues.hourlyRate.length !== 0 &&
    //   pmSessionValues.fee.length !== 0 &&
    //   pmSessionValues.about.length !== 0 &&
    //   pmSessionValues.sessionJobType.length !== 0 &&
    //   pmSessionValues.sessionJobRole.length !== 0 &&
    //   pmSessionValues.sessionFeatures.length !== 0 &&
    //   pmSessionValues.appointmentLength.length !== 0
    // ) {
    //   sessionServices
    //     .patchEditPmSession(data, selectedSession?.uuid, token)
    //     .then(resp => {
    //       console.log(resp)
    //       if (resp.success) {
    //         toast.success(resp.message || 'Something went wrong')
    //       } else {
    //         toast.error(resp.message || 'Something went wrong')
    //       }
    //     })
    //     .catch(err => console.log(err))
    // } else {
    //   toast.error('Please fill all fields!')
    // }

    sessionServices
      .patchEditPmSession(data, selectedSession?.uuid, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message || 'Something went wrong')
          handleClose()
        } else {
          toast.error(resp.message || 'Something went wrong')
          handleClose()
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      scrollable={true}
      id='exampleModalScrollable'
    >
      <ModalHeader
        className='p-3 bg-soft-info modal-title'
        id='exampleModalScrollableTitle'
        toggle={toggle}
      >
        Edit Session
      </ModalHeader>
      <ModalBody>
        <div className='d-flex justify-content-between'>
          <div className='mb-3 w-100 margin-right'>
            <Label
              htmlFor='choices-multiple-groups'
              className='form-label mb-0'
            >
              Select Job Role
            </Label>
            <Select
              isMulti={false}
              options={userRoleType || []}
              maxMenuHeight={210}
              isSearchable={false}
              closeMenuOnSelect={true}
              onChange={event => {
                let copyiedObj = { ...pmSessionValues }
                copyiedObj.sessionJobRole = event.value
                copyiedObj.defaultJobRole = event.label
                setPmSessionValues(copyiedObj)
              }}
              value={{ label: pmSessionValues.defaultJobRole }}
            />
          </div>
          <div className='mb-3 w-100 ml-2'>
            <Label
              htmlFor='choices-multiple-groups'
              className='form-label mb-0'
            >
              Select Job Type
            </Label>
            <Select
              isMulti={false}
              options={sessionJobType || []}
              maxMenuHeight={210}
              isSearchable={false}
              closeMenuOnSelect={true}
              onChange={event => {
                let copyiedObj = { ...pmSessionValues }
                copyiedObj.sessionJobType = event.value
                copyiedObj.defaultJobType = event.label
                setPmSessionValues(copyiedObj)
              }}
              value={{ label: pmSessionValues.defaultJobType }}
            />
          </div>
        </div>
        <div className='d-flex justify-content-between'>
          <div className='mb-3 add-session-modal margin-right'>
            <Label className='form-label mb-0'>Start Time</Label>
            <Flatpickr
              name='startTime'
              className='form-control w-100'
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                time_24hr: true
              }}
              placeholder='Please select start time'
              onChange={event => {
                let copyiedObj = { ...pmSessionValues }
                copyiedObj.startTime = moment(event[0]).format('HH:mm')
                setPmSessionValues(copyiedObj)
              }}
              value={pmSessionValues.startTime || ''}
            />
          </div>
          <div className='mb-3'>
            <Label className='form-label mb-0'>End Time</Label>
            <Flatpickr
              name='endTime'
              className='form-control w-100'
              options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                time_24hr: true
              }}
              onChange={event => {
                let copyiedObj = { ...pmSessionValues }
                copyiedObj.endTime = moment(event[0]).format('HH:mm')
                setPmSessionValues(copyiedObj)
              }}
              value={pmSessionValues.endTime || ''}
              placeholder='Please select end time'
            />
          </div>
        </div>
        <div className='mb-3'>
          <Label className='form-label mb-0'>Appointment length</Label>
          <Col lg={12}>
            <div className='input-group'>
              <Input
                type='number'
                className={'form-control d-block'}
                name='appointmentLength'
                id='event-location'
                onChange={handlePmSessionOnChanges}
                placeholder='Enter your appointment length'
                value={pmSessionValues.appointmentLength || ''}
              />
            </div>
          </Col>
        </div>
        <div className='mb-3'>
          <Label className='form-label mb-0'>About</Label>
          <Col lg={12}>
            <div className='input-group'>
              <Input
                type='text'
                className={'form-control d-block'}
                name='about'
                onChange={handlePmSessionOnChanges}
                id='event-location'
                placeholder='Enter about session'
                value={pmSessionValues.about || ''}
              />
            </div>
          </Col>
        </div>
        <div className='mb-3'>
          <Label className='form-label mb-0'>Hourly Rate</Label>
          <Col lg={12}>
            <div className='input-group'>
              <span className='input-group-text' id='basic-addon1'>
                £
              </span>
              <Input
                type='number'
                className={'form-control d-block'}
                name='hourlyRate'
                onChange={handlePmSessionOnChanges}
                id='event-location'
                placeholder='Enter Hourly Rate (£)'
                value={pmSessionValues.hourlyRate || ''}
              />
            </div>
          </Col>
        </div>
        <div className='mb-3'>
          <Label className='form-label mb-0'>Break Duration</Label>
          <Col lg={12}>
            <div className='input-group'>
              <span className='input-group-text' id='basic-addon1'>
                Break
              </span>
              <Input
                type='number'
                className={'form-control d-block'}
                name='breakDuration'
                onChange={handlePmSessionOnChanges}
                id='event-location'
                placeholder='Enter Break Duration in minutes'
                value={pmSessionValues.breakDuration || ''}
              />
            </div>
          </Col>
        </div>
        <div className='mb-3'>
          <Label className='form-label mb-2'>Feature Types</Label>
          <Col lg={12}>
            {features
              ? features?.map((_, idx) => (
                  <div key={idx} className='form-check'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      name='sessionFeatures'
                      checked={_?.checked}
                      value={_?.checked}
                      onChange={e => handleFeaturesCheck(e, _, idx)}
                    />
                    <label className='form-check-label'>
                      {_?.name || 'NA'}
                    </label>
                  </div>
                ))
              : ''}
          </Col>
        </div>
      </ModalBody>

      <div className='modal-footer'>
        <Button color='light' onClick={handleClose}>
          Close
        </Button>
        <Button color='primary' onClick={fetchUpdateSessionAPI}>
          Edit
        </Button>
      </div>
    </Modal>
  )
}

export default EditDraftSessionModal
