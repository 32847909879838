import React, { useState, useEffect, useCallback } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  CardHeader
} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import UiContent from '../../Components/Common/UiContent'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { documentServices } from '../../services/documentServices'
import PaginationV2 from '../../Components/Common/PaginationV2'
import SpinnerComponent from '../../Components/Common/Spinner'
import moment from 'moment/moment'
import userphoto from '../../assets/images/user.png'

const DocumentListTable = () => {
  document.title = 'Document List | airGP'
  const [page, setPage] = useState(1)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [userDocumentList, setUserDocumentList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory()
  const token = localStorage.getItem('token')

  useEffect(() => {
    fetchUserListAndDocument()
  }, [])

  const fetchUserListAndDocument = () => {
    setIsLoading(true)
    documentServices
      .getDocumentListSuperAdmin(20, page, '', token)
      .then(resp => {
        if (resp.success) {
          setUserDocumentList(resp?.data || [])
          setTotalPageCount(resp.totalPages || 1)
        }else{
          if(resp?.message === "Unauthorised"){
            localStorage.clear();
            history.push('/login');
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchSearchDocumentList = e => {
    const { value } = e.target
    documentServices
      .getDocumentListSuperAdmin(20, page, value, token)
      .then(resp => {
        if (resp.status) {
          setUserDocumentList(resp?.data || [])
        }
      })
      .catch(err => console.log(err))
  }

  const debouncingSearchFunction = func => {
    let timer
    return function (...args) {
      const context = this
      console.log(context)
      clearTimeout(timer)
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 300)
    }
  }

  const handleOnKeyUpSearchBox = useCallback(
    debouncingSearchFunction(fetchSearchDocumentList),
    []
  )
  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
      {isLoading && <SpinnerComponent />}
        <Container fluid>
          <Row>
            <Col>
              <Card>
                <CardHeader className='card-title text-light mb-0'>
                  
                  <div className={'search-box float-end'}>
                    <input
                      onKeyUp={handleOnKeyUpSearchBox}
                      id='search-bar-0'
                      type='text'
                      className='form-control search'
                      placeholder={`Search...`}
                    />
                    <i className='ri-search-line search-icon'></i>
                    {/* {console.log(userDocumentList)} */}
                  </div>
                  <p className='pt-2'>Documents awaiting approval</p>
                </CardHeader>
                <CardBody>

                  <div className='table-responsive table-card'>
                    <Table className='align-middle table-nowrap table-sm mb-0'>
                      <thead className='table-light'>
                        <tr>
                          <th scope='col'>ID</th>
                          <th scope='col'>Name</th>
                          <th scope='col'>Date Of Birth</th>
                          <th scope='col'>Email</th>
                          <th scope='col'>Phone No</th>
                          <th scope='col'>Registered</th>
                          <th scope='col'>GMC Number</th>
                          <th scope='col'>Regulator Status</th>
                          <th scope='col'>Last Logged</th>
                          <th scope='col'>Status</th>
                          <th scope='col'>Role</th>
                          <th scope='col'>Bonus</th>
                          <th scope='col'>Action</th>
                        </tr>
                      </thead>
                      {userDocumentList.length !== 0 ? (
                        userDocumentList.map((_, index) => {
                          return (
                            <tbody key={`admin-${index}`}>
                              <tr>
                                <td>
                                  <Link to='#' className='fw-medium'>
                                    {`#${index + 1}` || 'NA'}
                                  </Link>
                                </td>
                                <td>
                                {_?.profile ? (
                                      <img
                                        key={index}
                                        src={`data:image/png;base64,${_?.profile }`}
                                        className="me-3 rounded-circle avatar-xs"
                                        alt={`_${index}`}
                                      />
                                      ) : (
                                      <img
                                        src={userphoto}
                                        className="me-3 rounded-circle avatar-xs"
                                        alt={`user_photo_placeholder_${index}`}
                                      />
                                      )}
                                    {_?.title} {_?.firstName} {_?.lastName}
                                </td>
                                <td>
                                      {_?.User_profile?.dob ? moment(_?.User_profile?.dob).format(
                                        'l'
                                      ) || '-' : ''}
                                      <p className='text-muted small mb-0'>{_?.User_profile?.dob ? moment(_?.User_profile?.dob).fromNow(true) + ' old' : ''} </p>
                                </td>
                                <td>{_?.email || 'NA'}</td>
                                <td>{_?.phone_number || 'NA'}</td>
                                <td>
                                      {moment(_?.User_profile?.createdAt).format(
                                        'll'
                                      ) || '-'}

                                      <p className='text-muted small mb-0'>{moment(_?.User_profile?.createdAt).fromNow()}</p>
                                </td>
                                <td>
                                      {_?.lrmp?.["GMC Ref No"] || '-'}
                                </td>
                                <td>
                                      {_?.lrmp?.registration_status_id === 5 ? (
                                        <span className='badge text-bg-success bg-success'>
                                          {_?.lrmp?.registrationStatus?.registration_status_name || '-'}
                                        </span>
                                      ) : (
                                        <span className='badge text-bg-danger bg-danger'>
                                          {_?.lrmp?.registrationStatus?.registration_status_name}
                                        </span>
                                      )}
                                </td>
                                <td>
                                        {_?.lastLoggedIn ? (
                                            <>
                                                {moment(_?.lastLoggedIn).format("ll")}
                                                <p className="text-muted small mb-0">
                                                    {moment(_?.lastLoggedIn).fromNow()}
                                                </p>
                                            </>
                                        ) : (
                                            "-"
                                        )}
                                </td>
                                <td>
                                      {_?.active === 1 ? (
                                        <span className='badge text-bg-success bg-success'>
                                          Active
                                        </span>
                                      ) : (
                                        <span className='badge text-bg-danger bg-danger'>
                                          Suspended
                                        </span>
                                      )}
                                </td>
                                <td>
                                      <span className='badge bg-info'>
                                        {_?.user_type?.name || 'NA'}
                                      </span>
                                </td>
                                <td>
                                    {_?.User_profile?.bonus_fee_percentage * 100  || '0'}%

                                </td>
                                <td>
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      className='btn-soft-secondary btn-sm dropdown'
                                      tag='button'
                                    >
                                      <i className='ri-more-fill'></i>
                                    </DropdownToggle>
                                    <DropdownMenu className='dropdown-menu-end'>
                                      <DropdownItem
                                        onClick={() =>
                                          history.push(
                                            `/list/${_?.uuid}/document`
                                          )
                                        }
                                      >
                                        <i className='ri-file-copy-2-line me-2 text-muted align-bottom'></i>
                                        Documents
                                      </DropdownItem>
                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            </tbody>
                          )
                        })
                      ) : (
                        <tbody>
                          <tr>
                            <td className='fw-medium'></td>
                            <td></td>
                            <td></td>
                            <td>No User found</td>
                            <td></td>
                          </tr>
                        </tbody>
                      )}
                    </Table>
                  </div>
                  </CardBody>

                <PaginationV2
                  totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                  previousDisabled={page === 1}
                  nextDisabled={totalPageCount === page ? true : false}
                  previousPageCall={() => setPage(page - 1)}
                  currentPage={page}
                  nextPageCalls={() => setPage(page + 1)}
                  onPageInputChange={({ target: { value } }) => {
                    var regex = new RegExp(`^[1-${totalPageCount}]$`)
                    if (value <= totalPageCount && value !== 0) {
                      setPage(value)
                    }
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default DocumentListTable
