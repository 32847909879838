import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Badge
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import UiContent from '../../../Components/Common/UiContent'
import { Link } from 'react-router-dom'
import { applicationServices } from '../../../services/applicationServices'
import { procedureTypeCodeClass } from '../../../utils/constants'
import { toast } from 'react-toastify'
import PaginationV2 from '../../../Components/Common/PaginationV2'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import SpinnerComponent from '../../../Components/Common/Spinner'

const PMApplications = () => {
  document.title = 'Applied List | airGP'
  const [page, setPage] = useState(1)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [orgStatusList, setOrgStatusList] = useState([])
  const [organisationList, setOrganisationList] = useState([])
  const [isLoading , setIsLoading] = useState(false)
  const token = localStorage.getItem('token')
  const history = useHistory();
  let limit = 20

  useEffect(() => {
    fetchGetPMAppliedOrg()
    fetchOrgStatusList()
  }, [page])

  const fetchGetPMAppliedOrg = () => {
    setIsLoading(true)
    applicationServices
      .getPMAppliedOrganisationsListSuperAdmin(token, page, limit)
      .then(resp => {
        if (resp.success) {
          setOrganisationList(resp.data || [])
          setTotalPageCount(resp.totalPages || 1)
        }else{
          if(resp?.message === 'Unauthorised'){
            localStorage.clear();
            history.push('/login');
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchOrgStatusList = () => {
    applicationServices
      .getOrganisationStatusList(token)
      .then(resp => {
        if (resp.success) {
          setOrgStatusList(resp.data || [])
        }
      })
      .catch(err => console.log(err))
  }

  const handleChangeOrgStatus = (status, data) => {
    applicationServices
      .patchChangeOrganisationStatusList(
        {
          orgProfileId: data.uuid,
          statusId: status.uuid
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          fetchGetPMAppliedOrg()
          toast.success(resp.message)
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }

  return (
    <React.Fragment>
      {/* <UiContent /> */}
      <div className='page-content'>
        {isLoading && <SpinnerComponent /> }
        <Container fluid>
          <Row>
            <Col>

                  <Card>
                    <CardHeader className='card-title  text-light mb-0'>
                      Applied Organisations
                    </CardHeader>

                        <Row>
                          <Col>
                          <CardBody>
                      <div className='live-preview'>
                        <div className='table-responsive vh-100 table-card py-4'>
                          <Table className='align-middle  table-nowrap table  table-hover'>
                              <thead className='text-muted'>
                                <tr>
                                  <th scope='col'>ID</th>
                                  <th scope='col'>Name</th>
                                  <th scope='col'>Email</th>
                                  <th scope='col'>Phone No</th>
                                  <th scope='col'>Organisation Name</th>
                                  <th scope='col'>Organisation Code</th>
                                  <th scope='col'>Status</th>
                                  <th scope='col'>Action</th>
                                </tr>
                              </thead>
                              {organisationList.length !== 0 ? (
                                organisationList.map((_, index) => {
                                  return (
                                    
                                    <tbody key={`admin-${index}`}>
                                      <tr>
                                        <td>
                                          <Link to='#' className='fw-medium'>
                                            {`#${_?.uuid.slice(0, 5)}` || 'NA'}
                                          </Link>
                                        </td>
                                        <td>
                                          {_?.users?.firstName}{' '}
                                          {_?.users?.lastName}
                                        </td>
                                        <td>{_?.users?.email || 'NA'}</td>
                                        <td>
                                          {_?.users?.phone_number || 'NA'}
                                        </td>
                                        <td>{_?.organisations.name || 'NA'}</td>
                                        <td>{_?.organisations.organisation_code || 'NA'}</td>
                                        <td>
                                          <Badge
                                            color={procedureTypeCodeClass(
                                              _.Organisation_profile_status_intermediates.slice(
                                                -1
                                              )[0].org_profile_statuses.name
                                                ? _.Organisation_profile_status_intermediates.slice(
                                                    -1
                                                  )[0].org_profile_statuses.name
                                                : 'NA'
                                            )}
                                          >
                                            {_.Organisation_profile_status_intermediates.slice(
                                              -1
                                            )[0].org_profile_statuses.name ||
                                              'NA'}
                                          </Badge>
                                        </td>
                                        <td>
                                        <UncontrolledDropdown>
                                          <DropdownToggle
                                              href='#'
                                              className='btn-soft-primary btn-sm dropdown'
                                              tag='button'
                                          >
                                              <i className='ri-more-fill'></i>
                                            </DropdownToggle>
                                            <DropdownMenu className='dropdown-menu-end'>
                                              {orgStatusList?.map(
                                                (status, idx) => {
                                                  return (
                                                    <DropdownItem
                                                      key={'status' + idx}
                                                      onClick={() =>
                                                        handleChangeOrgStatus(
                                                          status,
                                                          _
                                                        )
                                                      }
                                                    >
                                                      {status?.name || 'NA'}
                                                    </DropdownItem>
                                                  )
                                                }
                                              )}
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </td>
                                      </tr>
                                    </tbody>
                                  )
                                })
                              ) : (
                                <tbody>
                                  <tr>
                                    <td className='fw-medium'></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>No User found</td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                </tbody>
                              )}
                            </Table>
                          </div>
                        </div>



                <PaginationV2
                  totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                  previousDisabled={page === 1}
                  nextDisabled={totalPageCount === page ? true : false}
                  previousPageCall={() => setPage(page - 1)}
                  currentPage={page ? page : 1}
                  nextPageCalls={() => setPage(page + 1)}
                  onPageInputChange={({ target: { value } }) => {
                    var regex = new RegExp(`^[1-${totalPageCount}]$`)
                    if (value <= totalPageCount && value !== 0) {
                      setPage(value)
                    }
                  }}
                />
                              </CardBody>
                                          </Col>
                        </Row>

              </Card>
              </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PMApplications
