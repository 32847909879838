import React, { useState, useEffect, useCallback } from 'react'
import { Card, CardBody, Col, Container, Row, Table } from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import UiContent from '../../Components/Common/UiContent'
import { Link } from 'react-router-dom'
import { organisationServices } from '../../services/organisationServices'
import { toast } from 'react-toastify'
import PaginationV2 from '../../Components/Common/PaginationV2'
import PMInvitaionModal from '../../Components/Common/PMInvitaitonModel'
import moment from 'moment'
import SpinnerComponent from '../../Components/Common/Spinner'

const OrganisationListTable = () => {
  document.title = 'Organisations List | AirGP'
  const [page, setPage] = useState(1)
  const [organisationList, setOrganistionList] = useState([])
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [selectedOrg, setSelectedOrg] = useState()
  const [showInvitaionModal, setInvitationModal] = useState(false)
  const [isLoading , setIsLoading] = useState(false)

  const token = localStorage.getItem('token')

  useEffect(() => {
    fetchOrganisationList()
  }, [page])

  const fetchOrganisationList = () => {
    setIsLoading(true)
    organisationServices
      .getOrganisationsListSuperAdmin('', page, 25, token)
      .then(resp => {
        if (resp.success) {
          setOrganistionList(resp.data || [])
          setTotalPageCount(resp.totalPages || 1)
        }
        console.log(resp)
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchSearchOrganisation = e => {
    const { value } = e.target

    organisationServices
      .getOrganisationsListSuperAdmin(value, page, 25, token)
      .then(resp => {
        if (resp.success) {
          setOrganistionList(resp.data || [])
          setTotalPageCount(resp.totalPages || 1)
        }
      })
      .catch(err => console.log(err))
  }

  const debouncingSearchFunction = func => {
    let timer
    return function (...args) {
      const context = this
      console.log(context)
      clearTimeout(timer)
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 300)
    }
  }

  const handleOnKeyUpSearchBox = useCallback(
    debouncingSearchFunction(fetchSearchOrganisation),
    []
  )

  return (
    <div>
      <React.Fragment>
        <UiContent />
        <div className='page-content'>
          {isLoading && <SpinnerComponent />}
          <Container fluid>
            <BreadCrumb title='PM invitation' pageTitle='Organisations' />
            <Row>
              <Col sm={5}>
                <div className='d-flex'>
                  <div className={'search-box me-2 mb-2 d-inline-block'}>
                    <input
                      onKeyUp={handleOnKeyUpSearchBox}
                      id='search-bar-0'
                      type='text'
                      className='form-control search'
                      placeholder={`Search...`}
                    />
                    <i className='ri-search-line search-icon'></i>
                  </div>
                </div>
              </Col>
              <Col xl={12}>
                <Card>
                  <Col xxl={12}>
                    <Card>
                      <CardBody>
                        <CardBody>
                          <div className='live-preview'>
                            <div className='table-responsive table-card'>
                              <Table className='align-middle table-nowrap mb-0'>
                                <thead className='table-light'>
                                  <tr>
                                    <th scope='col' className='text-center'>
                                      ID
                                    </th>
                                    <th scope='col'>Name</th>
                                    <th scope='col'>Address</th>
                                    <th scope='col' className='text-center'>
                                      Postcode
                                    </th>
                                    <th scope='col' className='text-center'>
                                      Phone No
                                    </th>
                                    <th scope='col' className='text-center'>
                                      Organisation Code
                                    </th>
                                    <th scope='col' className='text-center'>
                                      Invitation Status
                                    </th>
                                    <th scope='col' className='text-center'>
                                      Action
                                    </th>
                                  </tr>
                                </thead>
                                {organisationList.length !== 0 ? (
                                  organisationList.map((_, index) => {
                                    return (
                                      <tbody key={`admin-${index}`}>
                                        <tr>
                                          <td className='text-center'>
                                            <Link to='#' className='fw-medium'>
                                              {`#${_?.uuid.slice(0, 6)}` ||
                                                'NA'}
                                            </Link>
                                          </td>
                                          <td>{_?.name || 'NA'}</td>
                                          <td>{_?.address_line_1 || 'NA'}</td>
                                          <td className='text-center'>
                                            {_?.postcode || 'NA'}
                                          </td>
                                          <td className='text-center'>
                                            {_?.contact_telephone_number ||
                                              'NA'}
                                          </td>
                                          <td className='text-center'>
                                            {_?.organisation_code || 'NA'}
                                          </td>
                                          <td className='text-center'>
                                            {_?.Organisations_invitations
                                              ?.length !== 0 ? (
                                              <span className='badge text-bg-success bg-success'>
                                                {_?.Organisations_invitations?.slice(
                                                  -1
                                                )[0]?.invitation_type +
                                                  ' - ' +
                                                  moment(
                                                    _?.Organisations_invitations?.slice(
                                                      -1
                                                    )[0]?.invitation_date
                                                  ).format('ll')}
                                              </span>
                                            ) : (
                                              '-'
                                            )}
                                          </td>
                                          <td className='text-center'>
                                            {' '}
                                            <button
                                              type='button'
                                              className='btn btn-success btn-sm custom-toggle active'
                                              data-bs-toggle='button'
                                              onClick={() => {
                                                setInvitationModal(true)
                                                setSelectedOrg(_)
                                              }}
                                            >
                                              <span className='icon-on'>
                                                <i className='ri-add-line align-bottom me-1'></i>
                                                Invite
                                              </span>
                                            </button>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )
                                  })
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td className='fw-medium'></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No User found</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </Table>
                            </div>
                          </div>
                        </CardBody>
                      </CardBody>
                    </Card>
                  </Col>

                  <PaginationV2
                    totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                    previousDisabled={page === 1}
                    nextDisabled={totalPageCount === page ? true : false}
                    previousPageCall={() => setPage(page - 1)}
                    currentPage={page}
                    nextPageCalls={() => setPage(page + 1)}
                    onPageInputChange={({ target: { value } }) => {
                      var regex = new RegExp(`^[1-${totalPageCount}]$`)
                      if (value <= totalPageCount && value !== 0) {
                        setPage(value)
                      }
                    }}
                  />

                  <PMInvitaionModal
                    open={showInvitaionModal}
                    toggle={() => setInvitationModal(!showInvitaionModal)}
                    handleClose={() => setInvitationModal(!showInvitaionModal)}
                    selectedPerformer={selectedOrg}
                    handleSuccess={() => fetchOrganisationList()}
                  />
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default OrganisationListTable
