import { customHeaders } from './authServices'
import {
  GET_DOCUMENT_AS_DV_SUPER_ADMIN,
  PATCH_CHANE_DOCUMENT_STATUS_SUPER_ADMIN
} from './endpoints'

export const documentServices = {
  // Get all document List
  getDocumentListSuperAdmin: (limit, page, key, token) =>
    fetch(GET_DOCUMENT_AS_DV_SUPER_ADMIN.endpoint(limit, page, key), {
      method: GET_DOCUMENT_AS_DV_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  patchChangeDocumentStatus: (data, token) =>
    fetch(PATCH_CHANE_DOCUMENT_STATUS_SUPER_ADMIN.endpoint, {
      method: PATCH_CHANE_DOCUMENT_STATUS_SUPER_ADMIN.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err))
}
