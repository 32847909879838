import React, { useCallback, useState } from 'react'
import { useEffect } from 'react'
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Row,
    Table,
    UncontrolledDropdown,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button
} from 'reactstrap'
import PaginationV2 from '../../Components/Common/PaginationV2'
import UiContent from '../../Components/Common/UiContent'
import { pmServices } from '../../services/pmServices'
import { sessionServices } from '../../services/sessionSrvices'
import classnames from 'classnames'
import { Link } from 'react-router-dom'
import moment from 'moment'
import SuccessConfirmationModal from "../../Components/Common/SuccessConfirmationModal"
import {
    badgeColor,
    calculatePensionablePay,
    convertTimeToMinute,
    BILLING_TABS
} from '../../utils/constants'
import BankDetailsModal from '../../Components/Common/BankDetailsModal'
import { CSVLink } from 'react-csv'
import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import SpinnerComponent from '../../Components/Common/Spinner'
import { clientServices } from '../../services/clientServices'
import fetchPDF from '../../common/fetchPDF'

let LIMIT = 20

const PmInvoices = () => {
    const token = localStorage.getItem('token')
    const [totalPageCount, setTotalPageCount] = useState(1)
    const [page, setPage] = useState(1)
    const [sessionFeePlusExpencesTotal, setSessionFeeExpensesTotal] = useState()
    const [pensionTotal, setPensionTotal] = useState()
    const [serviceFeeTotal, setServiceFeeTotal] = useState()
    const [totalOfAllAmount, setTotalOfAllAmount] = useState()
    const [bankDetails, setBankDetails] = useState()
    const [showBankDetailsModal, setShowBankDetailsModal] = useState(false)
    const [topBorderjustifyTab, settopBorderjustifyTab] = useState(0)
    const [showPaidModal, setShowPaidModal] = useState(false)
    const [showPmReceivedPaymentModal, setShowPmReceivedPaymentModal] = useState(false)
    const [showSignFormaModal, setShowSignFormaModal] = useState(false)
    const [showMakeClientEarlyPaidModal, setShowMakeClientEarlyPaidModal] = useState(false)
    const [formAuuid, setFormAuuid] = useState({})
    const [sessionAndIdData, setSessionAndIdData] = useState({})
    const [paymentMethod, setPaymentMethod] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const [pmInvoices, setPmInvoices] = useState([])
    const [dateValue, setDateValue] = useState(moment().format(
        "YYYY-MM-DD HH:mm:ss"
    ));

    const history = useHistory()

    useEffect(() => {
        switch (topBorderjustifyTab) {
            case 0:
                return fetchWorkedSessionAPI(LIMIT, page, token)
            case 1:
                return fetchUnpaidInvoices("Unpaid", LIMIT, page, token)
            case 2:
                return fetchPmInvoices(token, "Payment_initialized")
            case 3:
                return fetchPmInvoices(token, "Paid")
            case 4:
                return fetchPmInvoices(token, "Early_Payment_By_Admin")
            case 5:
                return fetchDirectDebit(token, "Unpaid")
            default:
                return
        }
    }, [page, topBorderjustifyTab])

    const fetchWorkedSessionAPI = (limit, page, token, args) => {
        setIsLoading(true)
        sessionServices
            .getWorkedSessions(limit, page, token, args && args[0]?.target.value || '')
            .then(resp => {
                if (resp.success) {
                    setPmInvoices(resp.data)
                    setTotalPageCount(resp?.totalPages === 0 ? 1 : resp?.totalPages)
                }else{
                    if(resp?.message === "Unauthorised"){
                        localStorage.clear();
                        history.push('/login');
                    }
                }
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const handleDownloadDocument = (pdf_uuid, status, originalFileName) => {
        setIsLoading(true)
        clientServices.downloadDocument(pdf_uuid, status, token)
          .then(resp => fetchPDF(resp, originalFileName))
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const fetchPmInvoices = (token, status, args = []) => {
        setIsLoading(true)
        pmServices
            .getPmInvoices(token, status, args[0]?.target.value || '', LIMIT, page)
            .then(resp => {
                if (resp.success) {
                    const checkedArray = resp.data.map((item, idx) => {
                        return { ...item, isChecked: true }
                    })
                    setPmInvoices(checkedArray)

                    let sessionFeeExpence = 0
                    let pensionFeeTotal = 0
                    let serviceFeeTotal = 0
                    let bonusPayment = 0
                    let total

                    checkedArray.map(item => {
                        let expenceTotalAmt = 0
                        item?.session?.expenses?.map(expns => {
                            expenceTotalAmt += +expns?.amount
                        })

                        const sessionFeePlusExpences =
                            (item?.session?.hourly_rate / 60) *
                            convertTimeToMinute(
                                item?.session?.start_time,
                                item?.session?.end_time,
                                item?.session?.unpaid_break_duration
                            ) +
                            +expenceTotalAmt

                        let feeWithCharges =
                            ((item?.session?.hourly_rate / 60) *
                                convertTimeToMinute(
                                    item?.session?.start_time,
                                    item?.session?.end_time,
                                    item?.session?.unpaid_break_duration
                                ) *
                                item?.session?.withdrawal_percentage) /
                            100

                        sessionFeeExpence +=
                            item?.session?.status === 'withdrawn' ? feeWithCharges : sessionFeePlusExpences

                        const pensionTotal =
                            item?.session?.status === 'withdrawn'
                                ? 0
                                : item?.session?.pensionable ? calculatePensionablePay(
                                    (item?.session?.hourly_rate / 60) *
                                    convertTimeToMinute(
                                        item?.session?.start_time,
                                        item?.session?.end_time,
                                        item?.session?.unpaid_break_duration
                                    ),
                                    expenceTotalAmt
                                ) : 0

                        pensionFeeTotal += +pensionTotal


                        // const service_fee =
                        //     ((item?.session?.hourly_rate / 60) *
                        //         convertTimeToMinute(
                        //             item?.session?.start_time,
                        //             item?.session?.end_time,
                        //             item?.session?.unpaid_break_duration
                        //         ) +
                        //         +expenceTotalAmt) *
                        //     item?.session?.organisation?.service_fee_percentage

                        const service_fee = item?.session?.service_fee

                        serviceFeeTotal += +service_fee
                        bonusPayment += service_fee * ((item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage !== undefined) ?
                            item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage
                            : 0)


                        total = +sessionFeeExpence + +pensionFeeTotal + +serviceFeeTotal

                    })

                    setSessionFeeExpensesTotal(sessionFeeExpence || 0)
                    setPensionTotal(pensionFeeTotal || 0)
                    setServiceFeeTotal(serviceFeeTotal)
                    setTotalOfAllAmount(total)
                    setTotalPageCount(resp.totalPages)
                }
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const fetchDirectDebit = (token, status, args = []) => {
        setIsLoading(true)
        pmServices
            .getDirectDebit(token, status, args[0]?.target.value || '', LIMIT, page)
            .then(resp => {
                if (resp.success) {
                    const checkedArray = resp.data.map((item, idx) => {
                        // let array = item.pm_invoices_intermediates.map(item2 => {
                        //   return { ...item2, isChecked: true }
                        // })

                        return { ...item, isChecked: true }
                    })
                    setPmInvoices(checkedArray)

                    let sessionFeeExpence = 0
                    let pensionFeeTotal = 0
                    let serviceFeeTotal = 0
                    let bonusPayment = 0
                    let total

                    checkedArray.map(item => {
                        let expenceTotalAmt = 0
                        item?.session?.expenses?.map(expns => {

                            // session?.Session?.expenses?.map(expns => {
                            expenceTotalAmt += +expns?.amount
                        })

                        const sessionFeePlusExpences =
                            (item?.session?.hourly_rate / 60) *
                            convertTimeToMinute(
                                item?.session?.start_time,
                                item?.session?.end_time,
                                item?.session?.unpaid_break_duration
                            ) +
                            +expenceTotalAmt

                        let feeWithCharges =
                            ((item?.session?.hourly_rate / 60) *
                                convertTimeToMinute(
                                    item?.session?.start_time,
                                    item?.session?.end_time,
                                    item?.session?.unpaid_break_duration
                                ) *
                                item?.session?.withdrawal_percentage) /
                            100

                        sessionFeeExpence +=
                            item?.session?.status === 'withdrawn' ? feeWithCharges : sessionFeePlusExpences

                        const pensionTotal =
                            item?.session?.status === 'withdrawn'
                                ? 0
                                : item?.session?.pensionable ? calculatePensionablePay(
                                    (item?.session?.hourly_rate / 60) *
                                    convertTimeToMinute(
                                        item?.session?.start_time,
                                        item?.session?.end_time,
                                        item?.session?.unpaid_break_duration
                                    ),
                                    expenceTotalAmt
                                ) : 0

                        pensionFeeTotal += +pensionTotal


                        // const service_fee =
                        //     ((item?.session?.hourly_rate / 60) *
                        //         convertTimeToMinute(
                        //             item?.session?.start_time,
                        //             item?.session?.end_time,
                        //             item?.session?.unpaid_break_duration
                        //         ) +
                        //         +expenceTotalAmt) *
                        //     item?.session?.organisation?.service_fee_percentage

                        const service_fee = item?.session?.service_fee

                        serviceFeeTotal += +service_fee
                        bonusPayment += service_fee * ((item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage !== undefined) ?
                            item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage
                            : 0)


                        total = +sessionFeeExpence + +pensionFeeTotal + +bonusPayment

                    })

                    setSessionFeeExpensesTotal(sessionFeeExpence || 0)
                    setPensionTotal(pensionFeeTotal || 0)
                    setServiceFeeTotal(serviceFeeTotal)
                    setTotalOfAllAmount(total)
                    setTotalPageCount(resp.totalPages)
                }
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const handleCheckedAllClick = (e) => {
        let data = [...pmInvoices]
        const newData = data.map(obj => {
            // obj.pm_invoices_intermediates?.map((item,idx)=>{
            obj.isChecked = e.target.checked
            // })
            return obj
        })
        setPmInvoices(newData)

        const ischeckedArray = newData.filter(item => item?.isChecked)

        if (ischeckedArray.length) {
            let sessionFeeExpence = 0
            let pensionFeeTotal = 0
            let serviceFeeTotal = 0
            let bonusPayment = 0
            let total

            ischeckedArray.map(item => {
                let expenceTotalAmt = 0
                item?.session?.expenses?.map(expns => {

                    // session?.Session?.expenses?.map(expns => {
                    expenceTotalAmt += +expns?.amount
                })

                const sessionFeePlusExpences =
                    (item?.session?.hourly_rate / 60) *
                    convertTimeToMinute(
                        item?.session?.start_time,
                        item?.session?.end_time,
                        item?.session?.unpaid_break_duration
                    ) +
                    +expenceTotalAmt

                let feeWithCharges =
                    ((item?.session?.hourly_rate / 60) *
                        convertTimeToMinute(
                            item?.session?.start_time,
                            item?.session?.end_time,
                            item?.session?.unpaid_break_duration
                        ) *
                        item?.session?.withdrawal_percentage) /
                    100

                sessionFeeExpence +=
                    item?.session?.status === 'withdrawn' ? feeWithCharges : sessionFeePlusExpences

                const pensionTotal =
                    item?.session?.status === 'withdrawn'
                        ? 0
                        : item?.session?.pensionable ? calculatePensionablePay(
                            (item?.session?.hourly_rate / 60) *
                            convertTimeToMinute(
                                item?.session?.start_time,
                                item?.session?.end_time,
                                item?.session?.unpaid_break_duration
                            ),
                            expenceTotalAmt
                        ) : 0

                pensionFeeTotal += +pensionTotal


                // const service_fee =
                //     ((item?.session?.hourly_rate / 60) *
                //         convertTimeToMinute(
                //             item?.session?.start_time,
                //             item?.session?.end_time,
                //             item?.session?.unpaid_break_duration
                //         ) +
                //         +expenceTotalAmt) *
                //     item?.session?.organisation?.service_fee_percentage

                const service_fee = item?.session?.service_fee

                serviceFeeTotal += +service_fee
                bonusPayment += service_fee * ((item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage !== undefined) ?
                    item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage
                    : 0)

                total = +sessionFeeExpence + +pensionFeeTotal + +bonusPayment
            })



            setSessionFeeExpensesTotal(sessionFeeExpence || 0)
            setPensionTotal(pensionFeeTotal || 0)
            setServiceFeeTotal(serviceFeeTotal)
            setTotalOfAllAmount(total)
        } else {
            setSessionFeeExpensesTotal(0)
            setPensionTotal(0)
            setServiceFeeTotal(0)
            setTotalOfAllAmount(0)
        }
    }

    const fetchUnpaidInvoices = (status, limit, page, token, args) => {
        setIsLoading(true)
        pmServices
            .getInvoicesFromStatus(status, limit, page, token, args && args[0]?.target.value || '')
            .then(resp => {
                let finalArray = []
                const sortedDataByWeek = resp?.data
                    ?.map(item => {
                        return { ...item, isChecked: true }
                    })
                    ?.sort(function (a, b) {
                        return new Date(b.invoice.session.start_time) - new Date(a.invoice.session.start_time)
                    })

                Object.keys(sortedDataByWeek).forEach(item => {
                    finalArray.push({ item: sortedDataByWeek[item] })
                })

                setPmInvoices(finalArray)

                setTotalPageCount(resp?.totalPages)
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const handleCheckBoxChange = (e, idx) => {
        const copiedArray = [...pmInvoices]
        copiedArray[idx].isChecked =
            e.target.checked
        setPmInvoices(copiedArray)

        let sessionFeeExpence = 0
        let pensionFeeTotal = 0
        let serviceFeeTotal = 0
        let bonusPayment = 0

        const filteredData = [];
        copiedArray?.forEach((item) => {
            if (item.isChecked) {
                filteredData.push(item)
            }
        })

        if (filteredData.length !== 0) {
            filteredData.map(obj => {
                let expenceTotalAmt = 0
                obj?.session.expenses?.map(expns => {
                    expenceTotalAmt += +expns?.amount
                })

                const sessionFeePlusExpences =
                    (obj?.session?.hourly_rate / 60) *
                    convertTimeToMinute(
                        obj?.session?.start_time,
                        obj?.session?.end_time,
                        obj?.session?.unpaid_break_duration
                    ) +
                    +expenceTotalAmt

                let feeWithCharges =
                    ((obj?.session?.hourly_rate / 60) *
                        convertTimeToMinute(
                            obj?.session?.start_time,
                            obj?.session?.end_time,
                            obj?.session?.unpaid_break_duration
                        ) *
                        obj?.session?.withdrawal_percentage) /
                    100

                sessionFeeExpence +=
                    obj?.session.status === 'withdrawn'
                        ? feeWithCharges
                        : sessionFeePlusExpences

                setSessionFeeExpensesTotal(sessionFeeExpence || 0)

                const pensionTotal =
                    obj?.session.status === 'withdrawn'
                        ? 0
                        : obj?.session?.pensionable ? calculatePensionablePay(
                            (obj?.session?.hourly_rate / 60) *
                            convertTimeToMinute(
                                obj?.session?.start_time,
                                obj?.session?.end_time,
                                obj?.session?.unpaid_break_duration
                            ),
                            expenceTotalAmt
                        ) : 0

                pensionFeeTotal += +pensionTotal
                setPensionTotal(pensionFeeTotal || 0)

                // const service_fee =
                //     ((obj?.session?.hourly_rate / 60) *
                //         convertTimeToMinute(
                //             obj?.session?.start_time,
                //             obj?.session?.end_time,
                //             obj?.session?.unpaid_break_duration
                //         ) +
                //         +expenceTotalAmt) *
                //     obj?.session?.organisation?.service_fee_percentage

                const service_fee = obj?.session?.service_fee

                serviceFeeTotal += +service_fee
                setServiceFeeTotal(serviceFeeTotal)

                bonusPayment += service_fee * ((obj?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage !== undefined) ?
                    obj?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage
                    : 0)

                let total = +sessionFeeExpence + +pensionFeeTotal + +bonusPayment
                setTotalOfAllAmount(total)
            })
        } else {
            setSessionFeeExpensesTotal(0)
            setPensionTotal(0)
            setServiceFeeTotal(0)
            setTotalOfAllAmount(0)
        }
    }

    const fetchBankDetails = (id, token) => {
        setIsLoading(true)
        pmServices
            .getBankDetails(id, token)
            .then(resp => {
                if (resp.success) {
                    setShowBankDetailsModal(true)
                    setBankDetails(resp.bankDetails)
                }
            })
            .catch(err => console.log(err))
            .finally(() => setIsLoading(false))
    }

    const fetchMarkAsPaid = (id, token) => {
        pmServices
            .postMarkAsPaid({ paidDate: moment().format('YYYY-MM-DD') }, id, token)
            .then(resp => {
                if (resp.success) {
                    toast.success(resp.message)
                if(topBorderjustifyTab === 1){
                    fetchUnpaidInvoices("Unpaid", LIMIT, page, token) 
                }
                else {
                    if (paymentMethod == "direct_debit") {
                        fetchDirectDebit(token, "Unpaid")
                    } else {
                        fetchPmInvoices(token, 'Payment_initialized')
                    }
                }
                } else {
                    toast.error(resp.message)
                }
            })
            .catch(err => console.log(err))
    }

    const signFormA = (details, token) => {
        pmServices
            .signFormA(details, token)
            .then(resp => {
                if (resp.success) {
                    toast.success(resp.message)
                } else {
                    toast.error(resp.message)
                }
            })
            .catch(err => console.log(err))
    }

    const fetchMarkAsPMPaymnetReceived = (id, dateValue, token) => {
        pmServices.postMarkAsPMPaymentReceived(id, dateValue, token)
            .then(resp => {
                if (resp.success) {
                    if (topBorderjustifyTab === 1) {
                        fetchUnpaidInvoices("Unpaid", LIMIT, page, token);
                    } else {
                    fetchPmInvoices(token, paymentMethod == "direct_debit" ? "Unpaid" : "Payment_initialized")
                    }
                    toast.success(resp.message)
                }
                else {
                    toast.error(resp.message)
                }
            })
            .catch(err => console.log(err))
    }

    const fetchMarkAsPMPaymnetReceivedPrePaidClient = (id, dateValue, token) => {
        pmServices.postMarkAsPMPaymentReceivedPrePaidClient(id, dateValue, token)
            .then(resp => {
                if (resp.success) {
                        fetchPmInvoices (token, 'Early_Payment_By_Admin')
                    toast.success(resp.message)
                }
                else {
                    toast.error(resp.message)
                }
            })
            .catch(err => console.log(err))
    }


    const makeEarlyPaymentToClient = (id, token) => {
        pmServices
            .postMakeEarlyPaymentToClient(id,{ paidDate: moment().format('YYYY-MM-DD') }, token)
            .then((resp) => {
                if (resp.success) {
                    if (topBorderjustifyTab === 1) {
                        fetchUnpaidInvoices("Unpaid", LIMIT, page, token);
                    } else {
                        fetchPmInvoices(token, paymentMethod == "direct_debit" ? "Unpaid" : "Payment_initialized");
                    }
                    toast.success(resp.message);
                } else {
                    toast.error(resp.message);
                }
            })
            .catch((err) => console.log(err));
    };


    const debouncingSearchFunction = (func, status = '') => {
        let params = [];
        switch (topBorderjustifyTab) {
            case 0:
                params = [LIMIT, page, token]
                break;
            case 1:
                params = ["Unpaid", LIMIT, page, token]
                break;
            case 2:
                status = "Payment_initialized"
                params = [token, status]
                break;
            case 3:
                status = "Paid"
                params = [token, status]
                break;
            case 4:
                status = "Payment_initialized"
                params = [token, status]
                break;
            default:
                status = "Payment_initialized"
        }
        let timer
        return function (...args) {
            params.push(args)
            const context = this
            console.log(context, args)
            clearTimeout(timer)
            if (timer) clearTimeout(timer)
            timer = setTimeout(() => {
                timer = null
                func.apply(context, params)
            }, 300)
        }
    }

    const handleOnKeyUpSearchBox = useCallback(
        debouncingSearchFunction((topBorderjustifyTab === 0 ? fetchWorkedSessionAPI : topBorderjustifyTab === 1 ? fetchUnpaidInvoices : topBorderjustifyTab === 4 ? fetchDirectDebit : fetchPmInvoices)),
        [topBorderjustifyTab])

    document.title = 'Invoices | airGP'
    return (
        <React.Fragment>
            {isLoading && <SpinnerComponent /> }
            <UiContent />
            <div className='page-content'>
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader className='mb-0 pb-0'>
                                    <Nav tabs className='nav-tabs'>
                                        {BILLING_TABS.map((tab, index) => {
                                            return (
                                                <NavItem key={index}>
                                                    <NavLink
                                                        style={{ cursor: 'pointer' }}
                                                        className={classnames({
                                                            active: topBorderjustifyTab === index
                                                        })}
                                                        onClick={() => {
                                                            settopBorderjustifyTab(index)
                                                            setPage(1)
                                                        }}
                                                    >
                                                        {tab}
                                                    </NavLink>
                                                </NavItem>
                                            )
                                        })}
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <TabContent
                                        activeTab={topBorderjustifyTab}
                                        className='text-muted'
                                    >
                                        <TabPane
                                            tabId={topBorderjustifyTab}
                                            id='nav-border-top-home'
                                        >
                                            {
                                                (topBorderjustifyTab === 0 || topBorderjustifyTab === 1) && (
                                                    <Row>
                                                        <Col>
                                                            <div className='ms-2 mb-2'>
                                                                <div className={'search-box me-2 mb-2 d-inline-block'}>
                                                                    <input
                                                                        onKeyUp={handleOnKeyUpSearchBox}
                                                                        id={topBorderjustifyTab === 1 ? 'search-bar-invoiced' : 'search-bar-upcoming'}
                                                                        type='text'
                                                                        className='form-control search /'
                                                                        placeholder={`Search...`}
                                                                    />
                                                                    <i className='ri-search-line search-icon'></i>
                                                                </div>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                )
                                            }
                                            <div className='table-responsive table-card'>
                                                <Table className='align-middle table-nowrap table-sm table-hover mt-4 mb-0'>

                                                    {topBorderjustifyTab === 0 && (
                                                        <>
                                                            <thead className='table-light'>
                                                                <tr>
                                                                    <th></th>
                                                                    <th scope='col'>ID</th>
                                                                    <th scope='col'>Client</th>
                                                                    <th scope='col'>Organisation</th>
                                                                    <th scope='col'>Date & Time</th>
                                                                    <th scope='col'>Fee</th>
                                                                    <th scope='col'>Status</th>
                                                                </tr>
                                                            </thead>
                                                            {pmInvoices?.length !== 0 ? (
                                                                pmInvoices?.map((item, index) => {
                                                                    return (

                                                                        <React.Fragment key={index}>
                                                                            <tbody>
                                                                                <tr key={item?.uuid}>
                                                                                    <td>
                                                                                        <div className='form-check'>
                                                                                            <Label
                                                                                                className='form-check-label'
                                                                                                htmlFor='cardtableCheck01'
                                                                                            ></Label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='small'>
                                                                                        {/* <Link
                                                                                            to='#'
                                                                                        > */}
                                                                                            #
                                                                                            {item?.uuid?.substring(
                                                                                                0,
                                                                                                5
                                                                                            ) || 'NA'}
                                                                                        {/* </Link> */}
                                                                                    </td>
                                                                                    <td  className='mb-1 cursor' onClick={() => history.push(`/client-list?id=${item?.Session_clients[0]?.users?.uuid}`)}>
                                                                                                    {(item?.Session_clients?.length && item?.Session_clients?.find((item) => item?.active === 1)?.users?.firstName) || 'NA'}{' '}
                                                                                                    {(item?.Session_clients?.length && item?.Session_clients?.find((item) => item?.active === 1)?.users?.lastName) || 'NA'}
                                                                                                <p className='text-truncate small mb-0 text-muted'>
                                                                                                        GMC - {item?.Session_clients?.length && item?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.regulator_number}
                                                                                                </p>
                                                                                    </td>
                                                                                    <td className='cursor' onClick={() => history.push(`/organisations?id=${item?.organisation?.uuid}`)}>
                                                                                                    {item?.organisation
                                                                                                        ?.name || 'NA'}
                                                                                                <p className='text-truncate small mb-0 text-muted'>
                                                                                                        {item?.organisation?.postcode || 'NA'}
                                                                                                        {' - '}
                                                                                                        {item?.organisation?.organisation_code || 'NA'}
                                                                                                </p>
                                                                                    </td>
                                                                                    <td>
                                                                                                    {`${moment(
                                                                                                        item?.start_time
                                                                                                    ).format(
                                                                                                        'ddd, ll'
                                                                                                    )}` || 'NA'}
                                                                                                <p className='small mb-0 text-muted'>
                                                                                                        {`${moment(
                                                                                                            item?.start_time
                                                                                                        ).format('LT')}` ||
                                                                                                            'NA'}{' '}
                                                                                                        -{' '}
                                                                                                        {`${moment(
                                                                                                            item?.end_time
                                                                                                        ).format('LT')}` ||
                                                                                                            'NA'}{' '}
                                                                                                        ({' '}{item?.unpaid_break_duration || ' No break'}{' '}
                                                                                                        {item?.unpaid_break_duration !== 0 ? "mins break" : ""}{' '})
                                                                                                </p>
                                                                                    </td>
                                                                                    <td>
                                                                                                £
                                                                                                    {item.status ===
                                                                                                        'withdrawn'
                                                                                                        ? (
                                                                                                            ((item?.hourly_rate /
                                                                                                                60) *
                                                                                                                convertTimeToMinute(
                                                                                                                    item?.start_time,
                                                                                                                    item?.end_time,
                                                                                                                    item?.unpaid_break_duration
                                                                                                                ) *
                                                                                                                item?.withdrawal_percentage) /
                                                                                                            100
                                                                                                        )?.toFixed(2)
                                                                                                        : (
                                                                                                            (item?.hourly_rate /
                                                                                                                60) *
                                                                                                            convertTimeToMinute(
                                                                                                                item?.start_time,
                                                                                                                item?.end_time,
                                                                                                                item?.unpaid_break_duration
                                                                                                            )
                                                                                                            +
                                                                                                            Number(
                                                                                                              item?.client_bonus_amount
                                                                                                            )
                                                                                                        )?.toFixed(2)}
                                                                                                
                                                                                                <p className='small mb-0 text-muted'>
                                                                                                    <span
                                                                                                        className={`fw-medium ${item.status ===
                                                                                                            'withdrawn' &&
                                                                                                            'text-decoration-line-through'
                                                                                                            } `}
                                                                                                    >
                                                                                                        £
                                                                                                        {(
                                                                                                            (item?.hourly_rate /
                                                                                                                60) *
                                                                                                            convertTimeToMinute(
                                                                                                                item?.start_time,
                                                                                                                item?.end_time,
                                                                                                                item?.unpaid_break_duration
                                                                                                            )
                                                                                                        ).toFixed(2) ||
                                                                                                            'NA'}{' '}
                                                                                                        (£
                                                                                                        {item?.hourly_rate ||
                                                                                                            'NA'}{' '}
                                                                                                        /hour)
                                                                                                        + £
                                                                    {Number(
                                                                    item?.client_bonus_amount
                                                                    )?.toFixed(2)}{' '}
                                                                    Bonus
                                                                                                        {item?.pensionable ===
                                                                                                            1 ? (
                                                                                                            <i
                                                                                                                title='Pensionable'
                                                                                                                className='ms-1 ri-file-ppt-line'
                                                                                                            ></i>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </span>
                                                                                                </p>
                                                                                    </td>

                                                                                    <td>
                                                                                        <Badge
                                                                                            color={badgeColor(
                                                                                                item?.status || 'NA'
                                                                                            )}
                                                                                            className='ms-auto'
                                                                                        >
                                                                                            {item?.status || 'NA'}
                                                                                        </Badge>
                                                                                    </td>
                                                                                    {/* <td></td> */}
                                                                                </tr>
                                                                            </tbody>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            ) : (
                                                                <tbody>
                                                                    <tr className='text-center m-5'>
                                                                        <td colSpan={12}>No data found</td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                            }

                                                        </>
                                                    )}

                                                    {topBorderjustifyTab === 1 && (
                                                        <>
                                                            <thead className='table-light'>
                                                                <tr>
                                                                    <th></th>
                                                                    <th scope='col'>ID</th>
                                                                    <th scope='col'>Client</th>
                                                                    <th scope='col'>Organisation</th>
                                                                    <th scope='col'>Date & Time</th>
                                                                    <th scope='col'>Invoice Date</th>
                                                                    <th scope='col'>Fee</th>
                                                                    <th scope='col'>Status</th>
                                                                    <th scope='col'>Bonus</th>
                                                                    <th scope='col'>Pension</th>
                                                                    <th scope='col'>Service Fee</th>
                                                                    <th scope='col'>PM Total</th>
                                                                    <th scope='col'>Digital Signature</th>
                                                                    <th scope='col'>PM Name</th>
                                                                    <th scope='col'>PM Payment</th>
                                                                    <th scope='col'>Reference</th>
                                                                    <th scope='col'>Action</th>
                                                                </tr>
                                                            </thead>

                                                            {pmInvoices?.length !== 0 ? (
                                                                pmInvoices?.map((item, index) => {
                                                                    let expenceTotalAmt = 0

                                                                    item?.item?.invoice?.session?.expenses?.map(
                                                                        expns => {
                                                                            expenceTotalAmt +=
                                                                                +expns?.amount
                                                                        }
                                                                    )


                                                                    let feeWithCharges =
                                                                        ((item?.item?.invoice?.session
                                                                            ?.hourly_rate /
                                                                            60) *
                                                                            convertTimeToMinute(
                                                                                item?.item?.invoice?.session
                                                                                    ?.start_time,
                                                                                item?.item?.invoice?.session
                                                                                    ?.end_time,
                                                                                item?.item?.invoice?.session
                                                                                    ?.unpaid_break_duration
                                                                            ) *
                                                                            item?.item?.invoice?.session
                                                                                ?.withdrawal_percentage) /
                                                                        100

                                                                    const sessionFeePlusExpences =
                                                                        (item?.item?.invoice?.session
                                                                            ?.hourly_rate /
                                                                            60) *
                                                                        convertTimeToMinute(
                                                                            item?.item?.invoice?.session
                                                                                ?.start_time,
                                                                            item?.item?.invoice?.session
                                                                                ?.end_time,
                                                                            item?.item?.invoice?.session
                                                                                ?.unpaid_break_duration
                                                                        ) +
                                                                        +expenceTotalAmt


                                                                    // const service_fee =
                                                                    //     ((Number(item?.item?.invoice?.session
                                                                    //         ?.hourly_rate) /
                                                                    //         60) *
                                                                    //         convertTimeToMinute(
                                                                    //             item?.item?.invoice?.session
                                                                    //                 ?.start_time,
                                                                    //             item?.item?.invoice?.session
                                                                    //                 ?.end_time,
                                                                    //             item?.item?.invoice?.session
                                                                    //                 ?.unpaid_break_duration
                                                                    //         ) +
                                                                    //         +expenceTotalAmt) *
                                                                    //     (Number(item?.item?.invoice?.session?.organisation
                                                                    //         ?.service_fee_percentage))
                                                                            
                                                                    const service_fee = item?.item?.invoice?.session?.service_fee

                                                                    return (

                                                                        <React.Fragment key={index}>
                                                                            <tbody>
                                                                                <tr key={item?.item?.invoice?.session?.uuid}>
                                                                                    <td>
                                                                                        <div className='form-check'>
                                                                                            <Label
                                                                                                className='form-check-label'
                                                                                                htmlFor='cardtableCheck01'
                                                                                            ></Label>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td className='small'>
                                                                                        {/* <Link
                                                                                            to='#'
                                                                                            className='fw-medium'
                                                                                        > */}
                                                                                            #
                                                                                            {item?.item?.invoice?.session?.uuid?.substring(
                                                                                                0,
                                                                                                5
                                                                                            ) || 'NA'}
                                                                                        {/* </Link> */}
                                                                                    </td>
                                                                                    <td className='cursor' onClick={() => history.push(`/client-list?id=${item?.item?.invoice?.session?.Session_clients[0]
                                                                                        ?.users?.uuid}`)}>


                                                                                                    {(item?.item?.invoice?.session?.Session_clients
                                                                                                        ?.length &&
                                                                                                        item?.item?.invoice?.session?.Session_clients[0]
                                                                                                            ?.users?.firstName) ||
                                                                                                        'NA'}{' '}
                                                                                                    {(item?.item?.invoice?.session?.Session_clients
                                                                                                        ?.length &&
                                                                                                        item?.item?.invoice?.session?.Session_clients[0]
                                                                                                            ?.users?.lastName) ||
                                                                                                        'NA'}

                                                                                                <p className='small mb-0 text-muted'>
                                                                                                        GMC - {item?.item?.invoice?.session?.Session_clients[0]?.users?.User_profile?.regulator_number}
                                                                                                </p>
                                                                                    </td>
                                                                                    <td className='fs-14 mb-1 cursor' onClick={() => history.push(`/organisations?id=${item?.item?.invoice?.session?.organisation?.uuid}`)}>

                                                                                                    {item?.item?.invoice?.session?.organisation
                                                                                                        ?.name || 'NA'}
                                                                                                <p className='small mb-0 text-muted'>
                                                                                                        {item?.item?.invoice?.session?.organisation
                                                                                                            ?.postcode || 'NA'}
                                                                                                        {' - '}
                                                                                                        {item?.item?.invoice?.session?.organisation
                                                                                                            ?.organisation_code || 'NA'}
                                                                                                </p>
                                                                                    </td>

                                                                                    <td>
                                                                                                    {`${moment(
                                                                                                        item?.item?.invoice?.session?.start_time
                                                                                                    ).format(
                                                                                                        'ddd, ll'
                                                                                                    )}` || 'NA'}
                                                                                                <p className='small mb-0 text-muted'>
                                                                                                        {`${moment(
                                                                                                            item?.item?.invoice?.session?.start_time
                                                                                                        ).format('LT')}` ||
                                                                                                            'NA'}{' '}
                                                                                                        -{' '}
                                                                                                        {`${moment(
                                                                                                            item?.item?.invoice?.session?.end_time
                                                                                                        ).format('LT')}` ||
                                                                                                            'NA'}{' '}
                                                                                                        ({' '}{item?.item?.invoice?.session?.unpaid_break_duration || ' No break'}{' '}
                                                                                                        {item?.item?.invoice?.session?.unpaid_break_duration !== 0 ? "mins break" : ""}{' '})
                                                                                                </p>
                                                                                    </td>
                                                                                    <td>
                                                                                            {moment(item?.item?.invoice.createdAt).format('ddd, ll')}
                                                                                            <p className='small mb-0 text-muted'>
                                                                                            {moment(item?.item?.invoice.createdAt).fromNow()}
                                                                                        </p>
                                                                                    </td>
                                                                                    <td>
                                                                                                    £
                                                                                                    {item.item?.invoice?.session?.status ===
                                                                                                        'withdrawn'
                                                                                                        ? (
                                                                                                            ((item?.item?.invoice?.session?.hourly_rate /
                                                                                                                60) *
                                                                                                                convertTimeToMinute(
                                                                                                                    item?.item?.invoice?.session?.start_time,
                                                                                                                    item?.item?.invoice?.session?.end_time,
                                                                                                                    item?.item?.invoice?.session?.unpaid_break_duration
                                                                                                                ) *
                                                                                                                item?.item?.invoice?.session?.withdrawal_percentage) /
                                                                                                            100
                                                                                                        )?.toFixed(2)
                                                                                                        : (
                                                                                                            (item?.item?.invoice?.session?.hourly_rate /
                                                                                                                60) *
                                                                                                            convertTimeToMinute(
                                                                                                                item?.item?.invoice?.session?.start_time,
                                                                                                                item?.item?.invoice?.session?.end_time,
                                                                                                                item?.item?.invoice?.session?.unpaid_break_duration
                                                                                                            )
                                                                                                            // +
                                                                                                            // Number(
                                                                                                            //   item?.client_bonus_amount
                                                                                                            // )
                                                                                                        )?.toFixed(2)}
                                                                                                
                                                                                                <p className='text-muted mb-0'>
                                                                                                    <span
                                                                                                        className={`fw-medium ${item.status ===
                                                                                                            'withdrawn' &&
                                                                                                            'text-decoration-line-through'
                                                                                                            } `}
                                                                                                    >
                                                                                                        £
                                                                                                        {(
                                                                                                            (item?.item?.invoice?.session?.hourly_rate /
                                                                                                                60) *
                                                                                                            convertTimeToMinute(
                                                                                                                item?.item?.invoice?.session?.start_time,
                                                                                                                item?.item?.invoice?.session?.end_time,
                                                                                                                item?.item?.invoice?.session?.unpaid_break_duration
                                                                                                            )
                                                                                                        ).toFixed() ||
                                                                                                            'NA'}{' '}
                                                                                                        (£
                                                                                                        {item?.item?.invoice?.session?.hourly_rate ||
                                                                                                            'NA'}
                                                                                                        /hour)
                                                                                                        {/* + £
                                                                    {Number(
                                                                    item?.client_bonus_amount
                                                                    )?.toFixed()}{' '}
                                                                    Bonus */}
                                                                                                        {item?.item?.invoice?.session?.pensionable ===
                                                                                                            1 ? (
                                                                                                            <i
                                                                                                                title='Pensionable'
                                                                                                                className='ms-1 ri-file-ppt-line'
                                                                                                            ></i>
                                                                                                        ) : (
                                                                                                            ''
                                                                                                        )}
                                                                                                    </span>
                                                                                                </p>
                                                                                    </td>

                                                                                    <td>
                                                                                        <Badge
                                                                                            color={badgeColor(
                                                                                                item?.item?.invoice?.session?.status || 'NA'
                                                                                            )}
                                                                                            className='ms-auto'
                                                                                        >
                                                                                            {item?.item?.invoice?.session?.status || 'NA'}
                                                                                        </Badge>
                                                                                    </td>
                                                                                    <td>
                                                                                        £{item?.item?.invoice?.session?.client_bonus_amount  || 'NA'}
                                                                                        
                                                                                        {/* {console.log(item?.item?.invoice?.session)} */}
                                                                                                    {/* £
                                                                                                    {(service_fee * ((item?.item?.invoice?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage !== undefined) ?
                                                                                                        item?.item?.invoice?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage
                                                                                                        : 0)).toFixed(
                                                                                                            2
                                                                                                        ) || ''} */}
                                                                                    
                                                                                    </td>
                                                                                    <td>
  
                                                                                                    {item?.item?.invoice?.session?.status ===
                                                                                                        'withdrawn' || item?.item?.invoice?.session?.pensionable === 0 ? (
                                                                                                        <Badge
                                                                                                            color="danger"
                                                                                                            className='ms-auto'
                                                                                                        >
                                                                                                            {'Not applied' ||
                                                                                                                'NA'}
                                                                                                        </Badge>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            {calculatePensionablePay(
                                                                                                                (item?.item?.invoice?.session?.hourly_rate /
                                                                                                                    60) *
                                                                                                                convertTimeToMinute(
                                                                                                                    item?.item?.invoice?.session?.start_time,
                                                                                                                    item?.item?.invoice?.session?.end_time,
                                                                                                                    item?.item?.invoice?.session?.unpaid_break_duration
                                                                                                                ),
                                                                                                                expenceTotalAmt
                                                                                                            ) === 0 ?
                                                                                                                <Badge color="danger" className='ms-auto'>{'Not applied' || 'NA'}</Badge> :
                                                                                                                <>
                                                                                                                    {'£ '}{calculatePensionablePay((item?.item?.invoice?.session?.hourly_rate / 60) *
                                                                                                                        convertTimeToMinute(
                                                                                                                            item?.item?.invoice?.session?.start_time,
                                                                                                                            item?.item?.invoice?.session?.end_time,
                                                                                                                            item?.item?.invoice?.session?.unpaid_break_duration
                                                                                                                        ),
                                                                                                                        expenceTotalAmt
                                                                                                                    )}
                                                                                                                </>
                                                                                                            }
                                                                                                        </>
                                                                                                    )}
                                                                                    </td>
                                                                                    <td>
                                                                                                    £
                                                                                                    {/* {service_fee.toFixed(
                                                                                                        2
                                                                                                    ) || ''} */}
                                                                                                    { service_fee }
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className='d-flex align-items-center'>
                                                                                            <div className='flex-grow-1'>
                                                                                                <h5 className='mb-1 text-primary fw-semibold '>
                                                                                                    {item?.item?.invoice?.session?.status ===
                                                                                                        'withdrawn' ? (
                                                                                                        <>
                                                                                                            £
                                                                                                            {(
                                                                                                                feeWithCharges +
                                                                                                                +service_fee
                                                                                                            ).toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                        </>
                                                                                                    ) : (
                                                                                                        <>
                                                                                                            £
                                                                                                            {(
                                                                                                                sessionFeePlusExpences +
                                                                                                                (item?.item?.invoice?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                                    (item?.item?.invoice?.session?.hourly_rate /
                                                                                                                        60) *
                                                                                                                    convertTimeToMinute(
                                                                                                                        item?.item?.invoice?.session?.start_time,
                                                                                                                        item?.item?.invoice?.session?.end_time,
                                                                                                                        item?.item?.invoice?.session?.unpaid_break_duration
                                                                                                                    ),
                                                                                                                    expenceTotalAmt
                                                                                                                ) : 0) +
                                                                                                                +(service_fee )
                                                                                                            ).toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                        </>
                                                                                                    )}
                                                                                                </h5>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    {/* <td>
                                                            <h5 className='fs-14 mb-1'>
                                                                {item?.item?.invoice?.session?.invoice?.pm_payment_received === 1 ? 
                                                                  <>{moment(item?.item?.invoice?.session?.invoice.pm_payment_received_date).format('MMMM Do YYYY')}</>:
                                                                    <Badge
                                                                    color={badgeColor(
                                                                        'unfilled' ||
                                                                        'NA'
                                                                    )}
                                                                    className='ms-auto'
                                                                    >
                                                                    {'Not received' ||
                                                                        'NA'}
                                                                    </Badge>
                                                                }
                                                            </h5>
                                                          </td>
                                                          <td>
                                                            <h5 className='fs-14 mb-1'>
                                                                {item?.item?.invoice?.session?.invoice?.reference_number}
                                                            </h5>
                                                          </td> */}
                                                                                    <td>

                                                                                            {(item?.item?.invoice?.session?.Session_clients?.reduce((acc, item, idx) => {
                                                                                                if (item?.active !== 1 && !(item?.users?.signature)) {
                                                                                                    acc = false;
                                                                                                } else {
                                                                                                    acc = true;
                                                                                                }
                                                                                                return acc;
                                                                                            }, false)) ?
                                                                                                (<Badge
                                                                                                    color={badgeColor(
                                                                                                        'completed' ||
                                                                                                        'NA'
                                                                                                    )}
                                                                                                    className='ms-auto'
                                                                                                >
                                                                                                    {'Present'}
                                                                                                </Badge>)
                                                                                                :
                                                                                                (<Badge
                                                                                                    color={badgeColor(
                                                                                                        'unfilled' ||
                                                                                                        'NA'
                                                                                                    )}
                                                                                                    className='ms-auto'
                                                                                                >
                                                                                                    {'Not Present'}
                                                                                                </Badge>)
                                                                                            }
                                                                                    </td>
                                                                                    <td>
                                                                                        <h5 className='fs-14 mb-1'>
                                                                                            {`${item?.item?.invoice?.session?.user?.firstName + " " + item?.item?.invoice?.session?.user?.lastName}`}
                                                                                        </h5>
                                                                                    </td>
                                                                                    <td>
                                                                                            <h5 className='fs-14 mb-1'>
                                                                                                {item?.item?.invoice?.pm_payment_received == 1 ?
                                                                                                    <>
                                                                                                        {
                                                                                                            moment(item?.item?.invoice?.pm_payment_received_date).format('ll') || "NA"
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    <Badge
                                                                                                        color={badgeColor(
                                                                                                            'unfilled' ||
                                                                                                            'NA'
                                                                                                        )}
                                                                                                        className='ms-auto'
                                                                                                    >
                                                                                                        {'Not received' ||
                                                                                                            'NA'}
                                                                                                    </Badge>
                                                                                                }
                                                                                            </h5>
                                                                                        </td>
                                                                                        <td>
                                                                                            <h5 className='fs-14 mb-1'>
                                                                                                {item?.item?.invoice?.reference_number || 'NA'}
                                                                                            </h5>
                                                                                        </td>
                                                                                    <td>
                                                                                    <UncontrolledDropdown className='align-self-start message-box-drop'>
                                                                                                <DropdownToggle
                                                                                                    className='btn-soft-secondary btn-sm dropdown'
                                                                                                    tag='button'
                                                                                                >
                                                                                                    <i className='ri-more-2-fill'></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem
                                                                                                        disabled={!(item?.item?.invoice?.session?.Session_clients[0]
                                                                                                            ?.users
                                                                                                            ?.uuid)}
                                                                                                        onClick={() =>
                                                                                                            fetchBankDetails(
                                                                                                                item?.item?.invoice?.session?.Session_clients[0]
                                                                                                                    ?.users
                                                                                                                    ?.uuid,
                                                                                                                token
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <i className='ri-bank-line me-2 text-muted align-bottom'></i>
                                                                                                        Show Bank
                                                                                                        Details
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                      onClick={() =>{
                                                                                                          setSessionAndIdData({
                                                                                                              uuid: item?.item?.invoice.uuid,
                                                                                                              mark: 'PM'
                                                                                                            })
                                                                                                            setShowMakeClientEarlyPaidModal(true)
                                                                                                            if (item?.item?.invoice?.session?.form_a[0] && item?.item?.invoice?.session?.uuid && item?.item?.invoice?.session?.user_id) {
                                                                                                                setFormAuuid({
                                                                                                                    form_a_id: item?.item?.invoice?.session?.uuid ? [item?.item?.invoice?.session?.uuid] : "",
                                                                                                                    userId: item?.item?.invoice?.session?.user_id
                                                                                                                });
                                                                                                            }
                                                                                                        }
                                                                                                    }
                                                                                                    
                                                                                                    >
                                                                                                      <i className='ri-check-line me-2 text-muted align-bottom'></i>{' '}
                                                                                                      Mark Client Early Paid
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                        onClick={() => {
                                                                                                            setSessionAndIdData({
                                                                                                                uuid: item?.item?.invoice.uuid,
                                                                                                                mark: 'PM'
                                                                                                            })
                                                                                                            setShowPaidModal(true)
                                                                                                            setPaymentMethod("bank_transfer")
                                                                                                            if (item?.item?.invoice?.session?.form_a[0] && item?.item?.invoice?.session?.uuid && item?.item?.invoice?.session?.user_id) {
                                                                                                                setFormAuuid({
                                                                                                                    form_a_id: item?.item?.invoice?.session?.uuid ? [item?.item?.invoice?.session?.uuid] : "",
                                                                                                                    userId: item?.item?.invoice?.session?.user_id
                                                                                                                });
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className='ri-calendar-check-line me-2 text-muted align-bottom'></i>
                                                                                                        Mark as Payment Received from PM
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                        onClick={() => {
                                                                                                            setSessionAndIdData({
                                                                                                                uuid: item?.item?.invoice.uuid,
                                                                                                                mark: 'CLIENT'
                                                                                                            })
                                                                                                            setShowPaidModal(true)
                                                                                                            setPaymentMethod("bank_transfer")
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className='ri-check-line me-2 text-muted align-bottom'></i>
                                                                                                        Mark Client As Paid
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                      onClick={() =>
                                                                                                        handleDownloadDocument(item?.item?.invoice?.uuid, "invoice", `invoice-${item?.item?.invoice?.pdf_copy.split('/')[2]}`)
                                                                                                      }
                                                                                                    >
                                                                                                      <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                                                                                                      Download Client Invoice
                                                                                                    </DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </React.Fragment>
                                                                    )
                                                                })
                                                            ) : (
                                                                <tbody>
                                                                    <tr className='text-center m-5'>
                                                                        <td colSpan={12}>No data found</td>
                                                                    </tr>
                                                                </tbody>
                                                            )
                                                            }

                                                        </>
                                                    )}

                                                    {topBorderjustifyTab === 2 && (
                                                        <>
                                                            <caption>
                                                                <Col>
                                                                    <div className=' ms-3 mb-2'>
                                                                        <div className={'search-box me-2 mb-2 d-inline-block'}>
                                                                            <input
                                                                                onKeyUp={handleOnKeyUpSearchBox}
                                                                                id='search-bar-initialized'
                                                                                type='text'
                                                                                className='form-control search /'
                                                                                placeholder={`Search...`}
                                                                            />
                                                                            <i className='ri-search-line search-icon'></i>
                                                                        </div>
                                                                        <div className={'search-box me-2 mb-2 d-inline-block text-center'}>
                                                                            <CSVLink
                                                                                data={pmInvoices.filter(item => item.isChecked).map(item => {

                                                                                    let expenceTotalAmt = 0

                                                                                    item?.session?.expenses?.map(
                                                                                        expns => {
                                                                                            expenceTotalAmt +=
                                                                                                +expns?.amount
                                                                                        }
                                                                                    )

                                                                                    let feeWithCharges =
                                                                                        ((item?.session?.hourly_rate /
                                                                                            60) *
                                                                                            convertTimeToMinute(
                                                                                                item?.session?.start_time,
                                                                                                item?.session?.end_time,
                                                                                                item?.session?.unpaid_break_duration
                                                                                            ) *
                                                                                            item?.session?.withdrawal_percentage) /
                                                                                        100

                                                                                    const sessionFeePlusExpences =
                                                                                        (item?.session?.hourly_rate /
                                                                                            60) *
                                                                                        convertTimeToMinute(
                                                                                            item?.session?.start_time,
                                                                                            item?.session?.end_time,
                                                                                            item?.session?.unpaid_break_duration
                                                                                        ) +
                                                                                        +expenceTotalAmt

                                                                                    // const service_fee =
                                                                                    //     ((item?.session?.hourly_rate /
                                                                                    //         60) *
                                                                                    //         convertTimeToMinute(
                                                                                    //             item?.session?.start_time,
                                                                                    //             item?.session?.end_time,
                                                                                    //             item?.session?.unpaid_break_duration
                                                                                    //         ) +
                                                                                    //         +expenceTotalAmt) *
                                                                                    //     item?.session?.organisation
                                                                                    //         ?.service_fee_percentage

                                                                                    const service_fee = item?.session?.service_fee;       

                                                                                    return {
                                                                                        "Client Name": ((item?.session?.Session_clients
                                                                                            ?.length &&
                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                ?.users
                                                                                                ?.firstName) ||
                                                                                            'NA' + " " + (item?.session?.Session_clients
                                                                                                ?.length &&
                                                                                                item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                    ?.users
                                                                                                    ?.lastName) ||
                                                                                            'NA'),

                                                                                        "Organisation": item?.session?.organisation?.name,
                                                                                        "Date & Time": `${moment(item?.session?.start_time).format('YYYY-MM-DD HH:mm:ss')} - ${moment(item?.session?.end_time).format('YYYY-MM-DD HH:mm:ss')} - ` || 'NA',
                                                                                        "Status": item?.session?.status,
                                                                                        "Invoice Date": moment(item?.createdAt).format('MMMM Do YYYY') || "NA",
                                                                                        "Fee + Expenses": item?.session?.status === 'withdrawn' ? (
                                                                                            ((item?.session?.hourly_rate /
                                                                                                60) *
                                                                                                convertTimeToMinute(
                                                                                                    item?.session?.start_time,
                                                                                                    item?.session?.end_time,
                                                                                                    item?.session?.unpaid_break_duration
                                                                                                ) *
                                                                                                item?.session?.withdrawal_percentage) /
                                                                                            100
                                                                                        )?.toFixed(
                                                                                            2
                                                                                        ) : ((item?.session?.hourly_rate /
                                                                                            60) *
                                                                                            convertTimeToMinute(
                                                                                                item?.session?.start_time,
                                                                                                item?.session?.end_time,
                                                                                                item?.session?.unpaid_break_duration
                                                                                            ) +
                                                                                            +expenceTotalAmt).toFixed(2),
                                                                                        
                                                                                        "Bonus": (item?.session?.client_bonus_amount),

                                                                                        // "Bonus": ((((item?.session?.hourly_rate /
                                                                                        //     60) *
                                                                                        //     convertTimeToMinute(
                                                                                        //         item?.session?.start_time,
                                                                                        //         item?.session?.end_time,
                                                                                        //         item?.session?.unpaid_break_duration
                                                                                        //     ) +
                                                                                        //     +expenceTotalAmt) *
                                                                                        //     item?.session?.organisation
                                                                                        //         ?.service_fee_percentage) * ((item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage !== undefined) ?
                                                                                        //             item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage
                                                                                        //             : 0)).toFixed(
                                                                                        //                 2
                                                                                        //             ),

                                                                                        "Pension": item?.session?.pensionable === 0 ? "0" : calculatePensionablePay(
                                                                                            (item?.session?.hourly_rate /
                                                                                                60) *
                                                                                            convertTimeToMinute(
                                                                                                item?.session?.start_time,
                                                                                                item?.session?.end_time,
                                                                                                item?.session?.unpaid_break_duration
                                                                                            ),
                                                                                            expenceTotalAmt
                                                                                        ),

                                                                                        "Service Fee": service_fee,

                                                                                        "Client Total": item?.session?.status === 'withdrawn' ? (
                                                                                            feeWithCharges +
                                                                                            +service_fee
                                                                                        ).toFixed(
                                                                                            1
                                                                                        ) : (
                                                                                            sessionFeePlusExpences +
                                                                                            (item?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                (item?.session?.hourly_rate /
                                                                                                    60) *
                                                                                                convertTimeToMinute(
                                                                                                    item?.session?.start_time,
                                                                                                    item?.session?.end_time,
                                                                                                    item?.session?.unpaid_break_duration
                                                                                                ),
                                                                                                expenceTotalAmt
                                                                                            ) : 0) +
                                                                                            +(item?.session?.client_bonus_amount)
                                                                                        ).toFixed(
                                                                                            1
                                                                                        ),


                                                                                        "Total": item?.session?.status ===
                                                                                            'withdrawn' ? (
                                                                                                feeWithCharges +
                                                                                                +service_fee
                                                                                            ).toFixed(
                                                                                                2
                                                                                            ) : (
                                                                                                sessionFeePlusExpences +
                                                                                                (item?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                    (item?.session?.hourly_rate /
                                                                                                        60) *
                                                                                                    convertTimeToMinute(
                                                                                                        item?.session?.start_time,
                                                                                                        item?.session?.end_time,
                                                                                                        item?.session?.unpaid_break_duration
                                                                                                    ),
                                                                                                    expenceTotalAmt
                                                                                                ) : 0) +
                                                                                                +(Number(service_fee))
                                                                                            ).toFixed(
                                                                                                2
                                                                                            ),

                                                                                        "Pm Payment Received date": item?.pm_payment_received_date ? moment(item?.pm_payment_received_date).format('ll') : "NA",
                                                                                        "Reference Number": item?.reference_number,
                                                                                    }

                                                                                })}
                                                                                filename={`file.csv`}
                                                                                target='_blank'
                                                                            >
                                                                                <Button
                                                                                    className='btn-icon'
                                                                                    style={{ width: 180 }}
                                                                                >
                                                                                    Export To CSV
                                                                                </Button>
                                                                            </CSVLink>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </caption>
                                                            {/* <div className='table-responsive table-card'>
                                                                <div className='table-responsive'> */}
                                                                    {/* <Table className='align-middle table-nowrap table-hover table-sm mb-0'> */}
                                                                        <thead className='table-light'>
                                                                            <tr>
                                                                                <th
                                                                                    scope='col'
                                                                                    style={{
                                                                                        width: '42px'
                                                                                    }}
                                                                                >
                                                                                    <div className='form-check'>
                                                                                        <Input
                                                                                            className='form-check-input'
                                                                                            type='checkbox'
                                                                                            defaultValue=''
                                                                                            id='responsivetableCheck'
                                                                                            checked={
                                                                                                !(pmInvoices?.map((item) => {
                                                                                                    // return item?.pm_invoices_intermediates?.map((item2)=>{
                                                                                                    return item.isChecked;
                                                                                                    // })
                                                                                                }).flat(1).includes(false))
                                                                                            }
                                                                                            onChange={e =>
                                                                                                handleCheckedAllClick(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        <Label
                                                                                            className='form-check-label'
                                                                                            for='responsivetableCheck'
                                                                                        ></Label>
                                                                                    </div>
                                                                                </th>
                                                                                <th scope='col'>ID</th>
                                                                                <th scope='col'>
                                                                                    Client
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Organisation
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Date & Time
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Status
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Invoice Date
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Fee + Expenses
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Bonus
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Pension
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Service Fee
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Client Total
                                                                                </th>
                                                                                <th scope='col'>Total</th>
                                                                                <th scope='col'>PM Payment</th>
                                                                                <th scope='col'>Reference</th>
                                                                                <th scope='col'>eSign</th>
                                                                                <th scope='col'>Action</th>
                                                                                <th></th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {pmInvoices.length !== 0 ? pmInvoices?.map((item, idx) => {
                                                                                let expenceTotalAmt = 0

                                                                                item?.session?.expenses?.map(
                                                                                    expns => {
                                                                                        expenceTotalAmt +=
                                                                                            +expns?.amount
                                                                                    }
                                                                                )

                                                                                let feeWithCharges =
                                                                                    ((item?.session?.hourly_rate /
                                                                                        60) *
                                                                                        convertTimeToMinute(
                                                                                            item?.session?.start_time,
                                                                                            item?.session?.end_time,
                                                                                            item?.session?.unpaid_break_duration
                                                                                        ) *
                                                                                        item?.session?.withdrawal_percentage) /
                                                                                    100

                                                                                const sessionFeePlusExpences =
                                                                                    (item?.session?.hourly_rate /
                                                                                        60) *
                                                                                    convertTimeToMinute(
                                                                                        item?.session?.start_time,
                                                                                        item?.session?.end_time,
                                                                                        item?.session?.unpaid_break_duration
                                                                                    ) +
                                                                                    +expenceTotalAmt

                                                                                // const service_fee =
                                                                                //     ((item?.session?.hourly_rate /
                                                                                //         60) *
                                                                                //         convertTimeToMinute(
                                                                                //             item?.session?.start_time,
                                                                                //             item?.session?.end_time,
                                                                                //             item?.session?.unpaid_break_duration
                                                                                //         ) +
                                                                                //         +expenceTotalAmt) *
                                                                                //     item?.session?.organisation
                                                                                //         ?.service_fee_percentage

                                                                                const service_fee = item?.session?.service_fee;
                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            idx
                                                                                        }
                                                                                    >
                                                                                        <th scope='row'>
                                                                                            <div className='form-check'>
                                                                                                <Input
                                                                                                    className='form-check-input'
                                                                                                    type='checkbox'
                                                                                                    checked={
                                                                                                        item.isChecked
                                                                                                    }
                                                                                                    onChange={e =>
                                                                                                        handleCheckBoxChange(
                                                                                                            e,
                                                                                                            idx
                                                                                                        )
                                                                                                    }
                                                                                                    id='responsivetableCheck01'
                                                                                                />
                                                                                                <Label
                                                                                                    className='form-check-label'
                                                                                                    htmlFor='responsivetableCheck01'
                                                                                                ></Label>
                                                                                            </div>
                                                                                        </th>
                                                                                        <td>
                                                                                            {/* <Link
                                                                                                to='#'
                                                                                                className='fw-medium'
                                                                                            > */}
                                                                                                #
                                                                                                {item?.session?.uuid?.substring(
                                                                                                    0,
                                                                                                    5
                                                                                                ) || 'NA'}
                                                                                            {/* </Link> */}
                                                                                        </td>
                                                                                        <td className='mb-1 cursor' onClick={() => history.push(`/client-list?id=${item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.uuid}`)}>

                                                                                                        {(item?.session?.Session_clients
                                                                                                            ?.length &&
                                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                ?.users
                                                                                                                ?.firstName) ||
                                                                                                            'NA'}{' '}
                                                                                                        {(item?.session?.Session_clients
                                                                                                            ?.length &&
                                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                ?.users
                                                                                                                ?.lastName) ||
                                                                                                            'NA'}
                                                                                                    <p className='small mb-0 text-muted'>
                                                                                                        GMC - {item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                            ?.users?.User_profile?.regulator_number || "N/A"}
                                                                                                    </p> 
                                                                                        </td>
                                                                                        <td className='fs-14 mb-1 cursor' onClick={() => history.push(`/organisations?id=${item?.session?.organisation?.uuid}`)}>
                                                                                                        {item?.session?.organisation
                                                                                                            ?.name ||
                                                                                                            'NA'}
                                                                                                    <p className='small mb-0 text-muted'>
                                                                                                            {item?.session?.organisation
                                                                                                                ?.postcode || 'NA'}
                                                                                                            {' - '}
                                                                                                            {item?.session?.organisation
                                                                                                                ?.organisation_code || 'NA'}
                                                                                                    </p>
                                                                                        </td>
                                                                                        <td>

                                                                                                        {`${moment(
                                                                                                            item?.session?.start_time
                                                                                                        ).format(
                                                                                                            'ddd, ll'
                                                                                                        )}` || 'NA'}
                                                                                                    <p className='small mb-0 text-muted'>

                                                                                                            {`${moment(
                                                                                                                item?.session?.start_time
                                                                                                            ).format(
                                                                                                                'LT'
                                                                                                            )}` ||
                                                                                                                'NA'}{' '}
                                                                                                            -{' '}
                                                                                                            {`${moment(
                                                                                                                item?.session?.end_time
                                                                                                            ).format(
                                                                                                                'LT'
                                                                                                            )}` ||
                                                                                                                'NA'}{' '}
                                                                                                            (
                                                                                                            {item?.session?.unpaid_break_duration ||
                                                                                                                'NA'}{' '}
                                                                                                            mins
                                                                                                            break)
                                                                                                    </p>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Badge
                                                                                                color={badgeColor(
                                                                                                    item?.session?.status ||
                                                                                                    'NA'
                                                                                                )}
                                                                                                className='ms-auto'
                                                                                            >
                                                                                                {item?.session?.status ||
                                                                                                    'NA'}
                                                                                            </Badge>
                                                                                        </td>
                                                                                        <td>

                                                                                                {moment(item?.createdAt).format('ll')}
                                                                                                <p className='text-muted small mb-0'>{moment(item?.createdAt).fromNow()}</p>
                                                                                        </td>
                                                                                        <td>

                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' ? (
                                                                                                            <>
                                                                                                                £
                                                                                                                {(
                                                                                                                    ((item?.session?.hourly_rate /
                                                                                                                        60) *
                                                                                                                        convertTimeToMinute(
                                                                                                                            item?.session?.start_time,
                                                                                                                            item?.session?.end_time,
                                                                                                                            item?.session?.unpaid_break_duration
                                                                                                                        ) *
                                                                                                                        item?.session?.withdrawal_percentage) /
                                                                                                                    100
                                                                                                                )?.toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                £
                                                                                                                {sessionFeePlusExpences.toFixed(
                                                                                                                    2
                                                                                                                ) ||
                                                                                                                    'NA'}
                                                                                                            </>
                                                                                                        )}

                                                                                                    <p className='text-muted small mb-0'>
                                                                                                        <span
                                                                                                            className={`fw-medium ${item?.session?.status ===
                                                                                                                'withdrawn' &&
                                                                                                                'text-decoration-line-through'
                                                                                                                } `}
                                                                                                        >
                                                                                                            £
                                                                                                            {(
                                                                                                                (item?.session?.hourly_rate /
                                                                                                                    60) *
                                                                                                                convertTimeToMinute(
                                                                                                                    item?.session?.start_time,
                                                                                                                    item?.session?.end_time,
                                                                                                                    item?.session?.unpaid_break_duration
                                                                                                                )
                                                                                                            ).toFixed() ||
                                                                                                                'NA'}{' '}
                                                                                                            (£
                                                                                                            {item?.session?.hourly_rate ||
                                                                                                                'NA'}{' '}
                                                                                                            /hour) + £
                                                                                                            {expenceTotalAmt?.toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                            {item?.session?.pensionable ===
                                                                                                                1 ? (
                                                                                                                <i
                                                                                                                    title='Pensionable'
                                                                                                                    className='ms-1 ri-file-ppt-line'
                                                                                                                ></i>
                                                                                                            ) : (
                                                                                                                ''
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </p>

                                                                                        </td>
                                                                                        <td>
                                                                                                        {/* {console.log(item)} */}
                                                                                                        £{item?.session?.client_bonus_amount}
                                                                                                        {/* £
                                                                                                        {(service_fee * ((item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage !== undefined) ?
                                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage
                                                                                                            : 0)).toFixed(
                                                                                                                2
                                                                                                            ) || ''} */}
                                                                                        </td>
                                                                                        <td>
                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' || item?.session?.pensionable === 0 ? (
                                                                                                            'NA'
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {'£ '}
                                                                                                                {calculatePensionablePay(
                                                                                                                    (item?.session?.hourly_rate /
                                                                                                                        60) *
                                                                                                                    convertTimeToMinute(
                                                                                                                        item?.session?.start_time,
                                                                                                                        item?.session?.end_time,
                                                                                                                        item?.session?.unpaid_break_duration
                                                                                                                    ),
                                                                                                                    expenceTotalAmt
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                        </td>
                                                                                        <td>
                                                                                                        £
                                                                                                        {/* {service_fee.toFixed(
                                                                                                            2
                                                                                                        ) || ''} */}
                                                                                                        { service_fee }
                                                                                        </td>
                                                                                        <td>
                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' ? (
                                                                                                            <>
                                                                                                                £
                                                                                                                {(
                                                                                                                    feeWithCharges +
                                                                                                                    +service_fee
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                £
                                                                                                                {(
                                                                                                                    sessionFeePlusExpences +
                                                                                                                    (item?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                                        (item?.session?.hourly_rate /
                                                                                                                            60) *
                                                                                                                        convertTimeToMinute(
                                                                                                                            item?.session?.start_time,
                                                                                                                            item?.session?.end_time,
                                                                                                                            item?.session?.unpaid_break_duration
                                                                                                                        ),
                                                                                                                        expenceTotalAmt
                                                                                                                    ) : 0) +
                                                                                                                    +(item?.session?.client_bonus_amount)
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                        </td>
                                                                                        <td>

                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' ? (
                                                                                                            <>
                                                                                                                £{' '}
                                                                                                                {(
                                                                                                                    feeWithCharges +
                                                                                                                    +service_fee
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                £{' '}
                                                                                                                {(
                                                                                                                    sessionFeePlusExpences +
                                                                                                                    (item?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                                        (item?.session?.hourly_rate /
                                                                                                                            60) *
                                                                                                                        convertTimeToMinute(
                                                                                                                            item?.session?.start_time,
                                                                                                                            item?.session?.end_time,
                                                                                                                            item?.session?.unpaid_break_duration
                                                                                                                        ),
                                                                                                                        expenceTotalAmt
                                                                                                                    ) : 0) +
                                                                                                                    +(Number(service_fee))
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                        </td>
                                                                                        <td>
                                                                                            <h5 className='fs-14 mb-1'>
                                                                                                {item?.pm_payment_received == 1 ?
                                                                                                    <>
                                                                                                        {
                                                                                                            moment(item?.pm_payment_received_date).format('ll') || "NA"
                                                                                                        }
                                                                                                    </>
                                                                                                    :
                                                                                                    <Badge
                                                                                                        color={badgeColor(
                                                                                                            'unfilled' ||
                                                                                                            'NA'
                                                                                                        )}
                                                                                                        className='ms-auto'
                                                                                                    >
                                                                                                        {'Not received' ||
                                                                                                            'NA'}
                                                                                                    </Badge>
                                                                                                }
                                                                                            </h5>
                                                                                        </td>
                                                                                        <td>
                                                                                            <h5 className='fs-14 mb-1'>
                                                                                                {item?.reference_number}
                                                                                            </h5>
                                                                                        </td>
                                                                                        <td>
                                                                                                {(item?.session?.Session_clients?.reduce((acc, item, idx) => {
                                                                                                    if (item?.active !== 1 && !(item?.users?.signature)) {
                                                                                                        acc = false;
                                                                                                    } else {
                                                                                                        acc = true;
                                                                                                    }
                                                                                                    return acc;
                                                                                                }, false)) ?
                                                                                                    (<Badge
                                                                                                        color={badgeColor(
                                                                                                            'completed' ||
                                                                                                            'NA'
                                                                                                        )}
                                                                                                        className='ms-auto'
                                                                                                    >
                                                                                                        {'Present'}
                                                                                                    </Badge>)
                                                                                                    :
                                                                                                    (<Badge
                                                                                                        color={badgeColor(
                                                                                                            'unfilled' ||
                                                                                                            'NA'
                                                                                                        )}
                                                                                                        className='ms-auto'
                                                                                                    >
                                                                                                        {'Not Present'}
                                                                                                    </Badge>)
                                                                                                }
                                                                                        </td>
                                                                                        <td>
                                                                                            <UncontrolledDropdown className='align-self-start message-box-drop'>
                                                                                                <DropdownToggle
                                                                                                    className='btn-soft-secondary btn-sm dropdown'
                                                                                                    tag='button'
                                                                                                >
                                                                                                    <i className='ri-more-2-fill'></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem
                                                                                                        disabled={!(item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                            ?.users
                                                                                                            ?.uuid)}
                                                                                                        onClick={() =>
                                                                                                            fetchBankDetails(
                                                                                                                item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                    ?.users
                                                                                                                    ?.uuid,
                                                                                                                token
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <i className='ri-bank-line me-2 text-muted align-bottom'></i>
                                                                                                        Show Bank
                                                                                                        Details
                                                                                                    </DropdownItem>
                                                                                                    {/* <DropdownItem
                                                                                                        disabled={!((item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                            ?.users
                                                                                                            ?.signature
                                                                                                            && item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                ?.users
                                                                                                                ?.pension_client[0]?.claim_pension))}

                                                                                                        onClick={() => {
                                                                                                            if (item?.session?.form_a[0]) {
                                                                                                                setFormAuuid({
                                                                                                                    form_a_id: item?.session?.form_a[0] ? item?.session?.form_a[0]?.uuid : "",
                                                                                                                    userId: item?.session?.Session_clients?.find(item => item.active === 1)?.user_id
                                                                                                                });
                                                                                                                setShowSignFormaModal(true);
                                                                                                            } else {
                                                                                                                toast.info('form-A not found')
                                                                                                            }
                                                                                                        }
                                                                                                        }
                                                                                                    >
                                                                                                        <i className='ri-sketching me-2 text-muted align-bottom'></i>
                                                                                                        Sign Pension Form A
                                                                                                    </DropdownItem> */}
                                                                                                    <DropdownItem
                                                                                                        onClick={() => {
                                                                                                            setSessionAndIdData({
                                                                                                                uuid: item?.uuid,
                                                                                                                mark: 'CLIENT'
                                                                                                            })
                                                                                                            setShowPaidModal(true)
                                                                                                            setPaymentMethod("bank_transfer")
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className='ri-check-line me-2 text-muted align-bottom'></i>
                                                                                                        Mark Client As Paid
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                        onClick={() => {
                                                                                                            setSessionAndIdData({
                                                                                                                uuid: item?.uuid,

                                                                                                                mark: 'PM'
                                                                                                            })
                                                                                                            setShowPaidModal(true)
                                                                                                            setPaymentMethod("bank_transfer")
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className='ri-calendar-check-line me-2 text-muted align-bottom'></i>
                                                                                                        Mark as Payment Received from PM
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                      onClick={() =>
                                                                                                        handleDownloadDocument(item?.uuid, "invoice", `invoice-${item?.pdf_copy.split('/')[2]}`)
                                                                                                      }
                                                                                                    >
                                                                                                      <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                                                                                                      Download Client Invoice
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                      onClick={() =>
                                                                                                        handleDownloadDocument(item?.session?.pm_invoices_intermediates[0]?.PMInvoice?.uuid, "pminvoices", `invoice-${item?.session?.pm_invoices_intermediates[0]?.PMInvoice?.pm_invoice_url.split('/')[4]}`)
                                                                                                      }
                                                                                                    >
                                                                                                      <i className='ri-download-line align-bottom me-2 text-muted'></i>{' '}
                                                                                                      Download PM Invoice
                                                                                                    </DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </td>
                                                                                        <td></td>
                                                                                    </tr>
                                                                                )
                                                                            }) : (
                                                                                // <tbody>
                                                                                <tr className='text-center m-5'>
                                                                                    <td colSpan={18}>No data found</td>
                                                                                </tr>
                                                                                // </tbody>
                                                                            )}
                                                                        </tbody>
                                                                        {pmInvoices.length !== 0 && <tfoot className='table-light'>
                                                                            <tr>
                                                                                <td colSpan='7'>Total</td>
                                                                                <td>
                                                                                    <p className='ps-2'>
                                                                                        £
                                                                                        {sessionFeePlusExpencesTotal?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <p className='ps-0'>
                                                                                        £
                                                                                        {pensionTotal?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className='ps-0'>
                                                                                        £
                                                                                        {serviceFeeTotal?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <p className='ps-0'>
                                                                                        £
                                                                                        {totalOfAllAmount?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td></td>
                                                                                <td>
                                                                                </td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tfoot>}
                                                                    {/* </Table> */}
                                                                {/* </div>
                                                            </div> */}
                                                        </>
                                                    )}

                                                    {topBorderjustifyTab === 3 && (
                                                        <>
                                                            <caption>
                                                                <Col>
                                                                    <div className='ms-3 mb-2'>
                                                                        <div className={'search-box me-2 mb-2 d-inline-block'}>
                                                                            <input
                                                                                onKeyUp={handleOnKeyUpSearchBox}
                                                                                id='search-bar-paid'
                                                                                type='text'
                                                                                className='form-control search /'
                                                                                placeholder={`Search...`}
                                                                            />
                                                                            <i className='ri-search-line search-icon'></i>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </caption>


                                                                        <thead className='table-light'>
                                                                            <tr>
                                                                                <th
                                                                                    scope='col'
                                                                                    style={{
                                                                                        width: '42px'
                                                                                    }}
                                                                                >
                                                                                    <div className='form-check'>
                                                                                        <Input
                                                                                            className='form-check-input'
                                                                                            type='checkbox'
                                                                                            defaultValue=''
                                                                                            id='responsivetableCheck'
                                                                                            checked={
                                                                                                !(pmInvoices?.map((item) => {
                                                                                                    // return item?.pm_invoices_intermediates?.map((item2)=>{
                                                                                                    return item.isChecked;
                                                                                                    // })
                                                                                                }).flat(1).includes(false))
                                                                                            }
                                                                                            onChange={e =>
                                                                                                handleCheckedAllClick(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        <Label
                                                                                            className='form-check-label'
                                                                                            for='responsivetableCheck'
                                                                                        ></Label>
                                                                                    </div>
                                                                                </th>
                                                                                <th scope='col'>ID</th>
                                                                                <th scope='col'>
                                                                                    Client
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Organisation
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Date & Time
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Status
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Payment Method
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Invoice Date
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    PM PAID
                                                                                </th>
                                                                                <th>
                                                                                    Client PAID
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Fee + Expenses
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Bonus
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Pension
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Service Fee
                                                                                </th>
                                                                                <th scope='col'>Total</th>
                                                                                <th scope='col'>Reference Number</th>
                                                                                <th scope='col'>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {pmInvoices.length !== 0 ? pmInvoices?.map((item, idx) => {

                                                                                let expenceTotalAmt = 0

                                                                                item?.session?.expenses?.map(
                                                                                    expns => {
                                                                                        expenceTotalAmt +=
                                                                                            +expns?.amount
                                                                                    }
                                                                                )

                                                                                let feeWithCharges =
                                                                                    ((item?.session?.hourly_rate /
                                                                                        60) *
                                                                                        convertTimeToMinute(
                                                                                            item?.session?.start_time,
                                                                                            item?.session?.end_time,
                                                                                            item?.session?.unpaid_break_duration
                                                                                        ) *
                                                                                        item?.session?.withdrawal_percentage) /
                                                                                    100

                                                                                const sessionFeePlusExpences =
                                                                                    (item?.session?.hourly_rate /
                                                                                        60) *
                                                                                    convertTimeToMinute(
                                                                                        item?.session?.start_time,
                                                                                        item?.session?.end_time,
                                                                                        item?.session?.unpaid_break_duration
                                                                                    ) +
                                                                                    +expenceTotalAmt

                                                                                // const service_fee =
                                                                                //     ((item?.session?.hourly_rate /
                                                                                //         60) *
                                                                                //         convertTimeToMinute(
                                                                                //             item?.session?.start_time,
                                                                                //             item?.session?.end_time,
                                                                                //             item?.session?.unpaid_break_duration
                                                                                //         ) +
                                                                                //         +expenceTotalAmt) *
                                                                                //     item?.session?.organisation
                                                                                //         ?.service_fee_percentage

                                                                                const service_fee = item?.session?.service_fee;

                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            idx
                                                                                        }
                                                                                    >
                                                                                        <th scope='row'>
                                                                                            <div className='form-check'>
                                                                                                <Input
                                                                                                    className='form-check-input'
                                                                                                    type='checkbox'
                                                                                                    checked={
                                                                                                        item?.isChecked
                                                                                                    }
                                                                                                    onChange={e =>
                                                                                                        handleCheckBoxChange(
                                                                                                            e,
                                                                                                            idx
                                                                                                        )
                                                                                                    }
                                                                                                    id='responsivetableCheck01'
                                                                                                />
                                                                                                <Label
                                                                                                    className='form-check-label'
                                                                                                    htmlFor='responsivetableCheck01'
                                                                                                ></Label>
                                                                                            </div>
                                                                                        </th>
                                                                                        <td>
                                                                                            {/* <Link
                                                                                                to='#'
                                                                                                className='fw-medium'
                                                                                            > */}
                                                                                                #
                                                                                                {item?.session?.uuid?.substring(
                                                                                                    0,
                                                                                                    5
                                                                                                ) || 'NA'}
                                                                                            {/* </Link> */}
                                                                                        </td>
                                                                                        <td className='mb-1 cursor' onClick={() => history.push(`/client-list?id=${item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.uuid}`)}>


                                                                                                        {(item?.session?.Session_clients
                                                                                                            ?.length &&
                                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                ?.users
                                                                                                                ?.firstName) ||
                                                                                                            'NA'}{' '}
                                                                                                        {(item?.session?.Session_clients
                                                                                                            ?.length &&
                                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                ?.users
                                                                                                                ?.lastName) ||
                                                                                                            'NA'}
                                                                                                    <p className='text-muted small mb-0'>
                                                                                                        <span className='fw-medium'> GMC - {item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                            ?.users?.User_profile?.regulator_number || "N/A"}</span>
                                                                                                    </p>
                                                                                        </td>
                                                                                        <td className='mb-1 cursor' onClick={() => history.push(`/organisations?id=${item?.session?.organisation?.uuid}`)}>
                                                                                                        {item?.session?.organisation
                                                                                                            ?.name ||
                                                                                                            'NA'}
                                                                                                    <p className='text-muted small mb-0'>
                                                                                                            {item?.session?.organisation
                                                                                                                ?.postcode || 'NA'}
                                                                                                            {' - '}
                                                                                                            {item?.session?.organisation
                                                                                                                ?.organisation_code || 'NA'}
                                                                                                    </p>
                                                                                        </td>
                                                                                        <td>
                                                                                                        {`${moment(
                                                                                                            item?.session?.start_time
                                                                                                        ).format(
                                                                                                            'ddd, ll'
                                                                                                        )}` || 'NA'}

                                                                                                    <p className='text-muted small mb-0'>
                                                                                                            {`${moment(
                                                                                                                item?.session?.start_time
                                                                                                            ).format(
                                                                                                                'LT'
                                                                                                            )}` ||
                                                                                                                'NA'}{' '}
                                                                                                            -{' '}
                                                                                                            {`${moment(
                                                                                                                item?.session?.end_time
                                                                                                            ).format(
                                                                                                                'LT'
                                                                                                            )}` ||
                                                                                                                'NA'}{' '}
                                                                                                            ({item?.session?.unpaid_break_duration !== 0 ? `${item?.session?.unpaid_break_duration} mins break` : "No Break"})
                                                                                                    </p>
                                                                                        </td>
                                                                                        <td className='small'>
                                                                                            <Badge
                                                                                                color={badgeColor(
                                                                                                    item?.session?.status ||
                                                                                                    'NA'
                                                                                                )}
                                                                                                className='ms-auto'
                                                                                            >
                                                                                                {item?.session?.status ||
                                                                                                    'NA'}
                                                                                            </Badge>
                                                                                        </td>
                                                                                        <td className='small'>
                                                                                            <Badge
                                                                                                color={badgeColor(
                                                                                                    item?.payment_method ||
                                                                                                    'NA'
                                                                                                )}
                                                                                                className='ms-auto'
                                                                                            >
                                                                                                {item?.payment_method ||
                                                                                                    'NA'}
                                                                                            </Badge>
                                                                                        </td>
                                                                                        <td>

                                                                                                {moment(item?.createdAt).format('L')}

                                                                                        </td>
                                                                                        <td>

                                                                                                {item?.pm_payment_received_date ? <>{moment(item?.pm_payment_received_date).format('L')}</> : "-"}

                                                                                        </td>
                                                                                        <td>{item?.session?.invoice?.paid_date ? <>{moment(item?.session?.invoice?.paid_date).format('L')}</> : "-"}</td>

                                                                                        <td>

                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' ? (
                                                                                                            <>
                                                                                                                £
                                                                                                                {(
                                                                                                                    ((item?.session?.hourly_rate /
                                                                                                                        60) *
                                                                                                                        convertTimeToMinute(
                                                                                                                            item?.session?.start_time,
                                                                                                                            item?.session?.end_time,
                                                                                                                            item?.session?.unpaid_break_duration
                                                                                                                        ) *
                                                                                                                        item?.session?.withdrawal_percentage) /
                                                                                                                    100
                                                                                                                )?.toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                £
                                                                                                                {sessionFeePlusExpences.toFixed(
                                                                                                                    2
                                                                                                                ) ||
                                                                                                                    'NA'}
                                                                                                            </>
                                                                                                        )}

                                                                                                    <p className='text-muted mb-0'>
                                                                                                        <span
                                                                                                            className={`fw-medium ${item?.session?.status ===
                                                                                                                'withdrawn' &&
                                                                                                                'text-decoration-line-through'
                                                                                                                } `}
                                                                                                        >
                                                                                                            £
                                                                                                            {(
                                                                                                                (item?.session?.hourly_rate /
                                                                                                                    60) *
                                                                                                                convertTimeToMinute(
                                                                                                                    item?.session?.start_time,
                                                                                                                    item?.session?.end_time,
                                                                                                                    item?.session?.unpaid_break_duration
                                                                                                                )
                                                                                                            ).toFixed(2) ||
                                                                                                                'NA'}{' '}
                                                                                                            (£
                                                                                                            {item?.session?.hourly_rate ||
                                                                                                                'NA'}
                                                                                                            /h)
                                                                                                            {
                                                                                                                expenceTotalAmt !== 0 ? <>+ £{' '} {expenceTotalAmt?.toFixed(2)}</> : ""
                                                                                                            }

                                                                                                            {item?.session?.pensionable ===
                                                                                                                1 ? (
                                                                                                                <i
                                                                                                                    title='Pensionable'
                                                                                                                    className='ms-1 ri-file-ppt-line'
                                                                                                                ></i>
                                                                                                            ) : (
                                                                                                                ''
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </p>
                                                                                        </td>
                                                                                        <td>

                                                                                                        {/* £
                                                                                                        {(service_fee * ((item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage !== undefined) ?
                                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.User_profile?.bonus_fee_percentage
                                                                                                            : 0)).toFixed(
                                                                                                                2
                                                                                                            ) || ''} */}


                                                                                                        £{item?.session?.client_bonus_amount}
                                                                                        </td>
                                                                                        <td>

                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' || item?.session?.pensionable === 0 ? (
                                                                                                            'NA'
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {'£'}
                                                                                                                {calculatePensionablePay(
                                                                                                                    (item?.session?.hourly_rate /
                                                                                                                        60) *
                                                                                                                    convertTimeToMinute(
                                                                                                                        item?.session?.start_time,
                                                                                                                        item?.session?.end_time,
                                                                                                                        item?.session?.unpaid_break_duration
                                                                                                                    ),
                                                                                                                    expenceTotalAmt
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='mb-1 text-primary fw-semibold '>
                                                                                                        £{' '}
                                                                                                        {/* {service_fee.toFixed(
                                                                                                            2
                                                                                                        ) || ''} */}
                                                                                                        { service_fee }
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>

                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' ? (
                                                                                                            <>
                                                                                                                £
                                                                                                                {(
                                                                                                                    feeWithCharges +
                                                                                                                    +service_fee
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                £
                                                                                                                {(
                                                                                                                    sessionFeePlusExpences +
                                                                                                                    (item?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                                        (item?.session?.hourly_rate /
                                                                                                                            60) *
                                                                                                                        convertTimeToMinute(
                                                                                                                            item?.session?.start_time,
                                                                                                                            item?.session?.end_time,
                                                                                                                            item?.session?.unpaid_break_duration
                                                                                                                        ),
                                                                                                                        expenceTotalAmt
                                                                                                                    ) : 0) +
                                                                                                                    +(Number(service_fee))
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                               
                                                                                        </td>
                                                                                        <td>

                                                                                                {item?.reference_number}
                                                                                        </td>
                                                                                        <td>
                                                                                    <UncontrolledDropdown className="align-self-start message-box-drop">
                                                                                        <DropdownToggle
                                                                                            className="btn-soft-secondary btn-sm dropdown"
                                                                                            tag="button"
                                                                                        >
                                                                                            <i className="ri-more-2-fill"></i>
                                                                                        </DropdownToggle>
                                                                                        <DropdownMenu>
                                                                                            <DropdownItem
                                                                                                disabled={
                                                                                                    !(
                                                                                                        item?.session
                                                                                                            ?.form_a[0]
                                                                                                            ?.uuid &&
                                                                                                        item?.session
                                                                                                            ?.form_a[0]
                                                                                                            ?.form_a_file_path
                                                                                                    )
                                                                                                }
                                                                                                onClick={() => {
                                                                                                    handleDownloadDocument(
                                                                                                        item?.session
                                                                                                            ?.form_a[0]
                                                                                                            ?.uuid,
                                                                                                        "pensionforma",
                                                                                                        `invoice-${
                                                                                                            item?.session?.form_a[0]?.form_a_file_path?.split(
                                                                                                                "/"
                                                                                                            )[3]
                                                                                                        }`
                                                                                                    );
                                                                                                }}
                                                                                            >
                                                                                                <i className="ri-download-line align-bottom me-2 text-muted"></i>{" "}
                                                                                                Download Pension Form A
                                                                                            </DropdownItem>
                                                                                            <DropdownItem
                                                                                                onClick={() =>
                                                                                                    handleDownloadDocument(
                                                                                                        item?.session
                                                                                                            ?.pm_invoices_intermediates[0]
                                                                                                            ?.PMInvoice
                                                                                                            ?.uuid,
                                                                                                        "pminvoices",
                                                                                                        `invoice-${
                                                                                                            item?.session?.pm_invoices_intermediates[0]?.PMInvoice?.pm_invoice_url.split(
                                                                                                                "/"
                                                                                                            )[3]
                                                                                                        }`
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className="ri-download-line align-bottom me-2 text-muted"></i>{" "}
                                                                                                Download PM Invoice
                                                                                            </DropdownItem>
                                                                                            <DropdownItem
                                                                                                onClick={() =>
                                                                                                    handleDownloadDocument(
                                                                                                        item?.uuid,
                                                                                                        "invoice",
                                                                                                        `invoice-${
                                                                                                            item?.pdf_copy.split(
                                                                                                                "/"
                                                                                                            )[2]
                                                                                                        }`
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className="ri-download-line align-bottom me-2 text-muted"></i>{" "}
                                                                                                Download Client Invoice
                                                                                            </DropdownItem>
                                                                                        </DropdownMenu>
                                                                                    </UncontrolledDropdown>
                                                                                </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                                :
                                                                                <tr className='text-center m-5'>
                                                                                    <td colSpan={18}>No data found</td>
                                                                                </tr>

                                                                            }
                                                                        </tbody>
                                                                        {pmInvoices.length !== 0 && <tfoot className='table-light'>
                                                                            <tr>
                                                                                <td colSpan='8'>Total</td>
                                                                                <td></td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <p className='ps-2'>
                                                                                        £{' '}
                                                                                        {sessionFeePlusExpencesTotal?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <p className='ps-0'>
                                                                                        £
                                                                                        {pensionTotal?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className='ps-0'>
                                                                                        £
                                                                                        {serviceFeeTotal?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className='ps-0'>
                                                                                        £
                                                                                        {totalOfAllAmount?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td></td>
                                                                                <td></td>
                                                                            </tr>
                                                                        </tfoot>}
                                                                    </>

                                                    )}

                                                    {topBorderjustifyTab === 4 && (
                                                        <>
                                                            <caption>
                                                                <Col>
                                                                    <div className="ms-3 mb-2">
                                                                        <div
                                                                            className={
                                                                                "search-box me-2 mb-2 d-inline-block"
                                                                            }
                                                                        >
                                                                            <input
                                                                                onKeyUp={handleOnKeyUpSearchBox}
                                                                                id="search-bar-paid"
                                                                                type="text"
                                                                                className="form-control search /"
                                                                                placeholder={`Search...`}
                                                                            />
                                                                            <i className="ri-search-line search-icon"></i>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </caption>

                                                            <thead className="table-light">
                                                                <tr>
                                                                    <th scope="col">ID</th>
                                                                    <th scope="col">Client</th>
                                                                    <th scope="col">Organisation</th>
                                                                    <th scope="col">Date & Time</th>
                                                                    <th scope="col">Status</th>
                                                                    <th scope="col">Invoice Date</th>
                                                                    <th>Client PAID</th>
                                                                    <th scope="col">Fee + Expenses</th>
                                                                    <th scope="col">Bonus</th>
                                                                    <th scope="col">Pension</th>
                                                                    <th scope="col">Service Fee</th>
                                                                    <th scope="col">Total</th>
                                                                    <th scope='col'>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {pmInvoices.length !== 0 ? (
                                                                    pmInvoices?.map((item, idx) => {
                                                                        let expenceTotalAmt = 0;

                                                                        item?.session?.expenses?.map((expns) => {
                                                                            expenceTotalAmt += +expns?.amount;
                                                                        });

                                                                        let feeWithCharges =
                                                                            ((item?.session?.hourly_rate / 60) *
                                                                                convertTimeToMinute(
                                                                                    item?.session?.start_time,
                                                                                    item?.session?.end_time,
                                                                                    item?.session?.unpaid_break_duration
                                                                                ) *
                                                                                item?.session?.withdrawal_percentage) /
                                                                            100;

                                                                        const sessionFeePlusExpences =
                                                                            (item?.session?.hourly_rate / 60) *
                                                                                convertTimeToMinute(
                                                                                    item?.session?.start_time,
                                                                                    item?.session?.end_time,
                                                                                    item?.session?.unpaid_break_duration
                                                                                ) +
                                                                            +expenceTotalAmt;

                                                                        const service_fee = item?.session?.service_fee;

                                                                        return (
                                                                            <tr key={idx}>
                                                                                <td>
                                                                                    #
                                                                                    {item?.session?.uuid?.substring(
                                                                                        0,
                                                                                        5
                                                                                    ) || "NA"}
                                                                                </td>
                                                                                <td
                                                                                    className="mb-1 cursor"
                                                                                    onClick={() =>
                                                                                        history.push(
                                                                                            `/client-list?id=${
                                                                                                item?.session?.Session_clients?.find(
                                                                                                    (item) =>
                                                                                                        item?.active ===
                                                                                                        1
                                                                                                )?.users?.uuid
                                                                                            }`
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {(item?.session?.Session_clients
                                                                                        ?.length &&
                                                                                        item?.session?.Session_clients?.find(
                                                                                            (item) => item?.active === 1
                                                                                        )?.users?.firstName) ||
                                                                                        "NA"}{" "}
                                                                                    {(item?.session?.Session_clients
                                                                                        ?.length &&
                                                                                        item?.session?.Session_clients?.find(
                                                                                            (item) => item?.active === 1
                                                                                        )?.users?.lastName) ||
                                                                                        "NA"}
                                                                                    <p className="text-muted small mb-0">
                                                                                        <span className="fw-medium">
                                                                                            {" "}
                                                                                            GMC -{" "}
                                                                                            {item?.session?.Session_clients?.find(
                                                                                                (item) =>
                                                                                                    item?.active === 1
                                                                                            )?.users?.User_profile
                                                                                                ?.regulator_number ||
                                                                                                "N/A"}
                                                                                        </span>
                                                                                    </p>
                                                                                </td>
                                                                                <td
                                                                                    className="mb-1 cursor"
                                                                                    onClick={() =>
                                                                                        history.push(
                                                                                            `/organisations?id=${item?.session?.organisation?.uuid}`
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    {item?.session?.organisation
                                                                                        ?.name || "NA"}
                                                                                    <p className="text-muted small mb-0">
                                                                                        {item?.session?.organisation
                                                                                            ?.postcode || "NA"}
                                                                                        {" - "}
                                                                                        {item?.session?.organisation
                                                                                            ?.organisation_code || "NA"}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    {`${moment(
                                                                                        item?.session?.start_time
                                                                                    ).format("ddd, ll")}` || "NA"}

                                                                                    <p className="text-muted small mb-0">
                                                                                        {`${moment(
                                                                                            item?.session?.start_time
                                                                                        ).format("LT")}` || "NA"}{" "}
                                                                                        -{" "}
                                                                                        {`${moment(
                                                                                            item?.session?.end_time
                                                                                        ).format("LT")}` || "NA"}{" "}
                                                                                        (
                                                                                        {item?.session
                                                                                            ?.unpaid_break_duration !==
                                                                                        0
                                                                                            ? `${item?.session?.unpaid_break_duration} mins break`
                                                                                            : "No Break"}
                                                                                        )
                                                                                    </p>
                                                                                </td>
                                                                                <td className="small">
                                                                                    <Badge
                                                                                        color={badgeColor(
                                                                                            item?.session?.status ||
                                                                                                "NA"
                                                                                        )}
                                                                                        className="ms-auto"
                                                                                    >
                                                                                        {item?.session?.status || "NA"}
                                                                                    </Badge>
                                                                                </td>
                                                                                <td>
                                                                                    {moment(item?.createdAt).format(
                                                                                        "L"
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.session?.invoice
                                                                                        ?.paid_date ? (
                                                                                        <>
                                                                                            {moment(
                                                                                                item?.session?.invoice
                                                                                                    ?.paid_date
                                                                                            ).format("L")}
                                                                                        </>
                                                                                    ) : (
                                                                                        "-"
                                                                                    )}
                                                                                </td>

                                                                                <td>
                                                                                    {item?.session?.status ===
                                                                                    "withdrawn" ? (
                                                                                        <>
                                                                                            £
                                                                                            {(
                                                                                                ((item?.session
                                                                                                    ?.hourly_rate /
                                                                                                    60) *
                                                                                                    convertTimeToMinute(
                                                                                                        item?.session
                                                                                                            ?.start_time,
                                                                                                        item?.session
                                                                                                            ?.end_time,
                                                                                                        item?.session
                                                                                                            ?.unpaid_break_duration
                                                                                                    ) *
                                                                                                    item?.session
                                                                                                        ?.withdrawal_percentage) /
                                                                                                100
                                                                                            )?.toFixed(2)}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            £
                                                                                            {sessionFeePlusExpences.toFixed(
                                                                                                2
                                                                                            ) || "NA"}
                                                                                        </>
                                                                                    )}

                                                                                    <p className="text-muted mb-0">
                                                                                        <span
                                                                                            className={`fw-medium ${
                                                                                                item?.session
                                                                                                    ?.status ===
                                                                                                    "withdrawn" &&
                                                                                                "text-decoration-line-through"
                                                                                            } `}
                                                                                        >
                                                                                            £
                                                                                            {(
                                                                                                (item?.session
                                                                                                    ?.hourly_rate /
                                                                                                    60) *
                                                                                                convertTimeToMinute(
                                                                                                    item?.session
                                                                                                        ?.start_time,
                                                                                                    item?.session
                                                                                                        ?.end_time,
                                                                                                    item?.session
                                                                                                        ?.unpaid_break_duration
                                                                                                )
                                                                                            ).toFixed(2) || "NA"}{" "}
                                                                                            (£
                                                                                            {item?.session
                                                                                                ?.hourly_rate || "NA"}
                                                                                            /h)
                                                                                            {expenceTotalAmt !== 0 ? (
                                                                                                <>
                                                                                                    + £{" "}
                                                                                                    {expenceTotalAmt?.toFixed(
                                                                                                        2
                                                                                                    )}
                                                                                                </>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                            {item?.session
                                                                                                ?.pensionable === 1 ? (
                                                                                                <i
                                                                                                    title="Pensionable"
                                                                                                    className="ms-1 ri-file-ppt-line"
                                                                                                ></i>
                                                                                            ) : (
                                                                                                ""
                                                                                            )}
                                                                                        </span>
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    £
                                                                                    {item?.session?.client_bonus_amount}
                                                                                </td>
                                                                                <td>
                                                                                    {item?.session?.status ===
                                                                                        "withdrawn" ||
                                                                                    item?.session?.pensionable === 0 ? (
                                                                                        "NA"
                                                                                    ) : (
                                                                                        <>
                                                                                            {"£"}
                                                                                            {calculatePensionablePay(
                                                                                                (item?.session
                                                                                                    ?.hourly_rate /
                                                                                                    60) *
                                                                                                    convertTimeToMinute(
                                                                                                        item?.session
                                                                                                            ?.start_time,
                                                                                                        item?.session
                                                                                                            ?.end_time,
                                                                                                        item?.session
                                                                                                            ?.unpaid_break_duration
                                                                                                    ),
                                                                                                expenceTotalAmt
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                    <div className="d-flex align-items-center">
                                                                                        <div className="flex-grow-1">
                                                                                            <h5 className="mb-1 text-primary fw-semibold ">
                                                                                                £ {service_fee}
                                                                                            </h5>
                                                                                        </div>
                                                                                    </div>
                                                                                </td>
                                                                                <td>
                                                                                    {item?.session?.status ===
                                                                                    "withdrawn" ? (
                                                                                        <>
                                                                                            £
                                                                                            {(
                                                                                                feeWithCharges +
                                                                                                +service_fee
                                                                                            ).toFixed(2)}
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            £
                                                                                            {(
                                                                                                sessionFeePlusExpences +
                                                                                                (item?.session
                                                                                                    ?.pensionable === 1
                                                                                                    ? +calculatePensionablePay(
                                                                                                          (item?.session
                                                                                                              ?.hourly_rate /
                                                                                                              60) *
                                                                                                              convertTimeToMinute(
                                                                                                                  item
                                                                                                                      ?.session
                                                                                                                      ?.start_time,
                                                                                                                  item
                                                                                                                      ?.session
                                                                                                                      ?.end_time,
                                                                                                                  item
                                                                                                                      ?.session
                                                                                                                      ?.unpaid_break_duration
                                                                                                              ),
                                                                                                          expenceTotalAmt
                                                                                                      )
                                                                                                    : 0) +
                                                                                                +Number(service_fee)
                                                                                            ).toFixed(2)}
                                                                                        </>
                                                                                    )}
                                                                                </td>
                                                                                <td>
                                                                                            <UncontrolledDropdown className='align-self-start message-box-drop'>
                                                                                                <DropdownToggle
                                                                                                    className='btn-soft-secondary btn-sm dropdown'
                                                                                                    tag='button'
                                                                                                >
                                                                                                    <i className='ri-more-2-fill'></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem
                                                                                                        onClick={() => {
                                                                                                            setSessionAndIdData({
                                                                                                                uuid: item?.uuid,

                                                                                                                mark: 'PM'
                                                                                                            })
                                                                                                            setShowPmReceivedPaymentModal(true)
                                                                                                            setPaymentMethod("bank_transfer")
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className='ri-calendar-check-line me-2 text-muted align-bottom'></i>
                                                                                                        Mark as Payment Received from PM
                                                                                                    </DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                </td>
                                                                            </tr>
                                                                        );
                                                                    })
                                                                ) : (
                                                                    <tr className="text-center m-5">
                                                                        <td colSpan={18}>No data found</td>
                                                                    </tr>
                                                                )}
                                                            </tbody>
                                                            {pmInvoices.length !== 0 && (
                                                                <tfoot className="table-light">
                                                                    <tr>
                                                                        <td colSpan="5">Total</td>
                                                                        <td></td>
                                                                        <td></td>
                                                                        <td>
                                                                            <p className="ps-2">
                                                                                £{" "}
                                                                                {sessionFeePlusExpencesTotal?.toFixed(
                                                                                    2
                                                                                )}
                                                                            </p>
                                                                        </td>
                                                                        <td></td>
                                                                        <td>
                                                                            <p className="ps-0">
                                                                                £{pensionTotal?.toFixed(2)}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="ps-0">
                                                                                £{serviceFeeTotal?.toFixed(2)}
                                                                            </p>
                                                                        </td>
                                                                        <td>
                                                                            <p className="ps-0">
                                                                                £{totalOfAllAmount?.toFixed(2)}
                                                                            </p>
                                                                        </td>
                                                                        <td></td>
                                                                    </tr>
                                                                </tfoot>
                                                            )}
                                                        </>
                                                    )}

                                                    {topBorderjustifyTab === 5 && (
                                                        <>
                                                            <caption>
                                                                <Col>
                                                                    <div className='ms-3 mb-2'>
                                                                        <div className={'search-box me-2 mb-2 d-inline-block'}>
                                                                            <input
                                                                                onKeyUp={handleOnKeyUpSearchBox}
                                                                                id='search-bar-paid'
                                                                                type='text'
                                                                                className='form-control search /'
                                                                                placeholder={`Search...`}
                                                                            />
                                                                            <i className='ri-search-line search-icon'></i>
                                                                        </div>

                                                                        <div className={'search-box me-2 mb-2 d-inline-block text-center'}>
                                                                            <CSVLink
                                                                                data={pmInvoices.filter(item => item.isChecked).map(item => {

                                                                                    let expenceTotalAmt = 0

                                                                                    item?.session?.expenses?.map(
                                                                                        expns => {
                                                                                            expenceTotalAmt +=
                                                                                                +expns?.amount
                                                                                        }
                                                                                    )

                                                                                    let feeWithCharges =
                                                                                        ((item?.session?.hourly_rate /
                                                                                            60) *
                                                                                            convertTimeToMinute(
                                                                                                item?.session?.start_time,
                                                                                                item?.session?.end_time,
                                                                                                item?.session?.unpaid_break_duration
                                                                                            ) *
                                                                                            item?.session?.withdrawal_percentage) /
                                                                                        100

                                                                                    const sessionFeePlusExpences =
                                                                                        (item?.session?.hourly_rate /
                                                                                            60) *
                                                                                        convertTimeToMinute(
                                                                                            item?.session?.start_time,
                                                                                            item?.session?.end_time,
                                                                                            item?.session?.unpaid_break_duration
                                                                                        ) +
                                                                                        +expenceTotalAmt

                                                                                    // const service_fee =
                                                                                    //     ((item?.session?.hourly_rate /
                                                                                    //         60) *
                                                                                    //         convertTimeToMinute(
                                                                                    //             item?.session?.start_time,
                                                                                    //             item?.session?.end_time,
                                                                                    //             item?.session?.unpaid_break_duration
                                                                                    //         ) +
                                                                                    //         +expenceTotalAmt) *
                                                                                    //     item?.session?.organisation
                                                                                    //         ?.service_fee_percentage

                                                                                    const service_fee = item?.session?.service_fee;        

                                                                                    return {
                                                                                        "Client Name": ((item?.session?.Session_clients
                                                                                            ?.length &&
                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                ?.users
                                                                                                ?.firstName) ||
                                                                                            'NA' + " " + (item?.session?.Session_clients
                                                                                                ?.length &&
                                                                                                item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                    ?.users
                                                                                                    ?.lastName) ||
                                                                                            'NA'),

                                                                                        "Organisation": item?.session?.organisation?.name,
                                                                                        "Date & Time": `${moment(item?.session?.start_time).format('YYYY-MM-DD HH:mm:ss')} - ${moment(item?.session?.end_time).format('YYYY-MM-DD HH:mm:ss')} - ` || 'NA',
                                                                                        "Status": item?.session?.status,
                                                                                        "Invoice Date": moment(item?.createdAt).format('MMMM Do YYYY') || "NA",
                                                                                        "Fee + Expenses": item?.session?.status === 'withdrawn' ? (
                                                                                            ((item?.session?.hourly_rate /
                                                                                                60) *
                                                                                                convertTimeToMinute(
                                                                                                    item?.session?.start_time,
                                                                                                    item?.session?.end_time,
                                                                                                    item?.session?.unpaid_break_duration
                                                                                                ) *
                                                                                                item?.session?.withdrawal_percentage) /
                                                                                            100
                                                                                        )?.toFixed(
                                                                                            2
                                                                                        ) : (sessionFeePlusExpences).toFixed(2),


                                                                                        "Bonus": item?.session?.client_bonus_amount,

                                                                                        "Pension": item?.session?.pensionable === 0 ? "0" : calculatePensionablePay(
                                                                                            (item?.session?.hourly_rate /
                                                                                                60) *
                                                                                            convertTimeToMinute(
                                                                                                item?.session?.start_time,
                                                                                                item?.session?.end_time,
                                                                                                item?.session?.unpaid_break_duration
                                                                                            ),
                                                                                            expenceTotalAmt
                                                                                        ),

                                                                                        "Service Fee": service_fee,

                                                                                        "Client Total": item?.session?.status === 'withdrawn' ? (
                                                                                            feeWithCharges +
                                                                                            +service_fee
                                                                                        ).toFixed(
                                                                                            1
                                                                                        ) : (
                                                                                            sessionFeePlusExpences +
                                                                                            (item?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                (item?.session?.hourly_rate /
                                                                                                    60) *
                                                                                                convertTimeToMinute(
                                                                                                    item?.session?.start_time,
                                                                                                    item?.session?.end_time,
                                                                                                    item?.session?.unpaid_break_duration
                                                                                                ),
                                                                                                expenceTotalAmt
                                                                                            ) : 0) +
                                                                                            +(item?.session?.client_bonus_amount)
                                                                                        ).toFixed(
                                                                                            1
                                                                                        ),


                                                                                        "Total": item?.session?.status ===
                                                                                            'withdrawn' ? (
                                                                                                feeWithCharges +
                                                                                                +service_fee
                                                                                            ).toFixed(
                                                                                                2
                                                                                            ) : (
                                                                                                sessionFeePlusExpences +
                                                                                                (item?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                    (item?.session?.hourly_rate /
                                                                                                        60) *
                                                                                                    convertTimeToMinute(
                                                                                                        item?.session?.start_time,
                                                                                                        item?.session?.end_time,
                                                                                                        item?.session?.unpaid_break_duration
                                                                                                    ),
                                                                                                    expenceTotalAmt
                                                                                                ) : 0) +
                                                                                                +(Number(service_fee))
                                                                                            ).toFixed(
                                                                                                2
                                                                                            ),

                                                                                        "Pm Payment Received date": item?.pm_payment_received_date ? moment(item?.pm_payment_received_date).format('MMMM Do YYYY') : "NA",
                                                                                        "Reference Number": item?.reference_number,
                                                                                    }

                                                                                })}
                                                                                filename={`file.csv`}
                                                                                target='_blank'
                                                                            >
                                                                                <Button
                                                                                    className='btn-icon'
                                                                                    style={{ width: 180 }}
                                                                                >
                                                                                    Export To CSV
                                                                                </Button>
                                                                            </CSVLink>
                                                                        </div>
                                                                    </div>
                                                                </Col>
                                                            </caption>

                                                                        <thead className='table-light'>
                                                                            <tr>
                                                                                <th
                                                                                    scope='col'
                                                                                    style={{
                                                                                        width: '42px'
                                                                                    }}
                                                                                >
                                                                                    <div className='form-check'>
                                                                                        <Input
                                                                                            className='form-check-input'
                                                                                            type='checkbox'
                                                                                            defaultValue=''
                                                                                            id='responsivetableCheck'
                                                                                            checked={
                                                                                                !(pmInvoices?.map((item) => {
                                                                                                    // return item?.pm_invoices_intermediates?.map((item2)=>{
                                                                                                    return item.isChecked;
                                                                                                    // })
                                                                                                }).flat(1).includes(false))
                                                                                            }
                                                                                            onChange={e =>
                                                                                                handleCheckedAllClick(
                                                                                                    e
                                                                                                )
                                                                                            }
                                                                                        />
                                                                                        <Label
                                                                                            className='form-check-label'
                                                                                            for='responsivetableCheck'
                                                                                        ></Label>
                                                                                    </div>
                                                                                </th>
                                                                                <th scope='col'>ID</th>
                                                                                <th scope='col'>
                                                                                    Client
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Organisation
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Date & Time
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Status
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Invoice Date
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Pm Payment Received date
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Fee + Expenses
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Bonus
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Pension
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Service Fee
                                                                                </th>
                                                                                <th scope='col'>
                                                                                    Client Total
                                                                                </th>
                                                                                <th scope='col'>Total</th>
                                                                                <th scope='col'>Reference Number</th>
                                                                                <th scope='col'>Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {pmInvoices.length !== 0 ? pmInvoices?.map((item, idx) => {

                                                                                let expenceTotalAmt = 0

                                                                                item?.session?.expenses?.map(
                                                                                    expns => {
                                                                                        expenceTotalAmt +=
                                                                                            +expns?.amount
                                                                                    }
                                                                                )

                                                                                let feeWithCharges =
                                                                                    ((item?.session?.hourly_rate /
                                                                                        60) *
                                                                                        convertTimeToMinute(
                                                                                            item?.session?.start_time,
                                                                                            item?.session?.end_time,
                                                                                            item?.session?.unpaid_break_duration
                                                                                        ) *
                                                                                        item?.session?.withdrawal_percentage) /
                                                                                    100

                                                                                const sessionFeePlusExpences =
                                                                                    (item?.session?.hourly_rate /
                                                                                        60) *
                                                                                    convertTimeToMinute(
                                                                                        item?.session?.start_time,
                                                                                        item?.session?.end_time,
                                                                                        item?.session?.unpaid_break_duration
                                                                                    ) +
                                                                                    +expenceTotalAmt

                                                                                // const service_fee =
                                                                                //     ((item?.session?.hourly_rate /
                                                                                //         60) *
                                                                                //         convertTimeToMinute(
                                                                                //             item?.session?.start_time,
                                                                                //             item?.session?.end_time,
                                                                                //             item?.session?.unpaid_break_duration
                                                                                //         ) +
                                                                                //         +expenceTotalAmt) *
                                                                                //     item?.session?.organisation
                                                                                //         ?.service_fee_percentage

                                                                                const service_fee = item?.session?.service_fee;

                                                                                return (
                                                                                    <tr
                                                                                        key={
                                                                                            idx
                                                                                        }
                                                                                    >
                                                                                        <th scope='row'>
                                                                                            <div className='form-check'>
                                                                                                <Input
                                                                                                    className='form-check-input'
                                                                                                    type='checkbox'
                                                                                                    checked={
                                                                                                        item?.isChecked
                                                                                                    }
                                                                                                    onChange={e =>
                                                                                                        handleCheckBoxChange(
                                                                                                            e,
                                                                                                            idx
                                                                                                        )
                                                                                                    }
                                                                                                    id='responsivetableCheck01'
                                                                                                />
                                                                                                <Label
                                                                                                    className='form-check-label'
                                                                                                    htmlFor='responsivetableCheck01'
                                                                                                ></Label>
                                                                                            </div>
                                                                                        </th>
                                                                                        <td>
                                                                                            <Link
                                                                                                to='#'
                                                                                                className='fw-medium'
                                                                                            >
                                                                                                #
                                                                                                {item?.session?.uuid?.substring(
                                                                                                    0,
                                                                                                    5
                                                                                                ) || 'NA'}
                                                                                            </Link>
                                                                                        </td>
                                                                                        <td className='cursor'>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='fs-14 mb-1 cursor' onClick={() => history.push(`/client-list?id=${item?.session?.Session_clients?.find((item) => item?.active === 1)?.users?.uuid}`)}>
                                                                                                        {(item?.session?.Session_clients
                                                                                                            ?.length &&
                                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                ?.users
                                                                                                                ?.firstName) ||
                                                                                                            'NA'}{' '}
                                                                                                        {(item?.session?.Session_clients
                                                                                                            ?.length &&
                                                                                                            item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                ?.users
                                                                                                                ?.lastName) ||
                                                                                                            'NA'}
                                                                                                    </h5>
                                                                                                    <p className='text-muted mb-0'>
                                                                                                        <span className='fw-medium'> GMC - {item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                            ?.users?.User_profile?.regulator_number || "N/A"}</span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='fs-14 mb-1 cursor' onClick={() => history.push(`/organisations?id=${item?.session?.organisation?.uuid}`)}>
                                                                                                        {item?.session?.organisation
                                                                                                            ?.name ||
                                                                                                            'NA'}
                                                                                                    </h5>
                                                                                                    <p className='text-muted mb-0'>
                                                                                                        <span className='fw-medium'>
                                                                                                            {item?.session?.organisation
                                                                                                                ?.postcode || 'NA'}
                                                                                                            {' - '}
                                                                                                            {item?.session?.organisation
                                                                                                                ?.organisation_code || 'NA'}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='fs-14 mb-1'>
                                                                                                        {`${moment(
                                                                                                            item?.session?.start_time
                                                                                                        ).format(
                                                                                                            'dddd, MMMM Do YYYY'
                                                                                                        )}` || 'NA'}
                                                                                                    </h5>
                                                                                                    <p className='text-muted mb-0'>
                                                                                                        <span className='fw-medium'>
                                                                                                            {`${moment(
                                                                                                                item?.session?.start_time
                                                                                                            ).format(
                                                                                                                'LT'
                                                                                                            )}` ||
                                                                                                                'NA'}{' '}
                                                                                                            -{' '}
                                                                                                            {`${moment(
                                                                                                                item?.session?.end_time
                                                                                                            ).format(
                                                                                                                'LT'
                                                                                                            )}` ||
                                                                                                                'NA'}{' '}
                                                                                                            
                                                                                                            ({item?.session?.unpaid_break_duration !== 0 ? `${item?.session?.unpaid_break_duration} mins break` : "No Break"})
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <Badge
                                                                                                color={badgeColor(
                                                                                                    item?.session?.status ||
                                                                                                    'NA'
                                                                                                )}
                                                                                                className='ms-auto'
                                                                                            >
                                                                                                {item?.session?.status ||
                                                                                                    'NA'}
                                                                                            </Badge>
                                                                                        </td>
                                                                                        <td>
                                                                                            <h5 className='fs-14 mb-1'>
                                                                                                {moment(item?.createdAt).format('MMMM Do YYYY')}
                                                                                            </h5>
                                                                                        </td>
                                                                                        <td>
                                                                                            <h5 className='fs-14 mb-1'>
                                                                                                {item?.pm_payment_received_date ? moment(item?.pm_payment_received_date).format('YYYY-MM-DD') : null}
                                                                                            </h5>
                                                                                        </td>

                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='mb-1 text-primary fw-semibold '>
                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' ? (
                                                                                                            <>
                                                                                                                £{' '}
                                                                                                                {(
                                                                                                                    ((item?.session?.hourly_rate /
                                                                                                                        60) *
                                                                                                                        convertTimeToMinute(
                                                                                                                            item?.session?.start_time,
                                                                                                                            item?.session?.end_time,
                                                                                                                            item?.session?.unpaid_break_duration
                                                                                                                        ) *
                                                                                                                        item?.session?.withdrawal_percentage) /
                                                                                                                    100
                                                                                                                )?.toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                £{' '}
                                                                                                                {sessionFeePlusExpences.toFixed(
                                                                                                                    2
                                                                                                                ) ||
                                                                                                                    'NA'}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </h5>
                                                                                                    <p className='text-muted mb-0'>
                                                                                                        <span
                                                                                                            className={`fw-medium ${item?.session?.status ===
                                                                                                                'withdrawn' &&
                                                                                                                'text-decoration-line-through'
                                                                                                                } `}
                                                                                                        >
                                                                                                            £
                                                                                                            {(
                                                                                                                (item?.session?.hourly_rate /
                                                                                                                    60) *
                                                                                                                convertTimeToMinute(
                                                                                                                    item?.session?.start_time,
                                                                                                                    item?.session?.end_time,
                                                                                                                    item?.session?.unpaid_break_duration
                                                                                                                )
                                                                                                            ).toFixed() ||
                                                                                                                'NA'}{' '}
                                                                                                            (£
                                                                                                            {item?.session?.hourly_rate ||
                                                                                                                'NA'}{' '}
                                                                                                            /hour) + £{' '}
                                                                                                            {expenceTotalAmt?.toFixed(
                                                                                                                2
                                                                                                            )}
                                                                                                            {item?.session?.pensionable ===
                                                                                                                1 ? (
                                                                                                                <i
                                                                                                                    title='Pensionable'
                                                                                                                    className='ms-1 ri-file-ppt-line'
                                                                                                                ></i>
                                                                                                            ) : (
                                                                                                                ''
                                                                                                            )}
                                                                                                        </span>
                                                                                                    </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='mb-1 text-primary fw-semibold '>
                                                                                                        £{' '}
                                                                                                        {item?.session?.client_bonus_amount || ''}
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='mb-1 text-primary fw-semibold '>
                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' || item?.session?.pensionable === 0 ? (
                                                                                                            '£ 0'
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                {'£ '}
                                                                                                                {calculatePensionablePay(
                                                                                                                    (item?.session?.hourly_rate /
                                                                                                                        60) *
                                                                                                                    convertTimeToMinute(
                                                                                                                        item?.session?.start_time,
                                                                                                                        item?.session?.end_time,
                                                                                                                        item?.session?.unpaid_break_duration
                                                                                                                    ),
                                                                                                                    expenceTotalAmt
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='mb-1 text-primary fw-semibold '>
                                                                                                        £{' '}
                                                                                                        {/* {service_fee.toFixed(
                                                                                                            2
                                                                                                        ) || ''} */}
                                                                                                        { service_fee }
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='mb-1 text-primary fw-semibold '>
                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' ? (
                                                                                                            <>
                                                                                                                £{' '}
                                                                                                                {(
                                                                                                                    feeWithCharges +
                                                                                                                    +service_fee
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                £{' '}
                                                                                                                {(
                                                                                                                    sessionFeePlusExpences +
                                                                                                                    (item?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                                        (item?.session?.hourly_rate /
                                                                                                                            60) *
                                                                                                                        convertTimeToMinute(
                                                                                                                            item?.session?.start_time,
                                                                                                                            item?.session?.end_time,
                                                                                                                            item?.session?.unpaid_break_duration
                                                                                                                        ),
                                                                                                                        expenceTotalAmt
                                                                                                                    ) : 0) +
                                                                                                                        +(item?.session?.client_bonus_amount)
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <div className='d-flex align-items-center'>
                                                                                                <div className='flex-grow-1'>
                                                                                                    <h5 className='mb-1 text-primary fw-semibold '>
                                                                                                        {item?.session?.status ===
                                                                                                            'withdrawn' ? (
                                                                                                            <>
                                                                                                                £{' '}
                                                                                                                {(
                                                                                                                    feeWithCharges +
                                                                                                                    +service_fee
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        ) : (
                                                                                                            <>
                                                                                                                £{' '}
                                                                                                                {(
                                                                                                                    sessionFeePlusExpences +
                                                                                                                    (item?.session?.pensionable === 1 ? +calculatePensionablePay(
                                                                                                                        (item?.session?.hourly_rate /
                                                                                                                            60) *
                                                                                                                        convertTimeToMinute(
                                                                                                                            item?.session?.start_time,
                                                                                                                            item?.session?.end_time,
                                                                                                                            item?.session?.unpaid_break_duration
                                                                                                                        ),
                                                                                                                        expenceTotalAmt
                                                                                                                    ) : 0) +
                                                                                                                    +(Number(service_fee))
                                                                                                                ).toFixed(
                                                                                                                    2
                                                                                                                )}
                                                                                                            </>
                                                                                                        )}
                                                                                                    </h5>
                                                                                                </div>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td>
                                                                                            <h5 className='fs-14 mb-1'>
                                                                                                {item?.reference_number}
                                                                                            </h5>
                                                                                        </td>
                                                                                        <td>
                                                                                            <UncontrolledDropdown className='align-self-start message-box-drop'>
                                                                                                <DropdownToggle
                                                                                                    className='btn-soft-secondary btn-sm dropdown'
                                                                                                    tag='button'
                                                                                                >
                                                                                                    <i className='ri-more-2-fill'></i>
                                                                                                </DropdownToggle>
                                                                                                <DropdownMenu>
                                                                                                    <DropdownItem
                                                                                                        disabled={!(item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                            ?.users
                                                                                                            ?.uuid)}
                                                                                                        onClick={() =>
                                                                                                            fetchBankDetails(
                                                                                                                item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                    ?.users
                                                                                                                    ?.uuid,
                                                                                                                token
                                                                                                            )
                                                                                                        }
                                                                                                    >
                                                                                                        <i className='ri-bank-line me-2 text-muted align-bottom'></i>
                                                                                                        Show Bank
                                                                                                        Details
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                        disabled={!((item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                            ?.users
                                                                                                            ?.signature
                                                                                                            && item?.session?.Session_clients?.find((item) => item?.active === 1)
                                                                                                                ?.users
                                                                                                                ?.pension_client[0]?.claim_pension))}

                                                                                                        onClick={() => {
                                                                                                            if (item?.session?.form_a[0] && item?.session?.uuid && item?.session?.user_id) {
                                                                                                                setFormAuuid({
                                                                                                                    form_a_id: item?.session?.uuid ? item?.session?.uuid : "",
                                                                                                                    userId: item?.session?.user_id
                                                                                                                });
                                                                                                                setShowSignFormaModal(true);
                                                                                                            } else {
                                                                                                                toast.info('form-A not found')
                                                                                                            }
                                                                                                        }
                                                                                                        }
                                                                                                    >
                                                                                                        <i className='ri-sketching me-2 text-muted align-bottom'></i>
                                                                                                        Sign Pension Form A
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                        onClick={() => {
                                                                                                            setSessionAndIdData({
                                                                                                                uuid: item?.uuid,
                                                                                                                mark: 'CLIENT'
                                                                                                            })
                                                                                                            setShowPaidModal(true)
                                                                                                            setPaymentMethod("direct_debit")
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className='ri-file-list-3-fill me-2 text-muted align-bottom'></i>
                                                                                                        Mark Client As Paid
                                                                                                    </DropdownItem>
                                                                                                    <DropdownItem
                                                                                                        onClick={() => {
                                                                                                            setSessionAndIdData({
                                                                                                                uuid: item?.uuid,
                                                                                                                mark: 'PM'
                                                                                                            })
                                                                                                            setShowPaidModal(true)
                                                                                                            setPaymentMethod("direct_debit")
                                                                                                        }}
                                                                                                    >
                                                                                                        <i className='ri-file-list-3-fill me-2 text-muted align-bottom'></i>
                                                                                                        Mark as Payment Received from PM
                                                                                                    </DropdownItem>
                                                                                                </DropdownMenu>
                                                                                            </UncontrolledDropdown>
                                                                                        </td>
                                                                                    </tr>
                                                                                )
                                                                            })
                                                                                :
                                                                                <tr className='text-center m-5'>
                                                                                    <td colSpan={18}>No data found</td>
                                                                                </tr>

                                                                            }
                                                                        </tbody>
                                                                        {pmInvoices.length !== 0 && <tfoot className='table-light'>
                                                                            <tr>
                                                                                <td colSpan='7'>Total</td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <p className='ps-2'>
                                                                                        £
                                                                                        {sessionFeePlusExpencesTotal?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <p className='ps-0'>
                                                                                        £
                                                                                        {pensionTotal?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                    <p className='ps-0'>
                                                                                        £
                                                                                        {serviceFeeTotal?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td></td>
                                                                                <td>
                                                                                    <p className='ps-0'>
                                                                                        £
                                                                                        {totalOfAllAmount?.toFixed(
                                                                                            2
                                                                                        )}
                                                                                    </p>
                                                                                </td>
                                                                                <td>
                                                                                </td>
                                                                                <td>
                                                                                </td>
                                                                            </tr>
                                                                        </tfoot>}
                                                        </>
                                                    )}
                                                </Table>
                                            </div>

                                            <div className='p-2'></div>
                                            <PaginationV2
                                                totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                                                previousDisabled={page === 1}
                                                nextDisabled={totalPageCount === page ? true : false}
                                                previousPageCall={() => setPage(page - 1)}
                                                currentPage={page}
                                                nextPageCalls={() => setPage(page + 1)}
                                                onPageInputChange={({ target: { value } }) => {
                                                    var regex = new RegExp(`^[1-${totalPageCount}]$`)
                                                    if (value <= totalPageCount && value !== 0) {
                                                        setPage(value)
                                                    }
                                                }}
                                            />
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                                {/* </Card> */}
                                <BankDetailsModal
                                    open={showBankDetailsModal}
                                    bankDetails={bankDetails}
                                    toggle={() =>
                                        setShowBankDetailsModal(!showBankDetailsModal)
                                    }
                                />
                                <SuccessConfirmationModal
                                    show={showPaidModal}
                                    enableDatePicker={sessionAndIdData.mark === 'CLIENT' ? false : true}
                                    dateChange={(e) => {
                                        setDateValue(e.target.value)
                                    }}
                                    dateValue={dateValue}
                                    onDeleteClick={() => {
                                        if (sessionAndIdData.mark === 'CLIENT') {
                                            fetchMarkAsPaid(
                                                sessionAndIdData.uuid,
                                                token
                                            )
                                        } else {
                                            fetchMarkAsPMPaymnetReceived(
                                                sessionAndIdData.uuid,
                                                {
                                                    dateValue: moment(dateValue).format("YYYY-MM-DD"),
                                                    paymentMethod: paymentMethod
                                                },
                                                token
                                            )
                                            if (formAuuid && Object.keys(formAuuid).length !== 0) {
                                                signFormA(formAuuid, token);
                                            }
                                        }
                                        setShowPaidModal(false)
                                    }}
                                    onCloseClick={() => setShowPaidModal(false)}
                                    deleteMsg={sessionAndIdData.mark === 'CLIENT' ? "Mark client as Paid?" : "Mark payment as recieved from ORG?"}
                                    btnName={sessionAndIdData.mark === 'CLIENT' ? "Confirm" : "Confirm "}
                                />

                                <SuccessConfirmationModal
                                    show={showSignFormaModal}
                                    onDeleteClick={() => {
                                        signFormA(formAuuid, token)
                                        setShowSignFormaModal(false)
                                    }}
                                    onCloseClick={() => setShowSignFormaModal(false)}
                                    deleteMsg={"Do you want to Sign Form A!"}
                                    btnName={"Sign Form A!"}
                                />
                                <SuccessConfirmationModal
                                    show={showMakeClientEarlyPaidModal}
                                    onDeleteClick={() => {
                                        makeEarlyPaymentToClient(sessionAndIdData.uuid, token)
                                        setShowMakeClientEarlyPaidModal(false)
                                        if (formAuuid && Object.keys(formAuuid).length !== 0) {
                                            signFormA(formAuuid, token);
                                        }
                                    }}
                                    onCloseClick={() => setShowMakeClientEarlyPaidModal(false)}
                                    deleteMsg={"Do want to make early payment"}
                                    btnName={"Pay Early"}
                                />
                                <SuccessConfirmationModal
                                    show={showPmReceivedPaymentModal}
                                    enableDatePicker={sessionAndIdData.mark === 'CLIENT' ? false : true}
                                    dateChange={(e) => {
                                        setDateValue(e.target.value)
                                    }}
                                    onDeleteClick={() => {
                                        fetchMarkAsPMPaymnetReceivedPrePaidClient(
                                            sessionAndIdData.uuid,
                                            {
                                                dateValue: moment(dateValue).format("YYYY-MM-DD"),
                                                paymentMethod: paymentMethod
                                            },
                                            token
                                        )
                                        setShowPmReceivedPaymentModal(false)
                                    }}
                                    onCloseClick={() => setShowPmReceivedPaymentModal(false)}
                                    deleteMsg={"Mark payment as recieved from ORG?"}
                                    btnName={"Mark Payment Received"}
                                />

                                {/* </Col> */}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PmInvoices
