import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'

import { Card, Container, Row, Col, Label, Input } from 'reactstrap'

import BreadCrumb from '../../Components/Common/BreadCrumb'

import avatar1 from '../../assets/images/users/avatar-1.jpg'
import moment from 'moment'
import { toast } from 'react-toastify'
import { organisationServices } from '../../services/organisationServices'
import { useLocation } from 'react-router-dom'

const StaffList = () => {
  const [staffList, setStaffList] = useState([])
  const [showStaffDetailsModal, setShowStaffDetailsModal] = useState(false)
  const [selectedUserId, setSelectedUserId] = useState('')

  const history = useHistory()

  const token = localStorage.getItem('token')

  let orgId = new URLSearchParams(useLocation().search).get('orgId')
  console.log('🚀 ~ file: StaffList.js:25 ~ StaffList ~ orgId:', orgId)

  useEffect(() => {
    fetchStaffList(orgId, token)
  }, [orgId])

  const fetchStaffList = (orgId, token) => {
    organisationServices
      .getOrganisationsStaffList(orgId, token)
      .then(resp => {
        if (resp.success) {
          const checkedAddedArray = resp?.data?.map((item, idx) => {
            return {
              ...item,
              ...(item?.Instant_bookings && {
                instantBookingChecked: item?.Instant_bookings[0]?.active
                  ? true
                  : false
              }),
              ...(item?.Banned_users && {
                bannedUserCheck: item?.Banned_users[0]?.active ? true : false
              })
            }
          })
          setStaffList(checkedAddedArray)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchPatchInstantBooking = (userId, orgID, instantBooking) => {
    return
    // const data = {
    //   user_id: userId,
    //   org_id: orgID,
    //   instantBooking: instantBooking
    // }

    // scheduleServices
    //   .patchInstantBooking(data, token)
    //   .then(resp => {
    //     if (resp.success) {
    //       toast.success(resp.message)
    //       fetchStaffList(orgId, token)
    //     } else {
    //       toast.error(resp.message)
    //     }
    //   })
    //   .catch(err => console.log(err))
  }

  const fetchPatchBanUser = (userId, orgID, bannedUser) => {
    return
    // const data = {
    //   user_id: userId,
    //   org_id: orgID,
    //   bannedUser: bannedUser
    // }

    // scheduleServices
    //   .patchBannUser(data, token)
    //   .then(resp => {
    //     if (resp.success) {
    //       toast.success(resp.message)
    //       fetchStaffList(orgId, token)
    //     } else {
    //       toast.error(resp.message)
    //     }
    //   })
    //   .catch(err => console.log(err))
  }
  document.title = 'Staff List | airGP'
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <BreadCrumb title='Staff List' pageTitle='Base UI' />
          <Row>
            <Col className='col-12'>
              <Row className='row-cols-xxl-3 row-cols-lg-4 row-cols-1'>
                {staffList?.length !== 0 ? (
                  staffList?.map((item, idx) => {
                    console.log(item)
                    return (
                      <Col key={idx}>
                        <Card className='card-body p-4'>
                          <div className='d-flex mb-4 align-items-center'>
                            <div className='flex-shrink-0'>
                              <img
                                src={
                                  item.User_profile.profile_photo_link ||
                                  avatar1
                                }
                                alt=''
                                className='avatar-sm rounded-circle'
                              />
                            </div>
                            <div className='flex-grow-1 ms-2'>
                              <h5 className='card-title mb-1'>
                                {`${item.firstName} ${item.lastName}`}{' '}
                                {/* <button
                                  type='button'
                                  className='btn btn-success btn-sm m-3'
                                  onClick={() =>
                                    history.push(
                                      `/PM/apps-chat?id=${item?.uuid}`
                                    )
                                  }
                                >
                                  <i className='ri-chat-1-fill align-bottom me-1'></i>
                                  Chat
                                </button> */}
                              </h5>{' '}
                              <p className='text-muted mb-0'>
                                {item?.user_type?.name}
                              </p>
                            </div>
                          </div>
                          <div className='d-flex justify-content-between'>
                            <h5 className='card-title mb-1'>
                              Session Worked :{' '}
                              <span className='text-muted mb-0'>
                                {item?.count}
                              </span>
                            </h5>
                            <div className='border-dashed border'></div>

                            <h5 className='card-title mb-1'>
                              Year Registered :{' '}
                              <span className='text-muted mb-0'>
                                {moment().diff(item.createdAt, 'years')}
                              </span>
                            </h5>
                          </div>
                          <hr></hr>
                          <div className='form-check mb-2'>
                            <div className='mb-2'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                id={`instant-booking-${idx}`}
                                checked={item.instantBookingChecked}
                                readOnly
                                // onChange={event => {
                                //   if (staffList) {
                                //     const copiedArray = [...staffList]
                                //     if (
                                //       copiedArray[idx]?.Banned_users[0]
                                //         ?.active === 0
                                //     ) {
                                //       copiedArray[idx].instantBookingChecked =
                                //         event.target.checked
                                //       setStaffList(copiedArray)
                                //     }
                                //     fetchPatchInstantBooking(
                                //       item.uuid,
                                //       orgId,
                                //       event.target.checked
                                //     )
                                //   }
                                // }}
                              />
                              <Label
                                className='form-check-label'
                                htmlFor={`instant-booking-${idx}`}
                              >
                                Instant Booking
                              </Label>
                            </div>
                            <div key={idx} className='mb-2'>
                              <Input
                                className='form-check-input'
                                type='checkbox'
                                id={`banned-user-${idx}`}
                                checked={item.bannedUserCheck}
                                readOnly
                                // onChange={event => {
                                //   const copiedArray = [...staffList]
                                //   copiedArray[idx].bannedUserCheck =
                                //     event.target.checked
                                //   fetchPatchBanUser(
                                //     item.uuid,
                                //     orgId,
                                //     event.target.checked
                                //   )
                                // }}
                              />
                              <Label
                                className='form-check-label'
                                htmlFor={`banned-user-${idx}`}
                              >
                                Banned
                              </Label>
                            </div>
                          </div>

                          <Link
                            to='#'
                            className='btn btn-primary btn-sm fs-15 mt-4'
                            onClick={() => {
                              setShowStaffDetailsModal(true)
                              setSelectedUserId(item?.uuid)
                            }}
                          >
                            See Details
                          </Link>
                        </Card>
                      </Col>
                    )
                  })
                ) : (
                  <>
                    <div className='text-center card-body page-content no-staff-found'>
                      <h3>No Staff Found</h3>
                    </div>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default StaffList
