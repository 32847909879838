import PropTypes from 'prop-types'
import React from 'react'
import {
  Row,
  Col,
  Alert,
  Card,
  CardBody,
  Container,
  FormFeedback,
  Input,
  Label,
  Form
} from 'reactstrap'

//redux
import { useSelector, useDispatch } from 'react-redux'

import { withRouter, Link } from 'react-router-dom'

// Formik Validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

// action
import { userForgetPassword } from '../../store/actions'

import ParticlesAuth from './ParticlesAuth'
import { authServices } from '../../services/authServices'
import { toast, ToastContainer } from 'react-toastify'
import { useHistory } from 'react-router-dom'
import logoLight from '../../assets/images/logo-light.png'


const ForgetPasswordPage = props => {
  const history = useHistory()
  const dispatch = useDispatch()

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email')
    }),
    onSubmit: values => {
      authServices
        .postForgotPasswordSuperAdmin(values)
        .then(resp => {
          if (resp.success) {
            toast.success(resp.message)
            history.push('success')
          } else {
            toast.error(resp.message)
          }
        })
        .catch(err => console.log(err))
      dispatch(userForgetPassword(values, props.history))
    }
  })

  const { forgetError, forgetSuccessMsg } = useSelector(state => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg
  }))

  document.title = 'Reset Password | airGP'
  return (
    <ParticlesAuth>
      <div className='auth-page-content'>
        <Container>
          <Row>
            <Col lg={12}>
              <div className='text-center mt-sm-5 mb-4 text-white-50'>
                <div>
                  <Link to='/' className='d-inline-block auth-logo'>
                  <span className='airgp-logo'>
                        <img src={logoLight} alt="" height="40" />
                      </span>
                  </Link>
                </div>
                <p className='mt-3 fs-15 fw-medium'>Admin Dashboard</p>
              </div>
            </Col>
          </Row>

          <Row className='justify-content-center'>
            <Col md={8} lg={6} xl={5}>
              <Card className='mt-4'>
                <CardBody className='p-4'>
                  <div className='text-center mt-2'>
                    <h5 className='text-primary'>Forgot Password?</h5>
                    {/* <p className='text-muted'>Reset password with AirGP</p> */}

                    {/* <lord-icon
                      src='https://cdn.lordicon.com/rhvddzym.json'
                      trigger='loop'
                      colors='primary:#0ab39c'
                      className='avatar-xl'
                      style={{ width: '120px', height: '120px' }}
                    ></lord-icon> */}
                  </div>

                  <Alert
                    className='alert-borderless alert-warning text-center mb-2 mx-2'
                    role='alert'
                  >
                    Enter your email and instructions will be sent to you!
                  </Alert>
                  <div className='p-2'>
                    {forgetError && forgetError ? (
                      <Alert color='danger' style={{ marginTop: '13px' }}>
                        {forgetError}
                      </Alert>
                    ) : null}
                    {forgetSuccessMsg ? (
                      <Alert color='success' style={{ marginTop: '13px' }}>
                        {forgetSuccessMsg}
                      </Alert>
                    ) : null}
                    <Form
                      onSubmit={e => {
                        e.preventDefault()
                        validation.handleSubmit()
                        return false
                      }}
                    >
                      <div className='mb-4'>
                        <Label className='form-label'>Email</Label>
                        <Input
                          name='email'
                          className='form-control'
                          placeholder='Enter email'
                          type='email'
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          value={validation.values.email || ''}
                          invalid={
                            validation.touched.email && validation.errors.email
                              ? true
                              : false
                          }
                        />
                        {validation.touched.email && validation.errors.email ? (
                          <FormFeedback type='invalid'>
                            <div>{validation.errors.email}</div>
                          </FormFeedback>
                        ) : null}
                      </div>

                      <div className='text-center mt-4'>
                        <button className='btn btn-success w-100' type='submit'>
                          Send Reset Link
                        </button>
                      </div>
                    </Form>
                  </div>
                </CardBody>
              </Card>

              <div className='mt-4 text-center'>
                <p className='mb-0'>
                  Wait, I remember my password...{' '}
                  <Link
                    to='/login'
                    className='fw-semibold text-primary text-decoration-underline'
                  >
                    {' '}
                    Click here{' '}
                  </Link>{' '}
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
    </ParticlesAuth>
  )
}

ForgetPasswordPage.propTypes = {
  history: PropTypes.object
}

export default withRouter(ForgetPasswordPage)
