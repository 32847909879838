import { toast } from 'react-toastify'


const fetchPDF = async (response, originalFileName, openInNewTab = true) => {
    try {
        if (response.ok) {
            const blob = await response.blob();

            // const pdfURL = URL.createObjectURL(blob);
            // window.open(pdfURL, '_blank');
            const contentType = response.headers.get('content-type');
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            if (contentType && contentType.startsWith('image/')) {
                link.setAttribute('download', originalFileName || "file");
            }
            else {
                link.setAttribute('download', originalFileName || "file.pdf");
            }

            if (openInNewTab) {
                window.open(url, '_blank');
                URL.revokeObjectURL(url);
            } else {
                document.body.appendChild(link);
                link.click();
            }

        } else {
            toast.error('Error fetching PDF:', response.statusText);
        }
    } catch (error) {
        toast.error('Error fetching PDF:', error);
    }
};

export default fetchPDF