import moment from 'moment/moment'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Select from 'react-select'
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table,
  UncontrolledCollapse
} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import InvitaionModal from '../../Components/Common/InvitaionModal'
import PaginationV2 from '../../Components/Common/PaginationV2'
import SpinnerComponent from '../../Components/Common/Spinner'
import UiContent from '../../Components/Common/UiContent'
import { clientServices } from '../../services/clientServices'
import { PerformerRole, PerformerStatus } from '../../utils/constants'

let LIMIT = 25
const GPInvitation = () => {
  const [performerRole, setPerformerRole] = useState(PerformerRole)
  const [status, setStatus] = useState(PerformerStatus)
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [searchBox, setSearchBox] = useState('')
  const [performerList, setPerformerList] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [proPeriod, setProPeriod] = useState(false)
  const [showInvitaionModal, setInvitationModal] = useState(false)
  const [selectedPerformer, setSelectedPerformer] = useState()
  const [page, setPage] = useState(1)

  const token = localStorage.getItem('token')

  useEffect(() => {
    const getData = setTimeout(() => {
      const selectedPerformer = performerRole
        .filter(item => item.checked)
        .map(item => item.type)

      const selectedStatus = status
        .filter(item => item.checked)
        .map(item => item.type)

      fetchPerformarList(
        token,
        searchBox,
        selectedPerformer.toString(),
        selectedStatus.toString(),
        proPeriod,
        page,
        LIMIT
      )
    }, 300)

    return () => clearTimeout(getData)
  }, [searchBox, page])

  useEffect(() => {
    const selectedPerformer = performerRole
      .filter(item => item.checked)
      .map(item => item.type)

    const selectedStatus = status
      .filter(item => item.checked)
      .map(item => item.type)

    fetchPerformarList(
      token,
      searchBox,
      selectedPerformer.toString(),
      selectedStatus.toString(),
      proPeriod,
      page,
      LIMIT
    )
  }, [page])

  const fetchPerformarList = (
    token,
    prefix,
    performer_role,
    status,
    probationary_period,
    page,
    limit
  ) => {
    setIsLoading(true)
    clientServices
      .getPerformerList(
        token,
        prefix,
        performer_role,
        status,
        probationary_period,
        page,
        limit
      )
      .then(resp => {
        if (resp.success) {
          setPerformerList(resp.data)
          setTotalPageCount(resp.totalPages)
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const handlePerformerRoleChange = (e, idx) => {
    let copiedRole = [...performerRole]
    copiedRole[idx].checked = e.target.checked
    setPerformerRole(copiedRole)

    const selectedPerformer = copiedRole
      .filter(item => item.checked)
      .map(item => item.type)

    const selectedStatus = status
      .filter(item => item.checked)
      .map(item => item.type)

    setPage(1)
    fetchPerformarList(
      token,
      searchBox,
      selectedPerformer.toString(),
      selectedStatus.toString(),
      proPeriod,
      1,
      LIMIT
    )
  }

  const handleStatusChange = (e, idx) => {
    let copiedStatus = [...status]
    copiedStatus[idx].checked = e.target.checked
    setStatus(copiedStatus)

    const selectedPerformer = performerRole
      .filter(item => item.checked)
      .map(item => item.type)

    const selectedStatus = copiedStatus
      .filter(item => item.checked)
      .map(item => item.type)

    setPage(1)

    fetchPerformarList(
      token,
      searchBox,
      selectedPerformer.toString(),
      selectedStatus.toString(),
      proPeriod,
      1,
      LIMIT
    )
  }

  const handleProbotationChange = e => {
    setProPeriod(e.target.checked)

    const selectedPerformer = performerRole
      .filter(item => item.checked)
      .map(item => item.type)

    const selectedStatus = status
      .filter(item => item.checked)
      .map(item => item.type)

    setPage(1)

    fetchPerformarList(
      token,
      searchBox,
      selectedPerformer.toString(),
      selectedStatus.toString(),
      e.target.checked,
      1,
      LIMIT
    )
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        {isLoading && <SpinnerComponent />}
        <Container fluid>
          <BreadCrumb title='GP Invitation' pageTitle='Invitation' />

          <Row>
            <Col xl={3} lg={4}>
              <Card>
                <CardHeader>
                  <div className='d-flex mb-3'>
                    <div className='flex-grow-1'>
                      <h5 className='fs-16'>Filters</h5>
                    </div>
                    {/* <div className='flex-shrink-0'>
                      <Link to='#' className='text-decoration-underline'>
                        Clear All
                      </Link>
                    </div> */}
                  </div>
                </CardHeader>

                <div className='accordion accordion-flush'>
                  <div className='accordion-item'>
                    <h2 className='accordion-header'>
                      <button
                        className='accordion-button bg-transparent shadow-none'
                        type='button'
                        id='flush-headingBrands'
                      >
                        <span className='text-muted text-uppercase fs-12 fw-medium'>
                          Filters
                        </span>
                        {/* <span className='badge bg-success rounded-pill align-middle ms-1'>
                          2
                        </span> */}
                      </button>
                    </h2>
                    <UncontrolledCollapse
                      toggler='#flush-headingBrands'
                      defaultOpen
                    >
                      <div
                        id='flush-collapseBrands'
                        className='accordion-collapse collapse show'
                        aria-labelledby='flush-headingBrands'
                      >
                        <div className='accordion-body text-body pt-0'>
                          <div className='search-box search-box-sm mb-3'>
                            <input
                              type='text'
                              className='form-control bg-light border-0'
                              placeholder='Search by name. . .'
                              onChange={e => setSearchBox(e.target.value)}
                            />
                            <i className='ri-search-line search-icon'></i>
                          </div>
                          <span className='text-muted text-uppercase fs-12 fw-medium '>
                            Performer Role
                          </span>{' '}
                          <div className='d-flex flex-column gap-2 mt-1'>
                            {performerRole?.map((item, idx) => {
                              return (
                                <div className='form-check' key={idx}>
                                  <input
                                    className='form-check-input'
                                    type='checkbox'
                                    id='productBrandRadio5'
                                    checked={item.checked}
                                    onChange={e =>
                                      handlePerformerRoleChange(e, idx)
                                    }
                                  />
                                  <label
                                    className='form-check-label'
                                    htmlFor='productBrandRadio5'
                                  >
                                    {item.type}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </div>

                  <div className='accordion-item'>
                    <h2 className='accordion-header'>
                      <button
                        className='accordion-button bg-transparent shadow-none collapsed'
                        type='button'
                        id='flush-headingDiscount'
                      >
                        <span className='text-muted text-uppercase fs-12 fw-medium'>
                          Status
                        </span>
                      </button>
                    </h2>
                    <UncontrolledCollapse
                      toggler='#flush-headingDiscount'
                      defaultOpen
                    >
                      <div
                        id='flush-collapseDiscount'
                        className='accordion-collapse collapse show'
                      >
                        <div className='accordion-body text-body pt-1'>
                          <div className='d-flex flex-column gap-2'>
                            {status.map((item, idx) => (
                              <div className='form-check' key={idx}>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='productdiscountRadio6'
                                  checked={item.checked}
                                  onChange={e => handleStatusChange(e, idx)}
                                />
                                <label
                                  className='form-check-label'
                                  htmlFor='productdiscountRadio6'
                                >
                                  {item.type}
                                </label>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </div>

                  <div className='accordion-item'>
                    <h2 className='accordion-header'>
                      <button
                        className='accordion-button bg-transparent shadow-none collapsed'
                        type='button'
                        id='flush-headingRating'
                      >
                        <span className='text-muted text-uppercase fs-12 fw-medium'>
                          Other
                        </span>
                      </button>
                    </h2>

                    <UncontrolledCollapse
                      toggler='#flush-headingRating'
                      defaultOpen
                    >
                      <div
                        id='flush-collapseRating'
                        className='accordion-collapse collapse show'
                        aria-labelledby='flush-headingRating'
                      >
                        <div className='accordion-body text-body'>
                          <div className='d-flex flex-column gap-2'>
                            <div className='form-check'>
                              <input
                                className='form-check-input'
                                type='checkbox'
                                id='productdiscountRadio6'
                                checked={proPeriod}
                                onChange={handleProbotationChange}
                              />
                              <label
                                className='form-check-label'
                                htmlFor='productdiscountRadio6'
                              >
                                Not in Probationary Period
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </UncontrolledCollapse>
                  </div>
                </div>
              </Card>
            </Col>

            <div className='col-xl-9 col-lg-8'>
              <div>
                <div className='card'>
                  <div className='card-header border-0'>
                    <div className='row align-items-center'>
                      <div className='col'>
                        <Nav
                          className='nav-tabs-custom card-header-tabs border-bottom-0 justify-content-between'
                          role='tablist'
                        >
                          {performerList.length !== 0 && (
                            <NavItem>
                              <NavLink href='#' className='fw-semibold'>
                                All
                                <span className='badge badge-soft-danger align-middle rounded-pill ms-1'>
                                  {performerList.length || 0}
                                </span>
                              </NavLink>
                            </NavItem>
                          )}
                        </Nav>
                      </div>
                    </div>
                  </div>
                  <div>
                    <CardBody className='pt-0 ps-0 pe-0'>
                      <div className='live-preview'>
                        <div className='table-responsive'>
                          {performerList.length !== 0 ? (
                            <Table className='align-middle table-nowrap mb-0'>
                              <thead className='table-light'>
                                <tr>
                                  <th scope='col' style={{ width: '30px' }}>
                                    {/* <div className='form-check'>
                                      <Input
                                        className='form-check-input'
                                        type='checkbox'
                                        defaultValue=''
                                        id='responsivetableCheck'
                                      />
                                      <Label
                                        className='form-check-label'
                                        for='responsivetableCheck'
                                      ></Label>
                                    </div> */}
                                  </th>
                                  <th scope='col'>ID</th>
                                  <th scope='col'>Name</th>
                                  <th scope='col'>Date of Registration</th>
                                  <th scope='col'>NHSEI Regional Team</th>
                                  <th scope='col'>Performer Role</th>
                                  <th scope='col'>Invitation Status</th>
                                  <th scope='col'>Status</th>
                                  <th scope='col'>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {performerList.map((item, idx) => {
                                  return (
                                    <tr key={idx}>
                                      <th scope='row'>
                                        <div className='form-check'>
                                          {/* <Input
                                            className='form-check-input'
                                            type='checkbox'
                                            defaultValue=''
                                            id='responsivetableCheck05'
                                          />
                                          <Label
                                            className='form-check-label'
                                            htmlFor='responsivetableCheck05'
                                          ></Label> */}
                                        </div>
                                      </th>
                                      <td>
                                        <Link to='#' className='fw-medium'>
                                          #{item.uuid.slice(0, 6)}
                                        </Link>
                                      </td>
                                      <td>
                                        {item['ForeName(s)'] || ''}{' '}
                                        {item.Surname || ''}
                                      </td>
                                      <td className='text-success'>
                                        {moment(
                                          item['Date of Registration']
                                        ).format('LL') || ''}
                                      </td>
                                      <td>
                                        {item['NHSEI Regional Team'] || 'NA'}
                                      </td>
                                      <td>{item['Performer Role']}</td>
                                      <td className='text-center'>
                                        {item?.performers_list_invitations
                                          .length !== 0 ? (
                                          <div className='d-flex flex-column'>
                                            <span>
                                              {item?.performers_list_invitations?.slice(
                                                -1
                                              )[0]?.invitation_type +
                                                ' ' +
                                                'invitaion'}
                                            </span>
                                            <span>
                                              {item?.performers_list_invitations?.slice(
                                                -1
                                              )[0]?.invitation_date
                                                ? moment(
                                                    item?.performers_list_invitations?.slice(
                                                      -1
                                                    )[0]?.invitation_date
                                                  ).format('LL')
                                                : ''}
                                            </span>
                                          </div>
                                        ) : (
                                          '-'
                                        )}
                                      </td>
                                      <td>
                                        <span className='badge bg-info text-bg-success'>
                                          {item.Status || 'NA'}
                                        </span>
                                      </td>
                                      <td>
                                        <button
                                          type='button'
                                          className='btn btn-success btn-sm custom-toggle active'
                                          data-bs-toggle='button'
                                          onClick={() => {
                                            setInvitationModal(true)
                                            setSelectedPerformer(item)
                                          }}
                                        >
                                          <span className='icon-on'>
                                            <i className='ri-add-line align-bottom me-1'></i>
                                            Invite
                                          </span>
                                        </button>
                                      </td>
                                    </tr>
                                  )
                                })}
                              </tbody>
                            </Table>
                          ) : (
                            <div className='card-body pt-0'>
                              <div className='py-4 text-center'>
                                <div className='mt-4'>
                                  <h5>Sorry! No Result Found</h5>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </div>
                </div>
              </div>
            </div>
          </Row>
          <PaginationV2
            totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
            previousDisabled={page === 1}
            nextDisabled={totalPageCount === page ? true : false}
            previousPageCall={() => setPage(page - 1)}
            currentPage={page}
            nextPageCalls={() => setPage(page + 1)}
            onPageInputChange={({ target: { value } }) => {
              var regex = new RegExp(`^[1-${totalPageCount}]$`)
              if (value <= totalPageCount && value !== 0) {
                setPage(value)
              }
            }}
          />

          <InvitaionModal
            open={showInvitaionModal}
            toggle={() => setInvitationModal(!showInvitaionModal)}
            handleClose={() => setInvitationModal(!showInvitaionModal)}
            selectedPerformer={selectedPerformer}
            handleSuccess={() => {
              const selectedPerformer = performerRole
                .filter(item => item.checked)
                .map(item => item.type)

              const selectedStatus = status
                .filter(item => item.checked)
                .map(item => item.type)

              fetchPerformarList(
                token,
                searchBox,
                selectedPerformer.toString(),
                selectedStatus.toString(),
                proPeriod,
                page,
                LIMIT
              )
            }}
          />
        </Container>
      </div>
    </React.Fragment>
  )
}

export default GPInvitation
