import { customHeaders } from './authServices'
import { headerContent } from '.'
import {
  GET_ALL_ORGANISATIONS_SUPER_ADMIN,
  GET_ORGANISATION_STAFF_LIST,
  GET_ORGANISATION_PM_LIST,
  POST_ADD_NEW_ORGANISATIONS,
  POST_SET_FEE_PERCENTAGE,
  POST_SUSPEND_PM_FOR_ORG,
  POST_UPDATE_DIRECT_DEBIT,
  GET_ORGANISATION_LIST,
  GET_IT_SYSTEMS,
  POST_ALLOCATE_PM,
} from './endpoints'

export const organisationServices = {

  // allocate organiation 
  getOrganizationsList: (prefix, page, limit, token, pmId) => 
    fetch(GET_ORGANISATION_LIST.endpoint(prefix, page, limit, pmId), {
    method: GET_ORGANISATION_LIST.method,
    headers: customHeaders(token)
  })
  .then(resp => {
    return resp.json()
  })
  .catch(err => console.log(err)),

  getItSystems: () =>
  fetch(GET_IT_SYSTEMS.endpoint, {
    method: GET_IT_SYSTEMS.method,
    headers: headerContent
  })
  .then(resp => {
    return resp.json()
  })
  .catch(err => console.log(err)),

  allocatePM: (data, token) => 
  fetch(POST_ALLOCATE_PM.endpoint,{
    method: POST_ALLOCATE_PM.method,
    headers: customHeaders(token),
    body: typeof data === 'string' ? data : JSON.stringify(data)
  })
  .then(resp => {
    return resp.json()
  })
  .catch(err => console.log(err)),


  getOrganisationsListSuperAdmin: (key, page, limit, token, registered) =>
    fetch(GET_ALL_ORGANISATIONS_SUPER_ADMIN.endpoint(key, page, limit, registered), {
      method: GET_ALL_ORGANISATIONS_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postAddNewOrganisaiton: (data, token) =>
    fetch(POST_ADD_NEW_ORGANISATIONS.endpoint, {
      method: POST_ADD_NEW_ORGANISATIONS.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postSuspendPMForOrganisation: (pm_id, org_id, suspend, token) =>
    fetch(POST_SUSPEND_PM_FOR_ORG.endpoint(pm_id, org_id, suspend), {
      method: POST_SUSPEND_PM_FOR_ORG.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getOrganisationsStaffList: (orgId, token) =>
    fetch(GET_ORGANISATION_STAFF_LIST.endpoint(orgId), {
      method: GET_ORGANISATION_STAFF_LIST.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getOrganisationsPmList: (orgId, token) =>
  fetch(GET_ORGANISATION_PM_LIST.endpoint(orgId), {
    method: GET_ORGANISATION_PM_LIST.method,
    headers: customHeaders(token)
  })
    .then(resp => {
      return resp.json()
    })
    .catch(err => console.log(err)),

  postUpdateDirectDebit: (data, token) =>
    fetch(POST_UPDATE_DIRECT_DEBIT.endpoint, {
      method: POST_UPDATE_DIRECT_DEBIT.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postSetFeePercentage: (data, token) =>
    fetch(POST_SET_FEE_PERCENTAGE.endpoint, {
      method: POST_SET_FEE_PERCENTAGE.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),
}
