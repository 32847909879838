import { customHeaders } from './authServices'
import {
  GET_ALL_PM_AS_SUPER_ADMIN,
  GET_BANK_DETAILS,
  GET_ORGANIATIONS_AS_PM_SUPER_ADMIN,
  GET_PM_INVOCIES,
  GET_SESSIONS_AS_PM_SUPER_ADMIN,
  POST_MARK_AS_PAID,
  POST_MARK_AS_PM_PAYMENT_RECEIVED,
  POST_MARK_AS_PM_PAYMENT_RECEIVED_PRE_PAID_CLIENT,
  GET_INVOICE_WITH_STATUS,
  SIGN_FORM_A,
  GET_DIRECT_DEBIT,
  POST_MAKE_EARLY_PAYMENT_TO_CLIENT,
} from './endpoints'

export const pmServices = {
  // Get all PM List
  getPMListSuperAdmin: (key, page, limit, token) =>
    fetch(GET_ALL_PM_AS_SUPER_ADMIN.endpoint(key, page, limit), {
      method: GET_ALL_PM_AS_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get all ORganisation assotiate with PM List
  getOrganisationByPMId: (id, token) =>
    fetch(GET_ORGANIATIONS_AS_PM_SUPER_ADMIN.endpoint(id), {
      method: GET_ORGANIATIONS_AS_PM_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get all ORganisation assotiate with PM List
  getSessionByOrganisationId: (id, token) =>
    fetch(GET_SESSIONS_AS_PM_SUPER_ADMIN.endpoint(id), {
      method: GET_SESSIONS_AS_PM_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getPmInvoices: (token,invoiceStatus, key,limit, page) =>
    fetch(GET_PM_INVOCIES.endpoint(invoiceStatus, key, limit, page), {
      method: GET_PM_INVOCIES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getBankDetails: (id, token) =>
    fetch(GET_BANK_DETAILS.endpoint(id), {
      method: GET_BANK_DETAILS.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postMarkAsPaid: (data, id, token) =>
    fetch(POST_MARK_AS_PAID.endpoint(id), {
      method: POST_MARK_AS_PAID.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

    postMarkAsPMPaymentReceived:(id,dateValue,token)=>
    fetch(POST_MARK_AS_PM_PAYMENT_RECEIVED.endpoint(id,dateValue), {
      method: POST_MARK_AS_PM_PAYMENT_RECEIVED.method,
      body: JSON.stringify(dateValue),
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

    postMarkAsPMPaymentReceivedPrePaidClient:(id,dateValue,token)=>
    fetch(POST_MARK_AS_PM_PAYMENT_RECEIVED_PRE_PAID_CLIENT.endpoint(id,dateValue), {
      method: POST_MARK_AS_PM_PAYMENT_RECEIVED_PRE_PAID_CLIENT.method,
      body: JSON.stringify(dateValue),
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

    postMakeEarlyPaymentToClient:(id, paidDate, token)=>
    fetch(POST_MAKE_EARLY_PAYMENT_TO_CLIENT.endpoint(id), {
      method: POST_MAKE_EARLY_PAYMENT_TO_CLIENT.method,
      body: JSON.stringify(paidDate),
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),
  
    // Get Invoice from status unpaid
    getInvoicesFromStatus: (status, limit, page, token, key) =>
      fetch(
        GET_INVOICE_WITH_STATUS.endpoint(
          status,
          limit,
          page,
          key
        ),
        {
          method: GET_INVOICE_WITH_STATUS.method,
          headers: customHeaders(token)
        }
      )
        .then(resp => {
          return resp.json()
        })
        .catch(err => console.log(err)),

    signFormA: (data, token) =>
      fetch(SIGN_FORM_A.endpoint(), {
        method: SIGN_FORM_A.method,
        headers: customHeaders(token),
        body: typeof data === 'string' ? data : JSON.stringify(data)
      })
        .then(resp => {
          return resp.json()
        })
        .catch(err => console.log(err)),

    getDirectDebit: (token, status, key,limit, page) =>
      fetch(GET_DIRECT_DEBIT.endpoint(status, key, limit, page), {
        method: GET_DIRECT_DEBIT.method,
        headers: customHeaders(token)
      })
        .then(resp => {
          return resp.json()
        })
        .catch(err => console.log(err)),
  }
