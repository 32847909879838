import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Table,
    CardImg,
    Form,
    UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import profile from "../../assets/images/user.png";
import { useEffect } from "react";
import moment from "moment";
import { documentStatusColor } from "../constants/layout";

const ClientDetailModal = ({ open, toggle, appliedClient, handleDownloadClick }) => {
    const [selectedClient, setSelectedClient] = useState();
    const [customverticalTab, setcustomverticalTab] = useState(0);
    const [activeTab, setactiveTab] = useState("1");
    const [selectedProfilePhoto, setSelectedProfilePhoto] = useState();
    useEffect(() => {
        if (appliedClient?.length !== 0) {
            setcustomverticalTab(0);
            setSelectedClient(appliedClient?.Session_clients[0]);
            setSelectedProfilePhoto(appliedClient?.Session_clients[0]?.users?.User_profile?.profile);
        }
    }, [appliedClient]);

    const customtoggleVertical = (tab) => {
        if (customverticalTab !== tab) {
            setcustomverticalTab(tab);
            setSelectedClient(appliedClient.Session_clients[tab]);
            setSelectedProfilePhoto(appliedClient.Session_clients[tab]?.users?.User_profile?.profile);
        }
    };

    const toggle_tab = (tab) => {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    };

    document.title = 'airGP | Session List'
    return (
        <Modal size="xl" isOpen={open} toggle={toggle} className="modal-dialog-scrollable">
            <ModalHeader className="p-3 bg-airgp modal-title" id="myExtraLargeModalLabel" toggle={toggle}>
                <div className="modal-title text-white h5">
                    {appliedClient?.status === "published" ? "Applicant Details" : "User Details"}
                </div>
            </ModalHeader>
            <ModalBody className="m-0 p-0">
                <CardBody>
                    <Row>
                        <Col xl={3}>
                            <Nav pills className="nav nav-pills flex-column nav-pills-tab custom-verti-nav-pills">
                                {appliedClient?.Session_clients?.length !== 0 &&
                                    appliedClient?.Session_clients?.map((client, idx) => {
                                        return (
                                            <NavItem key={idx}>
                                                <NavLink
                                                    style={{
                                                        cursor: "pointer",
                                                        ...(customverticalTab !== idx && { color: "black" }),
                                                    }}
                                                    className={classnames({
                                                        "mb-2 ": true,
                                                        active: customverticalTab === idx,
                                                    })}
                                                    onClick={() => {
                                                        customtoggleVertical(idx);
                                                    }}
                                                    id="custom-v-pills-home-tab"
                                                >
                                                    <CardImg
                                                        src={
                                                            client.users?.User_profile?.profile
                                                                ? `data:image/jpeg;base64,${client.users?.User_profile?.profile}`
                                                                : profile
                                                        }
                                                        className="avatar-xs rounded-circle me-2"
                                                        style={{ objectFit: "cover" }}
                                                    ></CardImg>

                                                    <div
                                                        className="align-middle d-inline-block text-truncate text-capitalize"
                                                        style={{ width: "180px" }}
                                                    >
                                                        {client?.users?.title || "NA"}{" "}
                                                        {client?.users?.firstName || "NA"}{" "}
                                                        {client?.users?.lastName || "NA"}
                                                        {appliedClient.status === "published" ? (
                                                            <div className="mb-0 small">
                                                                Applied on:{" "}
                                                                {client?.Session_statuses[0]
                                                                    ?.Session_client_status?.createdAt
                                                                    ? moment(
                                                                        client?.Session_statuses[0]
                                                                              ?.Session_client_status?.createdAt
                                                                      ).format("DD MMMM YYYY")
                                                                    : "-"}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </NavLink>
                                            </NavItem>
                                        );
                                    })}
                            </Nav>
                        </Col>
                        <Col xl={9}>
                            <TabContent activeTab={0} className="text-muted mt-3 mt-lg-0">
                                <TabPane tabId={0} id="custom-v-pills-home">
                                    <Row>
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="flex-shrink-0">
                                                <CardImg
                                                    src={
                                                        selectedProfilePhoto
                                                            ? `data:image/jpeg;base64,${selectedProfilePhoto}`
                                                            : profile
                                                    }
                                                    alt=""
                                                    className="avatar-lg img-thumbnail rounded-circle"
                                                    style={{ objectFit: "cover" }}
                                                ></CardImg>
                                            </div>
                                            <div className="flex-grow-1 ms-2 name">
                                                <h4 className=" fw-semibold">
                                                    {selectedClient?.users?.title || "NA"}{" "}
                                                    {selectedClient?.users?.firstName || "NA"}{" "}
                                                    {selectedClient?.users?.lastName || "NA"}
                                                    {selectedClient?.RegisteredWith?.registrationStatus
                                                        ?.registration_status_name === "Registered with licence" &&
                                                        selectedClient?.availableOnPerformerList &&
                                                        selectedClient?.users?.active === 1 &&
                                                        selectedProfilePhoto.length !== 0 && (
                                                            <>
                                                                <i
                                                                    className="ri-verified-badge-fill text-warning fs-24 m-2 cursor"
                                                                    id="verified-icon"
                                                                ></i>
                                                                <UncontrolledTooltip
                                                                    placement="top"
                                                                    target="verified-icon"
                                                                >
                                                                    {" "}
                                                                    Verified Profile
                                                                </UncontrolledTooltip>
                                                            </>
                                                        )}
                                                </h4>

                                                <h6 className="fs-12 text-muted">
                                                    {selectedClient?.users?.User_profile?.regulator_type || "GMC - "}
                                                    {" Number: "}{" "}
                                                    {selectedClient?.users?.User_profile?.regulator_number || "NA"}{" "}
                                                    <Link
                                                        to={{
                                                            pathname: `https://www.gmc-uk.org/doctors/${encodeURIComponent(
                                                                `${selectedClient?.users?.User_profile?.regulator_number}`
                                                            )}`,
                                                        }}
                                                        target="_blank"
                                                        className="btn btn-soft-dark btn-sm float-end text-decoration-underline"
                                                    >
                                                        View on GMC website
                                                    </Link>
                                                </h6>
                                            </div>
                                        </div>
                                    </Row>
                                    <Row>
                                        <Col lg={4} md={12} sm={12}>
                                            <div
                                                className={`p-0 mb-3 border border-dashed ${
                                                    selectedClient?.RegisteredWith.registrationStatus
                                                        .registration_status_name === "Registered with licence"
                                                        ? "border-success"
                                                        : "border-danger"
                                                }  rounded`}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm me-1">
                                                        <div
                                                            className={`avatar-title rounded bg-transparent ${
                                                                selectedClient?.RegisteredWith?.registrationStatus
                                                                    .registration_status_name ===
                                                                "Registered with licence"
                                                                    ? "text-success"
                                                                    : "text-danger"
                                                            }  fs-24`}
                                                        >
                                                            {selectedClient?.RegisteredWith?.registrationStatus
                                                                .registration_status_name ===
                                                            "Registered with licence" ? (
                                                                <i className="ri-checkbox-circle-fill"></i>
                                                            ) : (
                                                                <i className="ri-close-circle-line"></i>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p
                                                            className={`mb-0  ${
                                                                selectedClient?.RegisteredWith?.registrationStatus
                                                                    .registration_status_name ===
                                                                "Registered with licence"
                                                                    ? "text-success"
                                                                    : "text-danger"
                                                            }`}
                                                        >
                                                            {selectedClient?.RegisteredWith?.registrationStatus
                                                                .registration_status_name ||
                                                                "Registered With - Not Found"}
                                                        </p>
                                                        <p className="mb-0 small">
                                                            Last checked:{" "}
                                                            {selectedClient?.RegisteredWith?.updatedAt
                                                                ? moment(
                                                                      selectedClient?.RegisteredWith?.updatedAt
                                                                  ).format("MMMM YYYY")
                                                                : "-"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={12} sm={12}>
                                            <div
                                                className={`p-0 mb-3 border border-dashed ${
                                                    selectedClient?.availableOnPerformerList
                                                        ? "border-success"
                                                        : "border-danger"
                                                }  rounded`}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm me-1">
                                                        <div
                                                            className={`avatar-title rounded bg-transparent ${
                                                                selectedClient?.availableOnPerformerList
                                                                    ? "text-success"
                                                                    : "text-danger"
                                                            }  fs-24`}
                                                        >
                                                            {selectedClient?.availableOnPerformerList ? (
                                                                <i className="ri-checkbox-circle-fill"></i>
                                                            ) : (
                                                                <i className="ri-close-circle-line"></i>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p
                                                            className={`mb-0  ${
                                                                selectedClient?.availableOnPerformerList
                                                                    ? "text-success"
                                                                    : "text-danger"
                                                            }`}
                                                        >
                                                            {selectedClient?.availableOnPerformerList
                                                                ? "On Performers List"
                                                                : "Not On Performers List"}
                                                        </p>
                                                        <p className="mb-0 small">
                                                            Last checked:{" "}
                                                            {selectedClient?.lastCheckedOnPerformerList
                                                                ? moment(
                                                                      selectedClient?.lastCheckedOnPerformerList
                                                                  ).format("MMMM YYYY")
                                                                : "-"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={12} sm={12}>
                                            <div
                                                className={`p-0 mb-3 border border-dashed ${
                                                    selectedClient?.users?.active === 1
                                                        ? "border-success"
                                                        : "border-danger"
                                                }  rounded`}
                                            >
                                                <div className="d-flex align-items-center">
                                                    <div className="avatar-sm me-1">
                                                        <div
                                                            className={`avatar-title rounded bg-transparent ${
                                                                selectedClient?.users?.active === 1
                                                                    ? "text-success"
                                                                    : "text-danger"
                                                            }  fs-24`}
                                                        >
                                                            {selectedClient?.users?.active === 1 ? (
                                                                <i className="ri-checkbox-circle-fill"></i>
                                                            ) : (
                                                                <i className="ri-close-circle-line"></i>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <p
                                                            className={`mb-0  ${
                                                                selectedClient?.users?.active === 1
                                                                    ? "text-success"
                                                                    : "text-danger"
                                                            }`}
                                                        >
                                                            {selectedClient?.users?.active === 1
                                                                ? "Account Verified"
                                                                : "Unverified Account"}
                                                        </p>
                                                        <p className="mb-0 small">
                                                            Last checked:{" "}
                                                            {selectedClient?.users?.updatedAt
                                                                ? moment(selectedClient?.users?.updatedAt).format(
                                                                      "MMMM YYYY"
                                                                  )
                                                                : "-"}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Card>
                                                <CardHeader className="mb-0 pb-0">
                                                    <Nav tabs className="nav-tabs">
                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    active: activeTab === "1",
                                                                })}
                                                                onClick={() => {
                                                                    toggle_tab("1");
                                                                }}
                                                            >
                                                                User details
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    active: activeTab === "2",
                                                                })}
                                                                onClick={() => {
                                                                    toggle_tab("2");
                                                                }}
                                                            >
                                                                Documents
                                                            </NavLink>
                                                        </NavItem>
                                                        <NavItem>
                                                            <NavLink
                                                                style={{ cursor: "pointer" }}
                                                                className={classnames({
                                                                    active: activeTab === "3",
                                                                })}
                                                                onClick={() => {
                                                                    toggle_tab("3");
                                                                }}
                                                            >
                                                                About
                                                            </NavLink>
                                                        </NavItem>
                                                    </Nav>
                                                </CardHeader>
                                                <CardBody>
                                                    <TabContent activeTab={activeTab} className="">
                                                        <TabPane tabId="1" id="description">
                                                            <Form
                                                                className={"needs-validation view-event "}
                                                                name="event-form"
                                                                id="form-event"
                                                                onSubmit={(e) => e.preventDefault()}
                                                            >
                                                                <div className="event-details">
                                                                    <div className="table-responsive">
                                                                        <Table className=" mb-0 table-nowrap">
                                                                            <tbody>
                                                                                <tr className="px-1">
                                                                                    <th
                                                                                        className="p-0 fw-semibold mb-0 "
                                                                                        scope="row"
                                                                                    >
                                                                                        <div className="flex-grow-1 d-flex align-items-center">
                                                                                            <div className="flex-grow-1">
                                                                                                <h6 className="d-block fw-semibold align-middle mt-2">
                                                                                                    Email:
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td className="p-1">
                                                                                        <div className="flex-grow-1">
                                                                                            <h6 className="d-block mt-2">
                                                                                                {selectedClient?.users
                                                                                                    ?.email || "NA"}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr className="p-2">
                                                                                    <th
                                                                                        className="ps-0 fw-semibold mb-0 p-1"
                                                                                        scope="row"
                                                                                    >
                                                                                        <div className="flex-grow-1 d-flex align-items-center">
                                                                                            <div className="flex-grow-1">
                                                                                                <h6 className="d-block fw-semibold  align-middle mt-2">
                                                                                                    Gender:
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td className="p-1">
                                                                                        <div className="flex-grow-1">
                                                                                            <h6 className="d-block mt-2">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.gender || "NA"}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <th
                                                                                        className="ps-0 fw-semibold mb-0 p-1"
                                                                                        scope="row"
                                                                                    >
                                                                                        <div className="flex-grow-1 d-flex align-items-center">
                                                                                            <div className="flex-grow-1">
                                                                                                <h6 className="d-block fw-semibold mt-2">
                                                                                                    Date of Birth:
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td className="p-1">
                                                                                        <div className="flex-grow-1">
                                                                                            <h6 className="d-block mt-2">
                                                                                                {moment(
                                                                                                    selectedClient
                                                                                                        ?.users
                                                                                                        ?.User_profile
                                                                                                        ?.dob
                                                                                                ).format("L") || "NA"}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <th
                                                                                        className="ps-0 fw-semibold mb-0 p-1"
                                                                                        scope="row"
                                                                                    >
                                                                                        <div className="flex-grow-1 d-flex align-items-center">
                                                                                            <div className="flex-grow-1">
                                                                                                <h6 className="d-block fw-semibold mt-2">
                                                                                                    Contact Number:
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td className="p-1">
                                                                                        <div className="flex-grow-1">
                                                                                            <h6 className="d-block mt-2">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.contact_number ||
                                                                                                    "NA"}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <th
                                                                                        className="ps-0 fw-semibold mb-0 p-1"
                                                                                        scope="row"
                                                                                    >
                                                                                        <div className="flex-grow-1 d-flex align-items-center">
                                                                                            <div className="flex-grow-1">
                                                                                                <h6 className="d-block fw-semibold mt-2">
                                                                                                    Smartcard Number:
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td className="p-1">
                                                                                        <div className="flex-grow-1">
                                                                                            <h6 className="d-block  mt-2">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.smartcard_number ||
                                                                                                    "NA"}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>

                                                                                <tr>
                                                                                    <th
                                                                                        className="ps-0 fw-semibold mb-0 p-1"
                                                                                        scope="row"
                                                                                    >
                                                                                        <div className="flex-grow-1 d-flex align-items-center">
                                                                                            <div className="flex-grow-1">
                                                                                                <h6 className="d-block fw-semibold mt-2">
                                                                                                    Employment Type:
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td className="p-1">
                                                                                        <div className="flex-grow-1">
                                                                                            <h6 className="d-block mt-2">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.employment_type ===
                                                                                                "self_employed"
                                                                                                    ? "Self Employed"
                                                                                                    : "Limited Company" ||
                                                                                                      "NA"}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <th
                                                                                        className="ps-0 fw-semibold mb-0 p-1"
                                                                                        scope="row"
                                                                                    >
                                                                                        <div className="flex-grow-1 d-flex align-items-center">
                                                                                            <div className="flex-grow-1">
                                                                                                <h6 className="d-block fw-semibold  mt-2">
                                                                                                    Address:
                                                                                                </h6>
                                                                                            </div>
                                                                                        </div>
                                                                                    </th>
                                                                                    <td className="p-1">
                                                                                        <div className="flex-grow-1">
                                                                                            <h6 className="d-block mt-2">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.address_line_1 ||
                                                                                                    ""}
                                                                                            </h6>
                                                                                            <h6 className="d-block">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.address_line_2 ||
                                                                                                    ""}
                                                                                            </h6>
                                                                                            <h6 className="d-block">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.address_line_3 ||
                                                                                                    ""}
                                                                                            </h6>
                                                                                            <h6 className="d-block">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.address_line_4 ||
                                                                                                    ""}
                                                                                            </h6>
                                                                                            <h6 className="d-block">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.address_line_5 ||
                                                                                                    ""}
                                                                                            </h6>
                                                                                            <h6 className="d-block">
                                                                                                {selectedClient?.users
                                                                                                    ?.User_profile
                                                                                                    ?.postcode || "NA"}
                                                                                            </h6>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </Table>
                                                                    </div>
                                                                </div>
                                                            </Form>
                                                            {/* </CardBody>
                              </Card> */}
                                                        </TabPane>
                                                    </TabContent>

                                                    <TabContent activeTab={activeTab} className="">
                                                        <TabPane tabId="2" id="description">
                                                            <Col lg={12}>
                                                                <div className="table-responsive table-card">
                                                                    <Table className="align-middle table-hover  table-nowrap">
                                                                        <thead className="text-muted">
                                                                            <tr>
                                                                                <th scope="col">Document Title</th>
                                                                                <th scope="col">Document Status</th>
                                                                                <th scope="col">Action</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            {(
                                                                                selectedClient?.clinicalDocuments || []
                                                                            ).map((item, key) => (
                                                                                <tr key={key}>
                                                                                    <td>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <div className="avatar-xs">
                                                                                                <div
                                                                                                    className={`avatar-title bg-soft-primary text-primary rounded-circle fs-20`}
                                                                                                >
                                                                                                    <i className=" ri-file-line"></i>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="ms-3 flex-grow-1">
                                                                                                {item?.document_type ||
                                                                                                    ""}
                                                                                                <p className="text-muted small px-0 m-0">
                                                                                                    {item?.document_category ||
                                                                                                        ""}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td>
                                                                                        {(
                                                                                            item.user_clinical_documents ||
                                                                                            []
                                                                                        ).some(
                                                                                            (status) =>
                                                                                                status?.document_statuses?.slice(
                                                                                                    -1
                                                                                                )[0]?.status ===
                                                                                                "approved"
                                                                                        ) ? (
                                                                                            <span
                                                                                                className={`text-capitalize text-success`}
                                                                                            >
                                                                                                Approved
                                                                                            </span>
                                                                                        ) : (
                                                                                              item.user_clinical_documents ||
                                                                                              []
                                                                                          ).some(
                                                                                              (status) =>
                                                                                                  status?.document_statuses?.slice(
                                                                                                      -1
                                                                                                  )[0]?.status ===
                                                                                                  "awaiting_approval"
                                                                                          ) ? (
                                                                                            <span
                                                                                                className={`text-capitalize text-${documentStatusColor(
                                                                                                    "awaiting_approval"
                                                                                                )}`}
                                                                                            >
                                                                                                Awaiting approval
                                                                                            </span>
                                                                                        ) : (
                                                                                              item.user_clinical_documents ||
                                                                                              []
                                                                                          ).some(
                                                                                              (status) =>
                                                                                                  status?.document_statuses?.slice(
                                                                                                      -1
                                                                                                  )[0]?.status ===
                                                                                                  "expired"
                                                                                          ) ? (
                                                                                            <span
                                                                                                className={`text-capitalize text-${documentStatusColor(
                                                                                                    "expired"
                                                                                                )}`}
                                                                                            >
                                                                                                expired
                                                                                            </span>
                                                                                        ) : (
                                                                                              item.user_clinical_documents ||
                                                                                              []
                                                                                          ).some(
                                                                                              (status) =>
                                                                                                  status?.document_statuses?.slice(
                                                                                                      -1
                                                                                                  )[0]?.status ===
                                                                                                  "rejected"
                                                                                          ) ? (
                                                                                            <span
                                                                                                className={`text-capitalize text-${documentStatusColor(
                                                                                                    "rejected"
                                                                                                )}`}
                                                                                            >
                                                                                                rejected
                                                                                            </span>
                                                                                        ) : (
                                                                                            <span
                                                                                                className={`text-capitalize text-${documentStatusColor(
                                                                                                    "NA"
                                                                                                )}`}
                                                                                            >
                                                                                                Not Uploaded
                                                                                            </span>
                                                                                        )}
                                                                                    </td>
                                                                                    <td>
                                                                                        <div className="hstack  gap-2">
                                                                                            <Button
                                                                                                color="success"
                                                                                                size="sm"
                                                                                                className="btn-label btn-soft-success rounded-pill"
                                                                                                id={
                                                                                                    "tooltipDel" +
                                                                                                    item.uuid
                                                                                                }
                                                                                                onClick={() =>
                                                                                                    handleDownloadClick(
                                                                                                        item?.user_clinical_documents
                                                                                                    )
                                                                                                }
                                                                                            >
                                                                                                <i className=" ri-download-cloud-fill label-icon align-middle fs-16 me-2"></i>
                                                                                                Download
                                                                                            </Button>
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            ))}
                                                                        </tbody>
                                                                    </Table>
                                                                </div>
                                                            </Col>
                                                        </TabPane>
                                                    </TabContent>

                                                    <TabContent activeTab={activeTab} className="">
                                                        <TabPane tabId="3" id="description">
                                                            <div>{selectedClient?.users?.User_profile?.bio || ""}</div>
                                                        </TabPane>
                                                    </TabContent>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Row>
                                </TabPane>
                            </TabContent>
                        </Col>
                    </Row>
                </CardBody>
            </ModalBody>

            <ModalFooter>
                <Button color="light" onClick={() => toggle()}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ClientDetailModal;
