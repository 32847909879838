import { customHeaders } from './authServices'
import {
  GET_SESSIONS,
  GET_SESSION_FEATURES,
  GET_WORKED_SESSIONS,
  GET_SESSION_JOB_ROLE,
  GET_SESSION_JOB_TYPE,
  GET_SESSION_CLIENTS_BY_ID,
  PATCH_EDIT_PM_SESSION
} from './endpoints'

export const sessionServices = {
  // Get session features
  getSessionFeaturesTypes: token =>
    fetch(GET_SESSION_FEATURES.endpoint, {
      method: GET_SESSION_FEATURES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get worked sessions
  getWorkedSessions: (limit, page, token, key) =>
  fetch(GET_WORKED_SESSIONS.endpoint(limit, page, key), {
    method: GET_WORKED_SESSIONS.method,
    headers: customHeaders(token)
  })
    .then(resp => {
      return resp.json()
    })
    .catch(err => console.log(err)),

  // Get session job
  getSessionJobTypes: token =>
    fetch(GET_SESSION_JOB_ROLE.endpoint, {
      method: GET_SESSION_JOB_ROLE.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getSessions: (status, token, page, limit) =>
    fetch(GET_SESSIONS.endpoint(status, page, limit), {
        method: GET_SESSIONS.method,
        headers: customHeaders(token),
    })
        .then((resp) => {
            return resp.json();
        })
        .catch((err) => console.log(err)),

  getSessionClientsByID: (sessionId, token, clientId) =>
    fetch(GET_SESSION_CLIENTS_BY_ID.endpoint(sessionId, clientId), {
      method: GET_SESSION_CLIENTS_BY_ID.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get session job type
  getSessionJobTypesData: token =>
    fetch(GET_SESSION_JOB_TYPE.endpoint, {
      method: GET_SESSION_JOB_TYPE.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Edit Pm session
  patchEditPmSession: (data, id, token) =>
    fetch(PATCH_EDIT_PM_SESSION.endpoint(id), {
      method: PATCH_EDIT_PM_SESSION.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err))
}
