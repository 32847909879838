import React, { useEffect, useState } from 'react'
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Button,
  Form,
  FormFeedback,
  Alert
} from 'reactstrap'
import ParticlesAuth from './ParticlesAuth'

//redux
import { useSelector, useDispatch } from 'react-redux'

import { withRouter, Link, useHistory } from 'react-router-dom'

// Formik validation
import * as Yup from 'yup'
import { useFormik } from 'formik'

import { loginUser } from '../../store/actions'
import { authServices } from '../../services/authServices'
import { toast, ToastContainer } from 'react-toastify'
import SpinnerComponent from '../../Components/Common/Spinner'
import logoLight from '../../assets/images/logo-light.png'

const Login = props => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { user } = useSelector(state => ({
    user: state.Account.user
  }))

  const [userLogin, setUserLogin] = useState([])
  const [isLoading , setIsLoading] = useState(false)

  useEffect(() => {
    if (user && user) {
      setUserLogin({
        email: user.user.email,
        password: user.user.confirm_password
      })
    }
  }, [user])

  const validation = useFormik({
    enableReinitialize: true,

    initialValues: {
      email: userLogin.email || '',
      password: userLogin.password || ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required('Please Enter Your Email'),
      password: Yup.string().required('Please Enter Your Password')
    }),
    onSubmit: values => {
      if (values) {
        authServices
          .postLoginSuperAdmin(values)
          .then(resp => {
            if (resp.success) {
              history.push('/client-list')
              // toast.success('You have successfully looged in.')
              localStorage.setItem('token', resp.accessToken)
              localStorage.setItem('ref-token', resp.refreshToken)
            } else {
              toast.error(resp.message)
            }
          })
          .catch(err => console.log(err))
          .finally(() => setIsLoading(false))
      }
      setIsLoading(true)
      dispatch(loginUser(values, props.history))
    }
  })

  const { error } = useSelector(state => ({
    error: state.Login.error
  }))

  document.title = 'SignIn | airGP'
  return (
    <React.Fragment>
      <ParticlesAuth>
        <div className='auth-page-content'>
          {isLoading && <SpinnerComponent />}
          <Container>
            <Row>
              <Col lg={12}>
                <div className='text-center mt-sm-5 mb-4 text-white'>
                  <div>
                    <Link to='/' className='d-inline-block auth-logo'>
                    <span className='airgp-logo'>
                        <img src={logoLight} alt="" height="40" />
                      </span>
                    </Link>
                  </div>
                  <p className='mt-3 fs-15 fw-medium'>Admin Dashboard</p>
                </div>
              </Col>
            </Row>

            <Row className='justify-content-center'>
              <Col md={8} lg={6} xl={5}>
                <Card className='mt-4'>
                  <CardBody className='p-4'>
                    <div className='text-center mt-2'>
                      <h5 className='text-primary'>RESTRICTED AREA</h5>
                      <p className='text-muted'>
                        {/* Sign in to continue to airGP. */}
                      </p>
                    </div>
                    {error && error ? (
                      <Alert color='danger'> {error} </Alert>
                    ) : null}
                    <div className='p-2 mt-4'>
                      <Form
                        onSubmit={e => {
                          e.preventDefault()
                          validation.handleSubmit()
                        }}
                        action='#'
                      >
                        <div className='mb-3'>
                          <Label htmlFor='email' className='form-label'>
                            Email
                          </Label>
                          <Input
                            name='email'
                            className='form-control'
                            placeholder='Enter email'
                            type='email'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.email || ''}
                            invalid={
                              validation.touched.email &&
                              validation.errors.email
                                ? true
                                : false
                            }
                          />
                          {validation.touched.email &&
                          validation.errors.email ? (
                            <FormFeedback type='invalid'>
                              {validation.errors.email}
                            </FormFeedback>
                          ) : null}
                        </div>

                        <div className='mb-3'>
                          {/* <div className='float-end'>
                            <Link to='/forgot-password' className='text-muted'>
                              Forgot password?
                            </Link>
                          </div> */}
                          <Label
                            className='form-label'
                            htmlFor='password-input'
                          >
                            Password
                          </Label>
                          <div className='position-relative auth-pass-inputgroup mb-3'>
                            <Input
                              name='password'
                              value={validation.values.password || ''}
                              type='password'
                              className='form-control pe-5'
                              placeholder='Enter Password'
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password &&
                                validation.errors.password
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.password &&
                            validation.errors.password ? (
                              <FormFeedback type='invalid'>
                                {validation.errors.password}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </div>

                        <div className='form-check'>
                          <Input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='auth-remember-check'
                          />
                          <Label
                            className='form-check-label'
                            htmlFor='auth-remember-check'
                          >
                            Remember me
                          </Label>
                        </div>

                        <div className='mt-4'>
                          <Button
                            color='primary'
                            className='btn btn-primary w-100'
                            type='submit'
                          >
                            Sign In
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </ParticlesAuth>
      <ToastContainer />
    </React.Fragment>
  )
}

export default withRouter(Login)
