import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Badge, Button, Col, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import fetchPDF from "../../common/fetchPDF";
import SpinnerComponent from "./Spinner";
import { clientServices } from "../../services/clientServices";
import { documentStatusColor } from "../constants/layout";

const DownloadDocumentsModal = ({ open, handleClose, userUploadedDocuments }) => {
    const [isLoading, setIsLoading] = useState(false);

    const token = localStorage.getItem("token");

    const handleDownloadDocument = (pdf_uuid, status, user_id, originalFileName) => {
        setIsLoading(true);
        clientServices
            .downloadDocument(pdf_uuid, status, token)
            .then((resp) => fetchPDF(resp, originalFileName))
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    return (
        <>
            {isLoading && <SpinnerComponent />}
            <Modal size="lg" id="myModal" isOpen={open} toggle={handleClose} className="modal-dialog-scrollable">
                <ModalHeader className="p-3 bg-airgp modal-title" id="myModalLabel" toggle={handleClose}>
                    <div className="modal-title text-white h5">Download Documents</div>
                </ModalHeader>
                <ModalBody>
                    <Col xl={12}>
                        <div className="table-responsive  table-card">
                            <Table className="table-hover align-middle table-nowrap mb-0">
                                <thead className="text-muted">
                                    <tr>
                                        <th scope="col">ID</th>
                                        <th scope="col">File Name</th>
                                        <th scope="col">Status</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {(userUploadedDocuments || []).map((_, index) => (
                                        <tr key={index}>
                                            <td>{_?.uuid?.slice(0, 4) || "NA"}</td>
                                            <td>{_?.original_file_name}</td>
                                            <td>
                                                <span
                                                    className={`badge text-capitalize rounded-pill badge-soft-${documentStatusColor(
                                                        _?.document_statuses?.slice(-1)[0]?.status
                                                            ? _?.document_statuses?.slice(-1)[0]?.status
                                                            : "NA"
                                                    )}`}
                                                >
                                                    {_?.document_statuses?.slice(-1)[0]?.status}
                                                </span>
                                            </td>
                                            <td>
                                                <Link
                                                    to="#"
                                                    onClick={() =>
                                                        handleDownloadDocument(
                                                            _?.uuid,
                                                            "userclinicaldocuments",
                                                            _?.user_id,
                                                            _?.original_file_name
                                                        )
                                                    }
                                                >
                                                    <i className="ri-download-2-line fs-17 lh-1 align-middle"></i>
                                                </Link>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    </Col>
                </ModalBody>
                <div className="modal-footer">
                    <Button color="light" onClick={handleClose}>
                        Close
                    </Button>
                </div>
            </Modal>
        </>
    );
};

export default DownloadDocumentsModal;
