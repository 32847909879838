import React, { useState } from 'react'

import { Card, CardBody, Col, Container, Row, Table, Badge } from 'reactstrap'

import BreadCrumb from '../../../Components/Common/BreadCrumb'
import UiContent from '../../../Components/Common/UiContent'
import { Link, useHistory, useParams } from 'react-router-dom'
import moment from 'moment/moment'
import { procedureTypeCodeClass } from '../../../utils/constants'
import { pmServices } from '../../../services/pmServices'
import { useEffect } from 'react'

const PMSessionList = () => {
  const [allSessions, setAllSessionList] = useState([])
  const history = useHistory()
  let { id } = useParams()
  const token = localStorage.getItem('token')

  useEffect(() => {
    fetchGetSessionByPmId()
  }, [])

  const fetchGetSessionByPmId = () => {
    pmServices
      .getSessionByOrganisationId(id, token)
      .then(resp => {
        if (resp.success) {
          setAllSessionList(resp.data || [])
        }
        console.log(resp)
      })
      .catch(err => console.log(err))
  }
  document.title = 'PM Session List | airGP'
  return (
    <div>
      <React.Fragment>
        <UiContent />
        <div className='page-content'>
          <Container fluid>
            <BreadCrumb
              title='Organisation Session List'
              pageTitle='Org List'
              to={'/practice-manager'}
            />

            <Row>
              <Col xl={12}>
                <Card>
                  <Col xxl={12}>
                    <Card>
                      <CardBody>
                        <CardBody>
                          <div className='live-preview'>
                            <div className='table-responsive table-card'>
                              <Table className='table-nowrap mb-0'>
                                <thead className='table-light'>
                                  <tr>
                                    <th scope='col'>ID</th>
                                    <th scope='col'>Start Time</th>
                                    <th scope='col'>End Time</th>
                                    <th scope='col'>Fee</th>
                                    <th scope='col'>Source</th>
                                    <th scope='col'>Status</th>
                                    <th scope='col'>Invoice Status</th>
                                  </tr>
                                </thead>
                                {allSessions?.length !== 0 ? (
                                  allSessions?.map((session, idx) => {
                                    return (
                                      <tbody key={`session-list-${idx}`}>
                                        <tr>
                                          <td className='fw-medium'>
                                            <Link to='#' className='fw-medium'>
                                              {`#${session?.uuid.slice(0, 6)}`}
                                            </Link>
                                          </td>
                                          <td>
                                            {moment(session?.start_time).format(
                                              'MMM Do YYYY, h:mm a'
                                            ) || 'NA'}
                                          </td>
                                          <td>
                                            {moment(session?.end_time).format(
                                              'MMM Do YYYY, h:mm a'
                                            ) || 'NA'}
                                          </td>
                                          <td>{session?.fee || 'NA'}</td>
                                          <td>
                                            <Badge
                                              color={procedureTypeCodeClass(
                                                session?.session_source
                                              )}
                                            >
                                              {session?.session_source || 'NA'}
                                            </Badge>
                                          </td>
                                          <td>
                                            <Badge
                                              color={procedureTypeCodeClass(
                                                session?.status
                                              )}
                                            >
                                              {session?.status || 'NA'}
                                            </Badge>
                                          </td>
                                          <td>
                                            <Badge
                                              color={procedureTypeCodeClass(
                                                session?.invoice_status
                                                  ? session?.invoice_status
                                                  : 'NA'
                                              )}
                                            >
                                              {session?.invoice_status || 'NA'}
                                            </Badge>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )
                                  })
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td className='fw-medium'></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No session found</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </Table>
                            </div>
                          </div>
                        </CardBody>
                      </CardBody>
                    </Card>
                  </Col>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  )
}

export default PMSessionList
