import React, { useState, useEffect } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Table,
  Badge,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  Button
} from 'reactstrap'
import BreadCrumb from '../../Components/Common/BreadCrumb'
import UiContent from '../../Components/Common/UiContent'
import { Link, useParams } from 'react-router-dom'
import { clientServices } from '../../services/clientServices'
import moment from 'moment/moment'
import {
  convertTimeToMinute,
  procedureTypeCodeClass
} from '../../utils/constants'
import { ModalHeader } from 'reactstrap'
import { ModalBody } from 'reactstrap'
import { documentServices } from '../../services/documentServices'
import { toast } from 'react-toastify'
import EditDraftSessionModal from '../../Components/Common/EditDraftSessionModal'
import PaginationV2 from '../../Components/Common/PaginationV2'
import { calculatePensionablePay } from '../../utils/constants'
import fetchPDF from '../../common/fetchPDF'
import SuccessConfirmationModal from '../../Components/Common/SuccessConfirmationModal'
import UserDetailsModal from '../../Components/Common/UserDetailsModal'

let LIMIT = 20
const SessionListTable = () => {
  const [allSessions, setAllSessions] = useState([])
  const [userDetails, setUserDetails] = useState({})
  const [showRejectModal, setShowRejectModal] = useState(false)
  const [rejectionNote, setRejectionNote] = useState()
  const [documentId, setDocumentId] = useState()
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [page, setPage] = useState(1)
  const [editSessionModal, setEditSessionModal] = useState(false)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [documentApprove, setDocumentApprove] = useState()
  const [sessionObj, setSessionObj] = useState()
  const [dateValue, setDateValue] = useState('')
  const [selectedSession, setSelectedSession] = useState()
  const [userDetailModal, setuserDetailModal] = useState(false)

  function tog_varying3 () {
    setShowRejectModal(!showRejectModal)
  }

  const token = localStorage.getItem('token')
  let { id, name } = useParams()

  useEffect(() => {
    if (name === 'session') {
      fetchSessionList(id, page, LIMIT, token)
    }

    if (name === 'invoice') {
      fetchInvoiceList(id, page, LIMIT, token)
    }

    if (name === 'document') {
      fetchDocumentList(id, page, LIMIT, token)
    }
  }, [id, page])

  const fetchSessionList = (id, page, limit, token) => {
    clientServices
      .getSessionsListAsUserId(id, page, limit, token)
      .then(resp => {
        if (resp.status) {
          setAllSessions(resp?.data || [])
          setTotalPageCount(resp.totalPages)
        }
      })
      .catch(err => console.log(err))
  }

  const fetchInvoiceList = (id, page, limit) => {
    clientServices
      .getInvoiceListAsUserId(id, page, limit, token)
      .then(resp => {
        setAllSessions(resp?.data || [])
        setTotalPageCount(resp.totalPages)
      })
      .catch(err => console.log(err))
  }

  const fetchDocumentList = (id, page, limit) => {
    clientServices
      .getDocuementListAsUserId(id, page, limit, token)
      .then(resp => {
        setUserDetails(resp?.user || {})
        setAllSessions(resp?.data || [])
        setTotalPageCount(resp.totalPages)
      })
      .catch(err => console.log(err))
  }

  const handleApproveClick = doc => {
    documentServices
      .patchChangeDocumentStatus(
        {
          documentStatus: 'approved',
          userDocumentId: doc.uuid,
          renewalDate: dateValue ? (moment(dateValue).format('YYYY-MM-DD HH:mm:ss')) : 'NOT_ADDED',
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          fetchDocumentList(id, page, LIMIT)
        } else {
          toast.error(resp.message)
        }
        console.log(resp)
      })
      .catch(err => console.log(err))
  }

  const handleExpireClick = doc => {
    documentServices
      .patchChangeDocumentStatus(
        {
          documentStatus: 'expired',
          userDocumentId: doc.uuid
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          fetchDocumentList(id, page, LIMIT)
        } else {
          toast.error(resp.message)
        }
        console.log(resp)
      })
      .catch(err => console.log(err))
  }

  const handleClickReject = doc => {
    console.log(doc)
    setDocumentId(doc.uuid)
    setShowRejectModal(true)
  }

  const fetchRejectClick = () => {
    documentServices
      .patchChangeDocumentStatus(
        {
          documentStatus: 'rejected',
          userDocumentId: documentId,
          rejectionNote: rejectionNote
        },
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          fetchDocumentList(id, page, LIMIT)
          setShowRejectModal(false)
        } else {
          toast.error(resp.message)
          setShowRejectModal(false)
        }
        console.log(resp)
      })
      .catch(err => console.log(err))
  }

  const handleClientNotAppearClick = session => {
    console.log(session)
    clientServices
      .postClientNotAppear(
        {
          session_Client_id: session?.Session_clients[0]?.uuid
        },
        session.uuid,
        token
      )
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          fetchSessionList(id, page, LIMIT, token)
        } else {
          toast.error(resp.message)
        }
      })
      .catch(err => console.log(err))
  }
  document.title = 'Client List | airGP'
  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        <Container fluid>
          {name === 'session' && (
            <BreadCrumb
              title='Session List'
              pageTitle='Client List'
              to={'/client-list'}
            />
          )}

          {name === 'invoice' && (
            <BreadCrumb
              title='Invoice List'
              pageTitle='Client List'
              to={'/client-list'}
            />
          )}

          {name === 'document' && (
            <BreadCrumb
              title='Document List'
              pageTitle='Client List'
              to={'/client-list'}
            />
          )}

          <Row>
            {/* <Col sm={5}> */}
              {/* <div className={'search-box me-2 mb-2 d-inline-block'}>
                <input
                  id='search-bar-0'
                  type='text'
                  className='form-control search /'
                  placeholder={`Search...`}
                />
              </div> */}
            {/* </Col> */}

            <Col>
              <Card>
                <CardHeader className='card-title text-light mb-0'>
                {name === 'session' && (
                  'Session List'
                  )}

                  {name === 'invoice' && (
                  'Invoices List'
                  )}

                  {name === 'document' && (
                       <>
                          <span>
                          Documents List
                          </span>
                          <Button
                              className="ms-3"
                              onClick={() => setuserDetailModal(true)}
                              style={{
                                color: '#fff',
                                backgroundColor: '#272f5d00',
                                borderColor: '#fff'
                              }}
                          >
                              {userDetails?.title} {userDetails?.firstName} {userDetails?.middleName} {userDetails?.lastName}
                          </Button>
                      </>
                  )}
                </CardHeader>
                      <CardBody>
                        <div className='live-preview'>
                          <div className='table-responsive table-card'>
                            {name === 'session' && (
                              <Table className='table-nowrap table-sm mb-0'>
                                <thead className='table-light'>
                                  <tr>
                                    <th scope='col'>ID</th>
                                    <th scope='col'>Organisation</th>
                                    <th scope='col'>Date & Time</th>
                                    <th scope='col'>Hourly Rate</th>
                                    <th scope='col'>Break Duration</th>
                                    <th scope='col'>Session Fee</th>
                                    <th scope='col'>Expenses</th>
                                    <th scope='col'>Pensionable Pay</th>
                                    <th scope='col'>Bonus</th>
                                    <th scope='col'>Total</th>
                                    <th scope='col'>Status</th>
                                    <th scope='col'>Invoice Status</th>
                                    <th scope='col'>Form_a Status</th>
                                    <th scope='col'>Action</th>
                                  </tr>
                                </thead>
                                {allSessions?.length !== 0 ? (
                                  allSessions?.map((session, idx) => {
                                    let amount = 0
                                    session?.expenses?.map(_ => {
                                      amount += +_?.amount
                                    })
                                    return (
                                      <tbody key={`session-list-${idx}`}>
                                        <tr>
                                          <td className='fw-medium'>
                                            <Link to='#' className='fw-medium'>
                                              {`#${session?.uuid.slice(0, 6)}`}
                                            </Link>
                                          </td>
                                          <td>
                                            {session?.organisation?.name ||
                                              'NA'}
                                          </td>
                                          <td>
                                            {moment(session?.start_time).format(
                                              'LLL'
                                            ) || 'NA'}{' '}
                                            -{' '}
                                            {moment(session?.end_time).format(
                                              'LT' || 'NA'
                                            )}
                                          </td>
                                          <td>
                                            £ {session?.hourly_rate || 'NA'}
                                          </td>
                                          <td>
                                            {session?.unpaid_break_duration ||
                                              'NA'}{' '}
                                            min
                                          </td>

                                          <td>
                                            £{' '}
                                            {(
                                              (session?.hourly_rate / 60) *
                                              convertTimeToMinute(
                                                session?.start_time,
                                                session?.end_time,
                                                session?.unpaid_break_duration
                                              )
                                            ).toFixed() || 'NA'}
                                          </td>
                                          <td>£ {amount || '0'}</td>
                                          <td>
                                            £{' '}
                                            {calculatePensionablePay(
                                              (session?.hourly_rate / 60) *
                                                convertTimeToMinute(
                                                  session?.start_time,
                                                  session?.end_time,
                                                  session?.unpaid_break_duration
                                                ),
                                              amount || 0
                                            ) || '0'}
                                          </td>
                                          <td>
                                            £{' '}
                                            {session?.client_bonus_amount ||
                                              '0'}
                                          </td>
                                          <td>
                                            {(
                                              +(session?.hourly_rate / 60) *
                                                convertTimeToMinute(
                                                  session?.start_time,
                                                  session?.end_time,
                                                  session?.unpaid_break_duration
                                                ) +
                                              amount +
                                              +calculatePensionablePay(
                                                (session?.hourly_rate / 60) *
                                                  convertTimeToMinute(
                                                    session?.start_time,
                                                    session?.end_time,
                                                    session?.unpaid_break_duration
                                                  ),
                                                amount || 0
                                              ) +
                                              +session?.client_bonus_amount
                                            ).toFixed(2)}
                                          </td>
                                          <td>
                                            <Badge
                                              color={procedureTypeCodeClass(
                                                session?.status || 'NA'
                                              )}
                                            >
                                              {session?.status || 'NA'}
                                            </Badge>
                                          </td>
                                          <td>
                                            <Badge
                                              color={procedureTypeCodeClass(
                                                session?.invoice
                                                  ?.Invoice_status_intermediates[0]
                                                  ?.invoice_status?.status ||
                                                  'NA'
                                              )}
                                            >
                                              {session?.invoice
                                                ?.Invoice_status_intermediates[0]
                                                ?.invoice_status?.status ||
                                                'NA'}
                                            </Badge>
                                          </td>
                                          <td>
                                            <Badge
                                              color={procedureTypeCodeClass(
                                                session?.form_a[0]?.status ||
                                                  'NA'
                                              )}
                                            >
                                              {session?.form_a[0]?.status ||
                                                'NA'}
                                            </Badge>
                                          </td>
                                          <td>
                                            {/* <button
                                              type='button'
                                              className='btn btn-warning btn-sm'
                                              onClick={() => {
                                                setEditSessionModal(true)
                                                setSelectedSession(session)
                                              }}
                                            >
                                              Edit
                                            </button> */}
                                            <UncontrolledDropdown className='align-self-start message-box-drop'>
                                              <DropdownToggle
                                                className='btn-soft-secondary btn-sm dropdown'
                                                tag='button'
                                              >
                                                <i className='ri-more-2-fill'></i>
                                              </DropdownToggle>
                                              <DropdownMenu>
                                                <DropdownItem
                                                  onClick={() => {
                                                    setEditSessionModal(true)
                                                    setSelectedSession(session)
                                                  }}
                                                >
                                                  Edit
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    clientServices.downloadDocument(session?.form_a[0]?.uuid, 'pensionforma', token)
                                                      .then(resp => fetchPDF(resp, session?.form_a[0]?.form_a_file_path.split('/')[2]))
                                                      .catch(err => console.log(err))
                                                  }
                                                >
                                                  Download Form_a
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>
                                                    clientServices.downloadDocument(session?.invoice?.uuid, 'invoice', token)
                                                      .then(resp => fetchPDF(resp, `invoice-${session?.invoice?.pdf_copy.split('/')[2]}`))
                                                      .catch(err => console.log(err))
                                                  }
                                                >
                                                  Download Invoice
                                                </DropdownItem>
                                                <DropdownItem
                                                  onClick={() =>{
                                                    // handleClientNotAppearClick(
                                                    //   session
                                                    // )
                                                    setSessionObj(session)
                                                    setShowConfirmationModal(true)
                                                  }}
                                                >
                                                  Client Not Appear
                                                </DropdownItem>
                                              </DropdownMenu>
                                            </UncontrolledDropdown>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )
                                  })
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td className='fw-medium'></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No session found</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </Table>
                            )}
                            {name === 'invoice' && (
                              <Table className='table-nowrap table-sm mb-0'>
                                <thead className='table-light'>
                                  <tr>
                                    <th scope='col'>ID</th>
                                    <th scope='col'>Start Time</th>
                                    <th scope='col'>End Time</th>
                                    <th scope='col'>Amount</th>
                                    <th scope='col'>Private Note</th>
                                    <th scope='col'>Status</th>
                                    <th scope='col'>Download</th>
                                  </tr>
                                </thead>
                                {allSessions?.length !== 0 ? (
                                  allSessions?.map((invoice, idx) => {
                                    return (
                                      <tbody key={`invo-list-${idx}`}>
                                        <tr>
                                          <td className='fw-medium'>
                                            <Link to='#' className='fw-medium'>
                                              {`#${invoice?.uuid.slice(0, 6)}`}
                                            </Link>
                                          </td>
                                          <td>
                                            {moment(
                                              invoice?.session?.start_time
                                            )?.format('MMM Do YYYY, h:mm a') ||
                                              'NA'}
                                          </td>
                                          <td>
                                            {moment(
                                              invoice?.session?.end_time
                                            ).format('MMM Do YYYY, h:mm a') ||
                                              'NA'}
                                          </td>
                                          <td>{invoice?.fee || 'NA'}</td>
                                          <td>{invoice?.private_note}</td>
                                          <td>
                                            <Badge
                                              color={procedureTypeCodeClass(
                                                invoice?.Invoice_status_intermediates?.slice(
                                                  -1
                                                )[0]?.invoice_status?.status ||
                                                  'NA'
                                              )}
                                            >
                                              {invoice?.Invoice_status_intermediates?.slice(
                                                -1
                                              )[0]?.invoice_status?.status ||
                                                'NA'}
                                            </Badge>
                                          </td>
                                          <td className='d-flex justify-content-center'>
                                            <i
                                              className='ri-download-line'
                                              onClick={() =>
                                                window.open(
                                                  invoice?.pdf_copy,
                                                  '_blank'
                                                )
                                              }
                                            ></i>
                                          </td>
                                        </tr>
                                      </tbody>
                                    )
                                  })
                                ) : (
                                  <tbody>
                                    <tr>
                                      <td className='fw-medium'></td>
                                      <td></td>
                                      <td></td>
                                      <td></td>
                                      <td>No invoice found</td>
                                      <td></td>
                                      <td></td>
                                    </tr>
                                  </tbody>
                                )}
                              </Table>
                            )}
                            {name === 'document' && (
                              <Table className='table-nowrap table-sm mb-0'>
                                <thead className='table-light'>
                                  <tr>
                                    <th scope='col'>ID</th>
                                    <th scope='col'>Source</th>
                                    <th scope='col'>Document Name</th>
                                    <th scope='col'>Status</th>
                                    <th scope='col'>Expiry Date</th>
                                    <th>
                                      View
                                    </th>
                                    <th scope='col'>Action</th>
                                  </tr>
                                </thead>
                                {allSessions?.map((session, idx) =>
                                  session?.user_clinical_documents !== 0 ? (
                                    session?.user_clinical_documents.map(
                                      (document, index) => {
                                        return (
                                          <tbody key={`${index}`}>
                                            <tr>
                                              <td className='fw-medium'>
                                                <Link
                                                  to='#'
                                                  className='fw-medium'
                                                >
                                                  {`#${session?.uuid.slice(
                                                    0,
                                                    6
                                                  )}`}
                                                </Link>
                                              </td>
                                              <td>
                                                {session?.document_type || 'NA'}
                                              </td>
                                              <td>
                                                {document?.original_file_name ||
                                                  'NA'}
                                              </td>
                                              <td>
                                                <Badge
                                                  color={procedureTypeCodeClass(
                                                    document?.document_statuses.slice(
                                                      -1
                                                    )[0]?.status || 'NA'
                                                  )}
                                                >
                                                  {document?.document_statuses.slice(
                                                    -1
                                                  )[0]?.status || 'NA'}
                                                </Badge>
                                              </td>
                                              <td>
                                              {
                                              
                                              document?.document_statuses.slice(
                                                -1
                                              )[0]?.status !== 'awaiting_approval'?

                                              moment(
                                                  document?.renewal_date
                                              ).format(
                                                  'll'
                                              ) : null
                                              
                                              }
                                              </td>
                                              <td>
                                                <Link to="#">
                                                  <i className="ri-download-line fs-17 lh-1 align-middle"
                                                    onClick={() =>{
                                                      clientServices.downloadDocument(document.uuid, 'userclinicaldocuments', token)
                                                        .then(resp => fetchPDF(resp, document.original_file_name.split('.')[0]))
                                                        .catch(err => console.log(err))
                                                      
                                                    }}></i>
                                                  </Link>
                                                {/* <i
                                                  className='ri-download-line'
                                                  onClick={() =>{
                                                    clientServices.downloadDocument(document.uuid, 'userclinicaldocuments', token)
                                                      .then(resp => fetchPDF(resp, document.original_file_name.split('.')[0]))
                                                      .catch(err => console.log(err))
                                                    
                                                  }}
                                                ></i> */}
                                              </td>
                                              <td>
                                                <UncontrolledDropdown className='align-self-start message-box-drop'>
                                                  <DropdownToggle
                                                    className='btn-soft-secondary btn-sm dropdown'
                                                    tag='button'
                                                  >
                                                    <i className='ri-more-2-fill'></i>
                                                  </DropdownToggle>
                                                  <DropdownMenu>
                                                    <DropdownItem
                                                      onClick={() =>{
                                                        // handleApproveClick(
                                                        //   document
                                                        // )
                                                        setDocumentApprove(document)
                                                        setShowConfirmationModal(true)
                                                      }}
                                                    >
                                                      <i className='ri-check-line me-2 text-success align-bottom'></i>
                                                      Approve
                                                    </DropdownItem>
                                                    <DropdownItem
                                                      onClick={() =>
                                                        handleExpireClick(
                                                          document
                                                        )
                                                      }
                                                    >
                                                      <i className='ri-hourglass-fill me-2 text-warning align-bottom'></i>
                                                      Expire
                                                    </DropdownItem>
                                                    <DropdownItem
                                                      onClick={() =>
                                                        handleClickReject(
                                                          document
                                                        )
                                                      }
                                                    >
                                                      <i className='ri-close-circle-line me-2 text-danger align-bottom'></i>
                                                      Reject
                                                    </DropdownItem>
                                                  </DropdownMenu>
                                                </UncontrolledDropdown>
                                              </td>
                                            </tr>
                                          </tbody>
                                        )
                                      }
                                    )
                                  ) : (
                                    <tbody key={idx}>
                                      <tr>
                                        <td className='fw-medium'></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>No User found</td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    </tbody>
                                  )
                                )}
                              </Table>
                            )}
                          </div>
                        </div>
                      </CardBody>

                <PaginationV2
                  totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                  previousDisabled={page === 1}
                  nextDisabled={totalPageCount === page ? true : false}
                  previousPageCall={() => setPage(page - 1)}
                  currentPage={page}
                  nextPageCalls={() => setPage(page + 1)}
                  onPageInputChange={({ target: { value } }) => {
                    var regex = new RegExp(`^[1-${totalPageCount}]$`)
                    if (value <= totalPageCount && value !== 0) {
                      setPage(value)
                    }
                  }}
                />
              </Card>
            </Col>
          </Row>
        </Container>
        <Modal
          isOpen={showRejectModal}
          toggle={() => {
            tog_varying3()
          }}
        >
          <ModalHeader
            className='modal-title'
            toggle={() => {
              tog_varying3()
            }}
          >
            Write rejection note here
          </ModalHeader>
          <ModalBody>
            <form>
              <div className='mb-3'>
                <label htmlFor='message-text' className='col-form-label'>
                  Rejection note:
                </label>
                <textarea
                  className='form-control'
                  id='message-text'
                  rows='4'
                  onChange={e => setRejectionNote(e.target.value)}
                ></textarea>
              </div>
            </form>
          </ModalBody>
          <div className='modal-footer'>
            <Button
              color='light'
              onClick={() => {
                setShowRejectModal(false)
              }}
            >
              Back
            </Button>
            <Button color='primary' onClick={fetchRejectClick}>
              Reject
            </Button>
          </div>
        </Modal>

        <EditDraftSessionModal
          open={editSessionModal}
          handleClose={() => setEditSessionModal(false)}
          selectedSession={selectedSession}
        />
        
        <UserDetailsModal
          open={userDetailModal}
          handleClose={() => setuserDetailModal(!userDetailModal)}
          toggle={() => setuserDetailModal(!userDetailModal)}
          selectedClient={userDetails}
        />

        <SuccessConfirmationModal
          show={showConfirmationModal}
          enableDatePicker={name === 'document' ? true : false}
          dateTitle={name === 'document' ? true : false}
          title={name === 'document' ? 'Renewal Date' : ''}
          dateChange={(e)=>{
            setDateValue(e.target.value)
          }}
          dateValue={dateValue}
          onDeleteClick={() => {
            if(name === 'document'){
              handleApproveClick(
                documentApprove
              )
            }else{
              handleClientNotAppearClick(
                sessionObj
              )
            }
            setShowConfirmationModal(false)
          }}
          onCloseClick={() => setShowConfirmationModal(false)}
          deleteMsg={name === 'document' ? "Do you want to Approve!": "Do yo want to mark as client not appear"}
          btnName={name === 'document' ? "Approve": "Client not appear"}
        />
      </div>
    </React.Fragment>
  )
}

export default SessionListTable
