import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

const Navdata = () => {
  const history = useHistory()
  //state data
  const [isDashboard, setIsDashboard] = useState(false)
  const [isApps, setIsApps] = useState(false)
  const [isAuth, setIsAuth] = useState(false)
  const [isPages, setIsPages] = useState(false)
  const [isBaseUi, setIsBaseUi] = useState(false)
  const [isAdvanceUi, setIsAdvanceUi] = useState(false)
  const [isForms, setIsForms] = useState(false)
  const [isTables, setIsTables] = useState(false)
  const [isCharts, setIsCharts] = useState(false)
  const [isIcons, setIsIcons] = useState(false)
  const [isMaps, setIsMaps] = useState(false)
  const [isMultiLevel, setIsMultiLevel] = useState(false)
  const [isLanding, setIsLanding] = useState(false)
  const [iscurrentState, setIscurrentState] = useState('Dashboard')

  function updateIconSidebar (e) {
    if (e && e.target && e.target.getAttribute('subitems')) {
      const ul = document.getElementById('two-column-menu')
      const iconItems = ul.querySelectorAll('.nav-icon.active')
      let activeIconItems = [...iconItems]
      activeIconItems.forEach(item => {
        item.classList.remove('active')
        var id = item.getAttribute('subitems')
        if (document.getElementById(id))
          document.getElementById(id).classList.remove('show')
      })
    }
  }

  useEffect(() => {
    document.body.classList.remove('twocolumn-panel')
    if (iscurrentState !== 'Dashboard') {
      setIsDashboard(false)
    }
    if (iscurrentState !== 'Apps') {
      setIsApps(false)
    }
    if (iscurrentState !== 'Auth') {
      setIsAuth(false)
    }
    if (iscurrentState !== 'Pages') {
      setIsPages(false)
    }
    if (iscurrentState !== 'BaseUi') {
      setIsBaseUi(false)
    }
    if (iscurrentState !== 'AdvanceUi') {
      setIsAdvanceUi(false)
    }
    if (iscurrentState !== 'Forms') {
      setIsForms(false)
    }
    if (iscurrentState !== 'Tables') {
      setIsTables(false)
    }
    if (iscurrentState !== 'Charts') {
      setIsCharts(false)
    }
    if (iscurrentState !== 'Icons') {
      setIsIcons(false)
    }
    if (iscurrentState !== 'Maps') {
      setIsMaps(false)
    }
    if (iscurrentState !== 'MuliLevel') {
      setIsMultiLevel(false)
    }
    if (iscurrentState === 'Widgets') {
      history.push('/widgets')
      document.body.classList.add('twocolumn-panel')
    }
    if (iscurrentState !== 'Landing') {
      setIsLanding(false)
    }
  }, [
    history,
    iscurrentState,
    isDashboard,
    isApps,
    isAuth,
    isPages,
    isBaseUi,
    isAdvanceUi,
    isForms,
    isTables,
    isCharts,
    isIcons,
    isMaps,
    isMultiLevel
  ])

  const menuItems = [
    {
      label: 'Menu',
      isHeader: true
    },
    {
      id: 'Documents',
      label: 'Documents',
      icon: 'ri-file-list-2-line',
      link: '/documents',
      click: function (e) {
        e.preventDefault()
        setIsApps(!isApps)
        setIscurrentState('Apps')
        updateIconSidebar(e)
      },
      stateVariables: isApps
    },
    {
      id: 'Client',
      label: 'Clients',
      icon: 'ri-group-line',
      link: '/client-list',
      click: function (e) {
        e.preventDefault()
        setIsAuth(!isAuth)
        setIscurrentState('Auth')
        updateIconSidebar(e)
      },
      // subItems: [
      //   {
      //     id: 'pm-invitation',
      //     label: 'Client List',
      //     link: '/client-list',
      //     parentId: 'Invitation'
      //   },
      //   {
      //     id: 'gp-invitation',
      //     label: 'GP Invitation',
      //     link: '/gp-invitation',
      //     parentId: 'Invitation'
      //   }
      // ],
      stateVariables: isAuth
    },
    {
      id: 'Practice manager',
      label: 'Practice manager',
      icon: 'ri-user-2-line',
      link: '/practice-manager',
      click: function (e) {
        e.preventDefault()
        setIsForms(!isForms)
        setIscurrentState('Forms')
        updateIconSidebar(e)
      },
      // subItems: [
      //   {
      //     id: 'pm-invitation',
      //     label: 'Practice manager List',
      //     link: '/practice-manager',
      //     parentId: 'Invitation'
      //   },
      //   {
      //     id: 'pm-invitation',
      //     label: 'PM Invitation',
      //     link: '/pm-invitation',
      //     parentId: 'Invitation'
      //   }
      // ],
      stateVariables: isForms
    },
    {
      id: 'Organisations',
      label: 'Organisations',
      icon: 'ri-home-4-line',
      link: '/organisations',
      click: function (e) {
        e.preventDefault()
        setIsTables(!isTables)
        setIscurrentState('Tables')
        updateIconSidebar(e)
      },
      stateVariables: isTables
    },
    {
      id: 'PM Applied Org',
      label: 'Applications',
      icon: ' ri-file-add-line',
      link: '/pm-applications',
      click: function (e) {
        e.preventDefault()
        setIsTables(!isTables)
        setIscurrentState('Tables')
        updateIconSidebar(e)
      },
      stateVariables: isTables
    },
    {
      id: 'Invoices',
      label: 'Invoices',
      icon: 'ri-money-pound-box-line',
      link: '/pm-invoices',
      click: function (e) {
        e.preventDefault()
        setIsTables(!isTables)
        setIscurrentState('Tables')
        updateIconSidebar(e)
      },
      stateVariables: isTables
    },
    {
      id: 'Sessions',
      label: 'Sessions',
      icon: 'ri-calendar-2-line',
      link: '/sessions',
      click: function (e) {
        e.preventDefault()
        setIsTables(!isTables)
        setIscurrentState('Tables')
        updateIconSidebar(e)
      },
      stateVariables: isTables
    },
    {
      id: 'Invitation',
      label: 'Invitation',
      icon: ' ri-file-add-line',
      click: function (e) {
        e.preventDefault()
        setIsTables(!isTables)
        setIscurrentState('Tables')
        updateIconSidebar(e)
      },
      subItems: [
        {
          id: 'gp-invitation',
          label: 'GP Invitation',
          link: '/PM/gp-invitation',
          parentId: 'Invitation'
        },
        {
          id: 'pm-invitation',
          label: 'PM Invitation',
          link: '/PM/pm-invitation',
          parentId: 'Invitation'
        }
      ],
      stateVariables: isTables
    }
  ]
  return <React.Fragment>{menuItems}</React.Fragment>
}
export default Navdata
