import { customHeaders } from './authServices'
import {
  GET_ORGANISATIONS_STATUS_SUPER_ADMIN,
  GET_PM_APPLIED_ORGANISATIONS_SUPER_ADMIN,
  PATCH_ORGANISATIONS_STATUS_CHANGE_SUPER_ADMIN
} from './endpoints'

export const applicationServices = {
  // Get all PM List
  getPMAppliedOrganisationsListSuperAdmin: (token, page, limit) =>
    fetch(GET_PM_APPLIED_ORGANISATIONS_SUPER_ADMIN.endpoint(page, limit), {
      method: GET_PM_APPLIED_ORGANISATIONS_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get all Org status List
  getOrganisationStatusList: token =>
    fetch(GET_ORGANISATIONS_STATUS_SUPER_ADMIN.endpoint, {
      method: GET_ORGANISATIONS_STATUS_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get all Org status List
  patchChangeOrganisationStatusList: (data, token) =>
    fetch(PATCH_ORGANISATIONS_STATUS_CHANGE_SUPER_ADMIN.endpoint, {
      method: PATCH_ORGANISATIONS_STATUS_CHANGE_SUPER_ADMIN.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err))
}
