import React, { useState, useEffect, useCallback } from 'react'
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Label,
  Badge
} from 'reactstrap'
import BreadCrumb from '../../../Components/Common/BreadCrumb'
import UiContent from '../../../Components/Common/UiContent'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { organisationServices } from '../../../services/organisationServices'
import Autocomplete from 'react-google-autocomplete'
import { toast } from 'react-toastify'
import PaginationV2 from '../../../Components/Common/PaginationV2'
import SpinnerComponent from '../../../Components/Common/Spinner'

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { procedureTypeCodeClass } from '../../../utils/constants'

const ADD_ORG_INITIAL_VALUES = {
  OrganisationName: '',
  OrganisationCode: '',
  NationalGrouping: '',
  HighLevelHealthGeography: '',
  Status: '',
  postcode: '',
  EACode:'',
  AddressLine_1: '',
  AddressLine_2: '',
  AddressLine_3: '',
  AddressLine_4: '',
  AddressLine_5: ''
}

const OrganisationListTable = () => {
  document.title = 'Organisations List | AirGP'
  const [page, setPage] = useState(1)
  const [organisationList, setOrganistionList] = useState([])
  const [allRelatedDirectDebits, setAllRelatedDirectDebits] = useState([])
  const [totalPageCount, setTotalPageCount] = useState(1)
  const [toggleDirectDebitList, setToggleDirectDebitList] = useState(false)
  const [showAddOrganisationModal, setShowOrganisationsModal] = useState(false)
  const [serviceFeeRateModal, setServiceFeeRateModal] = useState(false)
  const [organisationUuid, setOrganisationUuid] = useState('')
  const [fetchOrganisationState, setFetchOrganisationState] = useState('all')
  const [feePercentage, setFeePercentage] = useState('')
  const [addOrgValues, setAddOrgValues] = useState(ADD_ORG_INITIAL_VALUES)
  const [isLoading , setIsLoading] = useState(false)
  const token = localStorage.getItem('token')
  const history = useHistory()

  let orgId = new URLSearchParams(document.location.search).get('id')

  useEffect(() => {
    if(fetchOrganisationState) fetchOrganisationList(fetchOrganisationState)
  }, [page, fetchOrganisationState])

  useEffect(() => {
    if (orgId) {
      fetchSearchOrganisation({ target: { value: orgId } })
    }
  }, [orgId])

  const fetchOrganisationList = (registered) => {
    setIsLoading(true)
    organisationServices
      .getOrganisationsListSuperAdmin('', page, 25, token, registered)
      .then(resp => {
        if (resp.success) {
          setOrganistionList(resp.data || [])
          setTotalPageCount(resp.totalPages || 1)
        }else{
          if(resp?.message === "Unauthorised"){
            localStorage.clear();
            history.push('/login')
          }
        }
      })
      .catch(err => console.log(err))
      .finally(() => setIsLoading(false))
  }

  const fetchSearchOrganisation = e => {
    const { value } = e.target

    organisationServices
      .getOrganisationsListSuperAdmin(value, page, 25, token, fetchOrganisationState)
      .then(resp => {
        if (resp.success) {
          setOrganistionList(resp.data || [])
          setTotalPageCount(resp.totalPages || 1)
        }
      })
      .catch(err => console.log(err))
  }

  const debouncingSearchFunction = func => {
    let timer
    return function (...args) {
      const context = this
      console.log(context, args)
      clearTimeout(timer)
      if (timer) clearTimeout(timer)
      timer = setTimeout(() => {
        timer = null
        func.apply(context, args)
      }, 300)
    }
  }

  const handleOnKeyUpSearchBox = useCallback(
    debouncingSearchFunction(fetchSearchOrganisation),
    []
  )

  const orgnisationValuesChanges = event => {
    let copuedAddOrgValues = { ...addOrgValues }

    const name = event.target.name
    const value = event.target.value

    copuedAddOrgValues[name] = value

    setAddOrgValues(copuedAddOrgValues)
  }

  const handlePlaceSelect = place => {
    console.log(place)
  }

  const handleCreateOrgClick = (values) => {
    const data = {
      name: values.OrganisationName,
      organisation_code: values.OrganisationCode,
      national_grouping: values.NationalGrouping,
      high_level_health_geography: values.HighLevelHealthGeography,
      address_line_1: values.AddressLine_1,
      address_line_2: values.AddressLine_2,
      address_line_3: values.AddressLine_3,
      address_line_4: values.AddressLine_4,
      address_line_5: values.AddressLine_5,
      postcode: values.postcode,
      status_code: values.Status,
      EA_code: values.EACode
    }

    organisationServices
      .postAddNewOrganisaiton(data, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setShowOrganisationsModal(false)
        } else {
          toast.error(resp.message)
          setShowOrganisationsModal(false)
        }
        console.log(resp)
      })
      .catch(err => console.log(err))
  }

  const validationSchema = Yup.object().shape({
    OrganisationName: Yup.string().required('Organisation Name is required'),
    OrganisationCode: Yup.string().required('Organisation Code is required'),
    NationalGrouping: Yup.string().required('National Grouping is required'),
    HighLevelHealthGeography: Yup.string().required('High Level Health Geography is required'),
    EACode: Yup.string().required('EACode is required'),
    Status: Yup.string().required('Status is required'),
    postcode: Yup.string().required('Postcode is required'),
    AddressLine_1: Yup.string().required('Address Line 1 is required'),
    AddressLine_2: Yup.string().required('Address Line 2 is required'),
    AddressLine_3: Yup.string().required('Address Line 3 is required'),
    AddressLine_4: Yup.string().required('Address Line 4 is required'),
    AddressLine_5: Yup.string().required('Address Line 5 is required'),
  });

  const formik = useFormik({
    initialValues: ADD_ORG_INITIAL_VALUES,
    validationSchema,
    onSubmit: (values) => {
      // Handle form submission here
      handleCreateOrgClick(values);
    },
  });

  const handleDirectDebitApprove = (status, org_id, user_id, mandate_id) => {
    if (mandate_id) {
      organisationServices
        .postUpdateDirectDebit({ status: status, org_id: org_id, user_id: user_id, mandateId: mandate_id }, token)
        .then(resp => {
          if (resp.success) {
            toast.success(resp.message)
            setToggleDirectDebitList(false)
            fetchOrganisationList()
          } else {
            toast.error(resp.message)
            setToggleDirectDebitList(false)
          }
        })
        .catch(err => console.log(err))
    } else {
      toast.error("Please enter mandate id.")
    }
  }

  const handleSetFeePercentage = () => {
    organisationServices
      .postSetFeePercentage({ uuid: organisationUuid, feePercentage: feePercentage }, token)
      .then(resp => {
        if (resp.success) {
          toast.success(resp.message)
          setServiceFeeRateModal(false)
          fetchOrganisationList()
        } else {
          toast.error(resp.message)
          setServiceFeeRateModal(false)
        }
      })
      .catch(err => console.log(err))
  }

  const handleMandateIdChange = (event, idx) => {
    const copiedArray = [...allRelatedDirectDebits]
    copiedArray[idx].mandate_id = event.target.value
    setAllRelatedDirectDebits(copiedArray)
  }

  return (
    <div>
      <React.Fragment>
        {isLoading && <SpinnerComponent /> }
        <UiContent />
        <div className='page-content'>
          <Container fluid>
            <Row>
              <Col>
                <Card>
                  <CardHeader className='card-title text-light mb-0'>

                    <div className='d-flex float-end'>
                      <div className={'search-box me-2 mb-2 d-inline-block'}>
                        <input
                          onKeyUp={handleOnKeyUpSearchBox}
                          id='search-bar-0'
                          type='text'
                          className='form-control search'
                          placeholder={`Search...`}
                        />
                        <i className='ri-search-line search-icon'></i>
                      </div>
                      <UncontrolledDropdown className='align-self-start message-box-drop me-2 mb-2 w-50'>
                        <DropdownToggle
                          className='btn btn-light dropdown d-flex justify-content-center align-item-center w-100'
                          tag='button'
                          caret
                        >
                          Filter Organisation By
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              setPage(1);
                              setFetchOrganisationState('all')
                          }}
                          >
                            All Organisations
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPage(1);
                              setFetchOrganisationState('true')
                          }}
                          >
                            Registered
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              setPage(1);
                              setFetchOrganisationState('false')
                          }}
                          >
                            Unregistered
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                      <button
                        className='btn btn-light w-50 h-50'
                        id='btn-new-event'
                        onClick={() => setShowOrganisationsModal(true)}
                      >
                        Add Organisation
                      </button>
                    </div>
                    <p className='pt-2'>Organisations</p>
                  </CardHeader>
                  <CardBody>
                    <div className='live-preview'>
                      <div className='table-responsive table-card'>
                        <Table className='align-middle table-nowrap table-sm mb-0'>
                          <thead className='table-light'>
                            <tr>
                              <th scope='col'>Name</th>
                              <th scope='col'>Address</th>
                              <th scope='col'>Postcode</th>
                              <th scope='col'>Phone No</th>
                              <th scope='col'>Organisation Code</th>
                              <th scope='col'>EA Code</th>
                              <th scope='col'>PCN Code</th>
                              <th scope='col'>PCN Name</th>
                              <th scope='col'>Pension Type</th>
                              <th scope='col'>Registered</th>
                              <th scope='col'>Service Charge</th>
                              <th scope='col'>Patient Count</th>
                              <th scope='col'>Action</th>
                            </tr>
                          </thead>
                          {organisationList.length !== 0 ? (
                            organisationList.map((_, index) => {
                              console.log(_)
                              return (
                                <tbody key={`admin-${index}`}>
                                  <tr>
                                    <td>{_?.name || 'NA'}</td>
                                    <td>{_?.address_line_1 || 'NA'}</td>
                                    <td>{_?.postcode || 'NA'}</td>
                                    <td>
                                      {_?.contact_telephone_number ||
                                        'NA'}
                                    </td>
                                    <td>
                                      {_?.organisation_code || 'NA'}
                                    </td>
                                    <td>
                                      {_?.EA_code || 'NA'}
                                    </td>
                                    <td>
                                      {_?.PCN_code || 'NA'}
                                    </td>
                                    <td>
                                      {_?.PCN_name || 'NA'}
                                    </td>
                                    <td>
                                      {_?.pension_type || 'NA'}
                                    </td>
                                    <td>
                                    <Badge
                                        color={procedureTypeCodeClass(
                                            (_?.registered === true
                                                ? "registered"
                                                : "unregistered") || "NA"
                                        )}
                                    >
                                        {(_?.registered === true
                                            ? "Registered"
                                            : "Unregistered") || "NA"}
                                    </Badge>
                                    </td>
                                    <td>
                                      {_?.service_fee_percentage || 'NA'}
                                    </td>
                                    <td>
                                      {_?.number_of_patients || 'NA'}
                                    </td>
                                    <td>
                                      <UncontrolledDropdown className='align-self-start message-box-drop'>
                                        <DropdownToggle
                                          className='btn-soft-secondary btn-sm dropdown'
                                          tag='button'
                                        >
                                          <i className='ri-more-2-fill'></i>
                                        </DropdownToggle>
                                        <DropdownMenu>
                                          <DropdownItem
                                            onClick={() =>
                                              history.push(
                                                `/staff-list?orgId=${_?.uuid}`
                                              )
                                            }
                                          >
                                            <i className='ri-group-line me-2 text-muted align-bottom'></i>
                                            View Staff
                                          </DropdownItem>
                                          <DropdownItem
                                            onClick={() =>
                                              history.push(
                                                `/pm-list?orgId=${_?.uuid}`
                                              )
                                            }
                                          >
                                            <i className='ri-group-line me-2 text-muted align-bottom'></i>
                                            View PM List
                                          </DropdownItem>
                                          {_?.debits?.length !== 0 ? <DropdownItem
                                            onClick={() => {
                                              setAllRelatedDirectDebits(_?.debits)
                                              setToggleDirectDebitList(true)
                                            }}
                                            disabled={_?.pension_organisation && _?.pension_organisation?.claim_pension_status ? false : true}
                                          >
                                            <i className='ri-money-pound-circle-line me-2 text-muted align-bottom'></i>
                                            Set Direct Debit
                                          </DropdownItem> : ''}
                                          <DropdownItem
                                            onClick={() => {
                                              setServiceFeeRateModal(true)
                                              setOrganisationUuid(_?.uuid)
                                              setFeePercentage(_?.service_fee_percentage * 100)
                                            }}
                                          >
                                            <i className='ri-vip-diamond-line me-2 text-muted align-bottom'></i>
                                            Set Service Fee Rate
                                          </DropdownItem>
                                        </DropdownMenu>
                                      </UncontrolledDropdown>
                                    </td>
                                  </tr>
                                </tbody>
                              )
                            })
                          ) : (
                            <tbody>
                              <tr>
                                <td className='fw-medium'></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>No User found</td>
                                <td></td>
                                <td></td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                      </div>
                    </div>
                  </CardBody>
                  <PaginationV2
                    totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                    previousDisabled={page === 1}
                    nextDisabled={totalPageCount === page ? true : false}
                    previousPageCall={() => setPage(page - 1)}
                    currentPage={page}
                    nextPageCalls={() => setPage(page + 1)}
                    onPageInputChange={({ target: { value } }) => {
                      var regex = new RegExp(`^[1-${totalPageCount}]$`)
                      if (value <= totalPageCount && value !== 0) {
                        setPage(value)
                      }
                    }}
                  />
                </Card>
              </Col>
            </Row>
          </Container>
        </div>

        <Modal
          isOpen={showAddOrganisationModal}
          toggle={() => setShowOrganisationsModal(!showAddOrganisationModal)}
          scrollable={true}
        >
          <ModalHeader
            className='modal-title'
            toggle={() => setShowOrganisationsModal(!showAddOrganisationModal)}
          >
            Create New Organisation
          </ModalHeader>
          <ModalBody>
            <form onSubmit={formik.handleSubmit}>
              <div className='row g-3'>
                <Col xxl={6}>
                  <div>
                    <label htmlFor='firstName' className='form-label'>
                      Organisation Name
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='firstName'
                      placeholder='Enter Organisation Name'
                      name='OrganisationName'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.OrganisationName}
                    />
                    {formik.touched.OrganisationName && formik.errors.OrganisationName && (
                      <div className='text-danger'>{formik.errors.OrganisationName}</div>
                    )}
                  </div>
                </Col>
                <Col xxl={6}>
                  <div>
                    <label htmlFor='lastName' className='form-label'>
                      Organisation Code
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      name='OrganisationCode'
                      type='text'
                      className='form-control'
                      id='lastName'
                      placeholder='Enter Organisation Code'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.OrganisationCode}
                    />
                    {formik.touched.OrganisationCode && formik.errors.OrganisationCode && (
                      <div className='text-danger'>{formik.errors.OrganisationCode}</div>
                    )}
                  </div>
                </Col>
                <Col xxl={6}>
                  <div>
                    <label htmlFor='NationalGrouping' className='form-label'>
                      National Grouping
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='NationalGrouping'
                      placeholder='Enter National Grouping'
                      name='NationalGrouping'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.NationalGrouping}
                    />
                    {formik.touched.NationalGrouping && formik.errors.NationalGrouping && (
                      <div className='text-danger'>{formik.errors.NationalGrouping}</div>
                    )}
                  </div>
                </Col>
                <Col xxl={6}>
                  <div>
                    <label htmlFor='HighLevelHealthGeography' className='form-label'>
                      High Level Health Geography
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='HighLevelHealthGeography'
                      placeholder='Enter High Level Health Geography'
                      name='HighLevelHealthGeography'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.HighLevelHealthGeography}
                    />
                    {formik.touched.HighLevelHealthGeography && formik.errors.HighLevelHealthGeography && (
                      <div className='text-danger'>{formik.errors.HighLevelHealthGeography}</div>
                    )}
                  </div>
                </Col>
                <Col xxl={6}>
                  <div>
                    <label htmlFor='EACode' className='form-label'>
                      EA Code
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='EACode'
                      placeholder='Enter EA_CODE'
                      name='EACode'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.EACode}
                    />
                    {formik.touched.EACode && formik.errors.EACode && (
                      <div className='text-danger'>{formik.errors.EACode}</div>
                    )}
                  </div>
                </Col>
                <Col xxl={6}>
                  <div>
                    <label htmlFor='lastName' className='form-label'>
                      Status
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='Status'
                      placeholder='Enter Status'
                      name='Status'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.Status}
                    />
                    {formik.touched.Status && formik.errors.Status && (
                      <div className='text-danger'>{formik.errors.Status}</div>
                    )}
                  </div>
                </Col>

                <Col xxl={6}>
                  <div>
                    <label htmlFor='postcode' className='form-label'>
                      Postcode
                      <span className='text-danger'>*</span>
                    </label>
                    <Autocomplete
                      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
                      className='form-control google-autocomplete-class'
                      onPlaceSelected={place => {
                        handlePlaceSelect(place);
                      }}
                      inputValue={['address']}
                      componentrestrictions={{ country: 'GB' }}
                      name='postcode'
                      libraries={['places']}
                      placeholder='Enter postcode *'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.postcode}
                      required
                    />
                    {formik.touched.postcode && formik.errors.postcode && (
                      <div className='text-danger'>{formik.errors.postcode}</div>
                    )}
                  </div>
                </Col>
                <Col xxl={6}>
                  <div>
                    <label htmlFor='AddressLine_1' className='form-label'>
                      Address Line 1
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='AddressLine_1'
                      placeholder='Enter Address Line 1'
                      name='AddressLine_1'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.AddressLine_1}
                    />
                    {formik.touched.AddressLine_1 && formik.errors.AddressLine_1 && (
                      <div className='text-danger'>{formik.errors.AddressLine_1}</div>
                    )}
                  </div>
                </Col>

                <Col xxl={6}>
                  <div>
                    <label htmlFor='AddressLine_2' className='form-label'>
                      Address Line 2
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='AddressLine_2'
                      placeholder='Enter Address Line 2'
                      name='AddressLine_2'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.AddressLine_2}
                    />
                    {formik.touched.AddressLine_2 && formik.errors.AddressLine_2 && (
                      <div className='text-danger'>{formik.errors.AddressLine_2}</div>
                    )}
                  </div>
                </Col>

                <Col xxl={6}>
                  <div>
                    <label htmlFor='AddressLine_3' className='form-label'>
                      Address Line 3
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='AddressLine_3'
                      placeholder='Enter Address Line 3'
                      name='AddressLine_3'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.AddressLine_3}
                    />
                    {formik.touched.AddressLine_3 && formik.errors.AddressLine_3 && (
                      <div className='text-danger'>{formik.errors.AddressLine_3}</div>
                    )}
                  </div>
                </Col>

                <Col xxl={6}>
                  <div>
                    <label htmlFor='AddressLine_4' className='form-label'>
                      Address Line 4
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='AddressLine_4'
                      placeholder='Enter Address Line 4'
                      name='AddressLine_4'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.AddressLine_4}
                    />
                    {formik.touched.AddressLine_4 && formik.errors.AddressLine_4 && (
                      <div className='text-danger'>{formik.errors.AddressLine_4}</div>
                    )}
                  </div>
                </Col>

                <Col xxl={6}>
                  <div>
                    <label htmlFor='AddressLine_5' className='form-label'>
                      Address Line 5
                      <span className='text-danger'>*</span>
                    </label>
                    <Input
                      type='text'
                      className='form-control'
                      id='AddressLine_5'
                      placeholder='Enter Address Line 5'
                      name='AddressLine_5'
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.AddressLine_5}
                    />
                    {formik.touched.AddressLine_5 && formik.errors.AddressLine_5 && (
                      <div className='text-danger'>{formik.errors.AddressLine_5}</div>
                    )}
                  </div>
                </Col>

              </div>
            </form>

          </ModalBody>
          <Col xxl={12}>
            <div className='modal-footer'>
              <Button
                color='light'
                onClick={() => setShowOrganisationsModal(false)}
              >
                Close
              </Button>
              <Button color='primary' type='submit' disabled={!formik.isValid} onClick={() => formik.handleSubmit()}>
                Create
              </Button>
            </div>
          </Col>

        </Modal>

        <Modal
          isOpen={toggleDirectDebitList}
          toggle={() => setToggleDirectDebitList(!toggleDirectDebitList)}
          // scrollable={true}
          size="lg"
        >
          <ModalHeader
            className='modal-title'
            toggle={() => setToggleDirectDebitList(!toggleDirectDebitList)}
          >
            Set Direct Debit
          </ModalHeader>
          <ModalBody>
            <div className="live-preview">
              <div className="table-responsive">
                <Table className="align-middle table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Status</th>
                      <th scope="col">Mandate Id</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allRelatedDirectDebits?.map((item, idx) => {
                      return <tr key={idx}>
                        <td>{item?.User?.firstName + " " + item?.User?.lastName}</td>
                        <td>{item?.User?.email}</td>
                        <td className={`${item?.mandate_status == "approved" ? "text-success" : "text-danger"} text-capitalize`}>{item?.mandate_status || "NA"}</td>
                        <td><Input type="text" className="form-control" value={item?.mandate_id || "NA"} onChange={(e) => handleMandateIdChange(e, idx)} /> </td>
                        <td><UncontrolledDropdown className='align-self-start message-box-drop'>
                          <DropdownToggle
                            className='btn nav-btn'
                            tag='a'
                          >
                            <Button
                            >
                              Action
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                handleDirectDebitApprove('approved', item?.org_id, item?.user_id, item?.mandate_id)
                              }}
                            >
                              <i className='bx bx-briefcase-alt me-2 text-muted align-bottom'></i>
                              Approve
                            </DropdownItem>
                            <DropdownItem
                              onClick={() => {
                                handleDirectDebitApprove('disabled', item?.org_id, item?.user_id, item?.mandate_id)
                              }}
                            >
                              <i className='bx bx-briefcase-alt me-2 text-muted align-bottom'></i>
                              Disable
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown></td>
                      </tr>
                    })}
                  </tbody>
                </Table>

              </div>

            </div>
          </ModalBody>
          <div className='modal-footer'>
            <Button
              color='light'
              onClick={() => {
                setToggleDirectDebitList(!toggleDirectDebitList)
              }}
            >
              Close
            </Button>
          </div>
        </Modal>

        <Modal
          isOpen={serviceFeeRateModal}
          toggle={() => setServiceFeeRateModal(!serviceFeeRateModal)}
        // scrollable={true}
        // size="lg"
        >
          <ModalHeader
            className='modal-title'
            toggle={() => setServiceFeeRateModal(!serviceFeeRateModal)}
          >
            Set Fee Percentage
          </ModalHeader>
          <ModalBody>
            <Row className='justify-content-center align-items-center'>
              <Col className='fw-bold'>
                Service fee Percentage :
              </Col>
              <Col>
                <Input
                  type='text'
                  className='form-control'
                  id='fee-percentage'
                  placeholder='Enter Fee Percentage'
                  name='FeePercentage'
                  value={Number(feePercentage).toFixed()}
                  onChange={(e) => {
                    setFeePercentage(e.target.value)
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <div className='modal-footer'>
            <Button
              color='primary'
              disabled={!(feePercentage)}
              onClick={() => {
                handleSetFeePercentage();
              }}
            >
              Submit
            </Button>
            <Button
              color='light'
              onClick={() => {
                setServiceFeeRateModal(!serviceFeeRateModal)
              }}
            >
              Close
            </Button>
          </div>
        </Modal>
      </React.Fragment>
    </div>
  )
}

export default OrganisationListTable
