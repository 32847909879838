import { customHeaders } from './authServices'
import {
  GET_DOCUMETN_AS_CLIENT_SUPER_ADMIN,
  GET_INVOICES_AS_CLIENT_SUPER_ADMIN,
  GET_PERFORMER_LIST,
  GET_SESSION_AS_CLIENT_SUPER_ADMIN,
  GET_TEMPLATES,
  GET_USERS_LIST_SUPER_ADMIN,
  POST_CLIENT_NOT_APPEAR,
  POST_SEND_INVITATION,
  POST_SEND_PM_INVITATION,
  POST_SUSPEND_CLIENT,
  POST_UPDATE_BONUS_PERCENTAGE,
  DOWNLOAD_DOCUMENT,
  GET_USERS_LIST_PROFILE_SUPER_ADMIN
} from './endpoints'

export const clientServices = {
  // Get all users List
  getUsersListSuperAdmin: (key, page, limit, token) =>
    fetch(GET_USERS_LIST_SUPER_ADMIN.endpoint(key, page, limit), {
      method: GET_USERS_LIST_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get all users List Profile
  getUsersListProfile: (key, page, limit, token) =>
    fetch(GET_USERS_LIST_PROFILE_SUPER_ADMIN.endpoint(key, page, limit), {
      method: GET_USERS_LIST_PROFILE_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get all session List as users
  getSessionsListAsUserId: (id, page, limit, token) =>
    fetch(GET_SESSION_AS_CLIENT_SUPER_ADMIN.endpoint(id, page, limit), {
      method: GET_SESSION_AS_CLIENT_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get all Invoices List as users
  getInvoiceListAsUserId: (id, page, limit, token) =>
    fetch(GET_INVOICES_AS_CLIENT_SUPER_ADMIN.endpoint(id, page, limit), {
      method: GET_INVOICES_AS_CLIENT_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get all Document List as users
  getDocuementListAsUserId: (id, page, limit, token) =>
    fetch(GET_DOCUMETN_AS_CLIENT_SUPER_ADMIN.endpoint(id, page, limit), {
      method: GET_DOCUMETN_AS_CLIENT_SUPER_ADMIN.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Get all Document List as users
  getPerformerList: (
    token,
    prefix,
    performer_role,
    status,
    probationary_period,
    page,
    limit
  ) =>
    fetch(
      GET_PERFORMER_LIST.endpoint(
        prefix,
        performer_role,
        status,
        probationary_period,
        page,
        limit
      ),
      {
        method: GET_PERFORMER_LIST.method,
        headers: customHeaders(token)
      }
    )
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  getTemplatesData: token =>
    fetch(GET_TEMPLATES.endpoint, {
      method: GET_TEMPLATES.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  // Send Invitaion
  postSendInvitation: (data, token) =>
    fetch(POST_SEND_INVITATION.endpoint, {
      method: POST_SEND_INVITATION.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postSendPMInvitation: (data, token) =>
    fetch(POST_SEND_PM_INVITATION.endpoint, {
      method: POST_SEND_PM_INVITATION.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postUpdateBonusPercentage: (data, id, token) =>
    fetch(POST_UPDATE_BONUS_PERCENTAGE.endpoint(id), {
      method: POST_UPDATE_BONUS_PERCENTAGE.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postSuspendClient: (id, suspend, token) =>
    fetch(POST_SUSPEND_CLIENT.endpoint(id, suspend), {
      method: POST_SUSPEND_CLIENT.method,
      headers: customHeaders(token)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  postClientNotAppear: (data, id, token) =>
    fetch(POST_CLIENT_NOT_APPEAR.endpoint(id), {
      method: POST_CLIENT_NOT_APPEAR.method,
      headers: customHeaders(token),
      body: typeof data === 'string' ? data : JSON.stringify(data)
    })
      .then(resp => {
        return resp.json()
      })
      .catch(err => console.log(err)),

  downloadDocument: (pdf_uuid, status, token) =>
    fetch(DOWNLOAD_DOCUMENT.endpoint(pdf_uuid, status), {
      method: DOWNLOAD_DOCUMENT.method,
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
      .then(resp => {
        return resp
      })
      .catch(err => console.log(err)),
}
