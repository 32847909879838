import React, { useState } from "react";
import { useEffect } from "react";
import {
    Badge,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Row,
    Table,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap";
import PaginationV2 from "../../Components/Common/PaginationV2";
import { sessionServices } from "../../services/sessionSrvices";
import classnames from "classnames";
import moment from "moment";
import { badgeColor, convertTimeToMinute, SESSIONS_TABS } from "../../utils/constants";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import SpinnerComponent from "../../Components/Common/Spinner";
import { useLocation } from "react-router-dom";
import ClientDetailModal from "../../Components/Common/ClientDetailModal";
import DownloadDocumentsModal from "../../Components/Common/DownloadDocumentModal";

let LIMIT = 20;

const Sessions = () => {
    const [topBorderjustifyTab, settopBorderjustifyTab] = useState(1);
    const [totalPageCount, setTotalPageCount] = useState(1);
    const [page, setPage] = useState(1);
    const [sessionList, setSessionList] = useState([]);
    const [showAppliedClientDetailModal, setShowAppliedClientDetailModal] = useState(false);
    const [appliedClientsData, setAppliedClientData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [downloadModal, setDownloadModal] = useState(false);
    const [documentList, setDocumentList] = useState([]);

    const token = localStorage.getItem("token");

    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        if (topBorderjustifyTab === 0) {
            fetchPMSessions("all", token, page, LIMIT);
        } else if (topBorderjustifyTab === 1) {
            fetchPMSessions("published", token, page, LIMIT);
        } else if (topBorderjustifyTab === 2) {
            fetchPMSessions("booked", token, page, LIMIT);
        } else if (topBorderjustifyTab === 3) {
            fetchPMSessions("worked", token, page, LIMIT);
        } else if (topBorderjustifyTab === 4) {
            fetchPMSessions("draft", token, page, LIMIT);
        }
        moment.locale("en-gb");
    }, [topBorderjustifyTab, page]);

    useEffect(() => {
        const tabIndex = location.state?.tabIndex;
        if (tabIndex) {
            settopBorderjustifyTab(tabIndex);
        }
    }, []);

    function toggleApproveModal() {
        setShowAppliedClientDetailModal(!showAppliedClientDetailModal);
    }

    const fetchPMSessions = (status, token, page, limit) => {
        setIsLoading(true);
        sessionServices
            .getSessions(status ? status : "all", token, page, limit)
            .then((resp) => {
                if (resp.success) {
                    setSessionList(resp.sessions);
                    setTotalPageCount(resp?.totalPages === 0 ? 1 : resp?.totalPages);
                } else {
                    if (resp?.message === "Unauthorised") {
                        localStorage.clear();
                        history.push("/login");
                    }
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    const fetchClientById = (sessionId, token, clientId) => {
        setIsLoading(true);
        sessionServices
            .getSessionClientsByID(sessionId, token, clientId)
            .then((resp) => {
                if (resp.success) {
                    if (resp?.session?.Session_clients.length !== 0) {
                        setShowAppliedClientDetailModal(true);
                        setAppliedClientData(resp?.session);
                    } else {
                        toast.error("No one has applied yet");
                    }
                }
            })
            .catch((err) => console.log(err))
            .finally(() => setIsLoading(false));
    };

    return (
        <React.Fragment>
            <div className={`page-content`}>
                {isLoading && <SpinnerComponent />}
                <Container fluid>
                    <Row>
                        <Col>
                            <Card>
                                <CardHeader className="mb-0 pb-0">
                                    <Nav tabs className="nav-tabs">
                                        {SESSIONS_TABS.map((tab, index) => {
                                            return (
                                                <NavItem key={index}>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: topBorderjustifyTab === index,
                                                        })}
                                                        onClick={() => {
                                                            settopBorderjustifyTab(index);
                                                            setPage(1);
                                                        }}
                                                    >
                                                        {tab}
                                                    </NavLink>
                                                </NavItem>
                                            );
                                        })}
                                    </Nav>
                                </CardHeader>
                                <CardBody>
                                    <TabContent activeTab={topBorderjustifyTab} className="text-muted">
                                        <TabPane tabId={topBorderjustifyTab} id="nav-border-top-home">
                                            <div className="live-preview">
                                                <div className="table-responsive table-card">
                                                    <Table className="align-middle table-hover  table-nowrap mb-0">
                                                        <thead className="text-muted">
                                                            <tr>
                                                                <th scope="col">
                                                                    <div className="form-check"></div>
                                                                </th>
                                                                <th scope="col">ID</th>
                                                                <th scope="col">Organisation</th>
                                                                <th scope="col">Date & Time</th>
                                                                <th scope="col">Fee</th>
                                                                <th scope="col">Status</th>
                                                                {topBorderjustifyTab === 1 && (
                                                                    <th scope="col">Applicants</th>
                                                                )}
                                                                {topBorderjustifyTab === 2 && (
                                                                    <th scope="col">Booked By</th>
                                                                )}
                                                                {topBorderjustifyTab === 3 && (
                                                                    <th scope="col">Worked By</th>
                                                                )}
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {sessionList.length !== 0 ? (
                                                                sessionList?.map((item, index) => {
                                                                    let bookedClient = [];
                                                                    let workedClient = [];
                                                                    if (topBorderjustifyTab === 2) {
                                                                        bookedClient = item?.Session_clients?.filter(
                                                                            (item) =>
                                                                                item?.Session_client_statuses[0]
                                                                                    ?.Session_status?.status ===
                                                                                "Booked"
                                                                        );
                                                                    }
                                                                    if (topBorderjustifyTab === 3) {
                                                                        workedClient = item?.Session_clients?.filter(
                                                                            (item) =>
                                                                                item?.Session_client_statuses[0]
                                                                                    ?.Session_status?.status ===
                                                                                "Worked"
                                                                        );
                                                                    }
                                                                    return (
                                                                        <tr key={item?.uuid}>
                                                                            <td>
                                                                                <div className="form-check"></div>
                                                                            </td>
                                                                            <td>
                                                                                #{item?.uuid.substring(0, 5) || "NA"}
                                                                            </td>

                                                                            <td
                                                                                className="text-truncate"
                                                                                style={{ maxWidth: 220 }}
                                                                            >
                                                                                {item?.organisation?.name || "NA"}
                                                                                <p className="text-muted small mb-0">
                                                                                    {item?.user_type?.name || "NA"}
                                                                                </p>
                                                                            </td>
                                                                            <td>
                                                                                {`${moment(item?.start_time).format(
                                                                                    "dddd, Do MMMM YYYY"
                                                                                )}` || "NA"}

                                                                                <p className="text-muted small mb-0">
                                                                                    <span className="fw-medium">
                                                                                        {`${moment(
                                                                                            item?.start_time
                                                                                        ).format("LT")}` || "NA"}{" "}
                                                                                        -{" "}
                                                                                        {`${moment(
                                                                                            item?.end_time
                                                                                        ).format("LT")}` || "NA"}{" "}
                                                                                        ({" "}
                                                                                        {item?.unpaid_break_duration ||
                                                                                            " No break"}{" "}
                                                                                        {item?.unpaid_break_duration !==
                                                                                        0
                                                                                            ? "mins break"
                                                                                            : ""}{" "}
                                                                                        )
                                                                                    </span>
                                                                                </p>
                                                                            </td>
                                                                            <td>
                                                                                £
                                                                                {(
                                                                                    (item?.hourly_rate / 60) *
                                                                                    convertTimeToMinute(
                                                                                        item?.start_time,
                                                                                        item?.end_time,
                                                                                        item?.unpaid_break_duration
                                                                                    )
                                                                                )?.toFixed(0)}
                                                                                <p className="text-muted mb-0">
                                                                                    <span className="fw-medium">
                                                                                        £
                                                                                        {(
                                                                                            (item?.hourly_rate / 60) *
                                                                                            convertTimeToMinute(
                                                                                                item?.start_time,
                                                                                                item?.end_time,
                                                                                                item?.unpaid_break_duration
                                                                                            )
                                                                                        ).toFixed() || "NA"}{" "}
                                                                                        (£
                                                                                        {item?.hourly_rate || "NA"}{" "}
                                                                                        /hour)
                                                                                        {item?.pensionable === 1 ? (
                                                                                            <i
                                                                                                title="Pensionable"
                                                                                                className="ms-1 ri-file-ppt-line"
                                                                                            ></i>
                                                                                        ) : (
                                                                                            ""
                                                                                        )}
                                                                                    </span>
                                                                                </p>
                                                                            </td>

                                                                            <td>
                                                                                <Badge
                                                                                    color={badgeColor(item?.status)}
                                                                                    className="ms-auto"
                                                                                >
                                                                                    {item?.status || "NA"}
                                                                                </Badge>
                                                                            </td>
                                                                            {topBorderjustifyTab === 1 && (
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`btn btn-sm  ${
                                                                                            item?.organisation
                                                                                                ?.totalNumberOfClientsApplied
                                                                                                ? "bg-airgp text-white"
                                                                                                : "btn-soft-primary"
                                                                                        }`}
                                                                                        onClick={() =>
                                                                                            fetchClientById(
                                                                                                item.uuid,
                                                                                                token
                                                                                            )
                                                                                        }
                                                                                    >
                                                                                        {item?.organisation
                                                                                            ?.totalNumberOfClientsApplied ||
                                                                                            "0"}{" "}
                                                                                        Applicant
                                                                                        {item?.organisation
                                                                                            ?.totalNumberOfClientsApplied >
                                                                                        1
                                                                                            ? "s"
                                                                                            : ""}
                                                                                    </button>
                                                                                </td>
                                                                            )}
                                                                            {topBorderjustifyTab === 2 && (
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`btn btn-sm  bg-airgp text-white`}
                                                                                        onClick={() => {
                                                                                            fetchClientById(
                                                                                                item.uuid,
                                                                                                token,
                                                                                                bookedClient[0]?.users
                                                                                                    ?.uuid
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item?.Session_clients[0]
                                                                                                ?.users.title
                                                                                        }{" "}
                                                                                        {
                                                                                            item?.Session_clients[0]
                                                                                                ?.users.firstName
                                                                                        }{" "}
                                                                                        {
                                                                                            item?.Session_clients[0]
                                                                                                ?.users.lastName
                                                                                        }
                                                                                    </button>
                                                                                </td>
                                                                            )}
                                                                            {topBorderjustifyTab === 3 && (
                                                                                <td>
                                                                                    <button
                                                                                        type="button"
                                                                                        className={`btn btn-sm bg-airgp text-white`}
                                                                                        onClick={() => {
                                                                                            fetchClientById(
                                                                                                item.uuid,
                                                                                                token,
                                                                                                workedClient[0]?.users
                                                                                                    ?.uuid
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            item?.Session_clients[0]
                                                                                                ?.users?.title
                                                                                        }{" "}
                                                                                        {
                                                                                            item?.Session_clients[0]
                                                                                                ?.users?.firstName
                                                                                        }{" "}
                                                                                        {
                                                                                            item?.Session_clients[0]
                                                                                                ?.users.lastName
                                                                                        }
                                                                                    </button>
                                                                                </td>
                                                                            )}
                                                                        </tr>
                                                                    );
                                                                })
                                                            ) : (
                                                                <tr>
                                                                    <td colSpan="12" className="text-center">
                                                                        No Data Found
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                                <PaginationV2
                                    totalPageCount={totalPageCount === 0 ? 1 : totalPageCount}
                                    previousDisabled={page === 1}
                                    nextDisabled={totalPageCount === page ? true : false}
                                    previousPageCall={() => setPage(page - 1)}
                                    currentPage={page}
                                    nextPageCalls={() => setPage(page + 1)}
                                    onPageInputChange={({ target: { value } }) => {
                                        var regex = new RegExp(`^[1-${totalPageCount}]$`);
                                        if (value <= totalPageCount && value !== 0) {
                                            setPage(value);
                                        }
                                    }}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <ClientDetailModal
                        open={showAppliedClientDetailModal}
                        toggle={toggleApproveModal}
                        handleClose={() => setShowAppliedClientDetailModal(false)}
                        appliedClient={appliedClientsData}
                        succesHandle={() => {
                            setShowAppliedClientDetailModal(false);
                            settopBorderjustifyTab(2);
                        }}
                        handleDownloadClick={(data) => {
                            if (data.length) {
                                setShowAppliedClientDetailModal(false);
                                setDownloadModal(true);
                                setDocumentList(data);
                            } else {
                                toast.error("Documents not uploaded");
                            }
                        }}
                    />
                    <DownloadDocumentsModal
                        open={downloadModal}
                        handleClose={() => {
                            <ClientDetailModal
                                open={showAppliedClientDetailModal}
                                toggle={toggleApproveModal}
                                handleClose={() => setShowAppliedClientDetailModal(false)}
                                appliedClient={appliedClientsData}
                                succesHandle={() => {
                                    setShowAppliedClientDetailModal(false);
                                    settopBorderjustifyTab(2);
                                }}
                                handleDownloadClick={(data) => {
                                    if (data.length) {
                                        setShowAppliedClientDetailModal(false);
                                        setDownloadModal(true);
                                        setDocumentList(data);
                                    } else {
                                        toast.error("Documents not uploaded");
                                    }
                                }}
                            />;
                            setShowAppliedClientDetailModal(true);
                            setDownloadModal(!downloadModal);
                            document.body.style.overflow = "visible";
                        }}
                        userUploadedDocuments={documentList}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Sessions;
